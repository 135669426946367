function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var employeeActivityTableHeaders = function employeeActivityTableHeaders(claim) {
  var headersLeft = [{
    text: 'Name',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.name || '';
    }
  }, {
    text: 'Role',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.role || '';
    }
  }, {
    text: 'State',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.state || '';
    }
  }];
  var headersPerProject = claim.claim_group.project_methodology === 'project_basis' ? claim.claim_group.claim_project_reports.map(function (_, index) {
    return [{
      text: 'R&D%',
      sortable: true,
      valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
        return r.data.apportionment.rnd_apportionment || '';
      }
    }, {
      text: 'Months',
      sortable: true,
      valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
        return r.data.apportionment.rnd_amount || '';
      }
    }];
  }).flatMap(function (x) {
    return x;
  }) : [];
  var headersRight = [{
    text: 'R&D%',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.rnd_apportionment || '';
    }
  }, {
    text: 'Wages',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.wage || '';
    }
  }, {
    text: 'R&D Amount',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.rnd_amount || '';
    }
  }];
  return [].concat(headersLeft, _toConsumableArray(headersPerProject), headersRight);
};