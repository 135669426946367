var requestOptions = {
  get: {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'error',
    referrer: 'no-referrer'
  },
  put: {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  post: {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  postFormData: {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*'
    }
  },
  putFormData: {
    method: 'PUT',
    headers: {
      'Accept': 'application/json, text/plain, */*'
    }
  },
  delete: {
    method: 'DELETE'
  }
};
export default requestOptions;
export var request = requestOptions;