import { createSlice } from '@reduxjs/toolkit';
import { AppCache } from '../../util/AppCache';
export var authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: function login(state, action) {
      state.user = action.payload;
      AppCache.clear();
    },
    logout: function logout(state) {
      state.user = null;
      AppCache.clear();
    },
    getCurrentSession: function getCurrentSession(state, action) {
      state.user = action.payload.user;
    }
  }
});
var _authSlice$actions = authSlice.actions,
  login = _authSlice$actions.login,
  logout = _authSlice$actions.logout,
  getCurrentSession = _authSlice$actions.getCurrentSession;
export { login, logout, getCurrentSession };
export var selectUser = function selectUser(state) {
  return state.user;
};
export default authSlice.reducer;