import { api } from '@rd-web-markets/shared/dist/services/service';
var stubTasks = [{
  id: 1,
  name: 'First Task',
  type: 'task',
  subtype: 'Type 1',
  status: 'not_started',
  description: 'Description 1',
  tags: 'cost, claim info',
  creator: 3,
  assignee: 3,
  comments: [],
  attachments: [],
  logs: [],
  files: []
}, {
  id: 2,
  name: 'First File Request',
  type: 'file_request',
  subtype: '',
  status: 'in_progress',
  description: 'Description 2',
  tags: 'cost, claim info',
  creator: 3,
  assignee: 3,
  comments: [],
  attachments: [],
  logs: [],
  files: []
}, {
  id: 3,
  name: 'Second File Request',
  type: 'file_request',
  subtype: '',
  status: 'completed',
  description: 'Description 3',
  tags: 'claim info',
  creator: 3,
  assignee: 3,
  comments: [],
  attachments: [],
  logs: [],
  files: []
}, {
  id: 4,
  name: 'Second Task',
  type: 'task',
  subtype: 'Type 2',
  status: 'not_started',
  description: 'Description 4',
  tags: 'cost',
  creator: 3,
  assignee: 3,
  comments: [],
  attachments: [],
  logs: [],
  files: []
}];
var claimGroupTasksService = {
  get: function get(claimGroupId, id) {
    // return api.get(`/api/claim_groups/${claimGroupId}/tasks/${id}`);
    var task = stubTasks.find(function (task) {
      return task.id == id;
    });
    return task;
  },
  update: function update(claimGroupId, id, task) {
    // return api.update(`/api/claim_groups/${claimGroupId}/tasks/${id}`, task);
    var index = stubTasks.findIndex(function (task) {
      return task.id == id;
    });
    stubTasks[index] = task;
    return stubTasks[index];
  },
  create: function create(claimGroupId, task) {
    // return api.create(`/api/claim_groups/${claimGroupId}/tasks`, task});
    task.id = stubTasks[stubTasks.length - 1].id + 1;
    stubTasks.push(task);
    var createdTask = stubTasks.find(function (t) {
      return t.id == task.id;
    });
    return createdTask;
  },
  all: function all(claimGroupId) {
    // return api.get(`/api/claim_groups/${claimGroupId}/tasks`);
    return stubTasks;
  },
  delete: function _delete(claimGroupId, id) {
    // return api.delete(`/api/claim_groups/${claimGroupId}/task/${id}`);
    stubTasks = stubTasks.filter(function (task) {
      return task.id != id;
    });
    return;
  }
};
export default claimGroupTasksService;