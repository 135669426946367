function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom, userIsCuttingContent } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";
import TrackChangesHighlight from "../models/TrackChangesHighlight";
import { setCursorPositionToStartOfElement } from "../cursorUtils";

/**
 * Cutting is handled in onCut.
 * In case we are deleting or we are pasting content or we are pressing enter - we surround the selected text with a deletion highlight.
 * In case we are deleting or pressing Enter - we move the cursor to end of the text node which is on the right of the highlited part.
 * In case we are pasting content - we prepare for pasting by adding an addition highlight dom element to the right and putting the cursor in it.
 * In case we are adding new text - we add a new highlight dom element at the cursor position and put the cursor in that element.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} contentNode 
 * @param {*} cursorSelection 
 * @param {*} predictedInputValueOrNull 
 */
function highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // cutting | pasting | event key enter
  if (contentNode.nodeName === 'IMG') {
    var _contentNode$classLis;
    (_contentNode$classLis = contentNode.classList).add.apply(_contentNode$classLis, _toConsumableArray(DeletionHighlight.createDomElementHighlightClasses().split(' ')));
    return;
  }
  if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full) || userIsPastingContent(event) || event.key === 'Enter') {
    surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion');
  }
}
export function isCutting(event) {
  return userIsCuttingContent(event);
}
export function doCut(event, editor, cursorSelection, contentNode, predictedInputValueOrNul) {
  if (!isEmptySelection(cursorSelection)) {
    highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}
export function onCutEvent(event, activeEditorInstance) {
  try {
    stopEvent(event);
    var cursorSelection = activeEditorInstance.selection.getSel();
    var contentNode = activeEditorInstance.selection.getNode();
    if (DynamicField.isEditingPartOfADynamicField(contentNode, cursorSelection)) {
      return;
    } else if (!isEmptySelection(cursorSelection) && TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType)) {
      return;
    } else if (cursorSelection.anchorNode.nodeName === 'DIV' && cursorSelection.anchorNode.parentElement.nodeName === 'BODY' || cursorSelection.focusNode.nodeName === 'DIV' && cursorSelection.focusNode.parentElement.nodeName === 'BODY') {
      // this seems to happen when we select a deletion highlight which has a contenteditable=false
      return;
    }
    var cutData = cursorSelection.toString();
    event.clipboardData.setData('text/plain', cutData);
    var _surroundSelectionTex = surroundSelectionTextNodesWithDom(activeEditorInstance.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion'),
      _surroundSelectionTex2 = _slicedToArray(_surroundSelectionTex, 2),
      leftSideTextNode = _surroundSelectionTex2[0],
      rightSideTextNode = _surroundSelectionTex2[1];
    setCursorPositionToStartOfElement(activeEditorInstance, rightSideTextNode);
  } catch (error) {
    console.error(error);
  }
}