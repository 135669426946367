import React from 'react';
import { FINANCIAL_SECTION_GENERAL_LINK, SIDEBAR_MENU_ITEMS, SIDEBAR_MENU_ITEMS_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR } from '@rd-web-markets/market/dist/constants';
import { useSelector } from 'react-redux';

const ClaimGroupSidebar = ({ claimGroup, highlightedMenuItem, showMenuItems = true }) => {
  const { claim_group_step: claimGroupStep } = useParams();
  const user = useSelector((state) => state.auth.user);
  
  const getSubMenuItems = (menu) => {
    if (menu.link === 'claim_costs') {
      const subMenuItems = claimGroup.claims.map(claim => {
        return { text: menu.showLastFourChars ? claim.name.slice(-4) : claim.name, link: `claims/${claim.id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}` }
      })

      if (SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR) {
        subMenuItems.push({ text: 'Add Claim', link: `add_claim/company/${claimGroup.company.id_in_provider_system}/claim_groups/${claimGroup.id}` })
      }

      if(menu.showWages) {
        subMenuItems.unshift(
          { text: 'All Base', link: `companies/${claimGroup.company_id}/${claimGroup.id}/base/wages` },
          { text: 'All Claims', link: `companies/${claimGroup.company_id}/${claimGroup.id}/claims/wages` },
          { text: 'All Claims and Base', link: `companies/${claimGroup.company_id}/${claimGroup.id}/all/wages` }
        )
      }
      return subMenuItems
    } else if (menu.link === 'technical_section') {
      const existingProjects = claimGroup.claim_project_reports.filter(p => !!p.id)
      const claimProjectsSorted = existingProjects.sort((a, b) => a.report_order - b.report_order);

      return claimProjectsSorted.map(report => {
        return { text: report.project_name, link: `claim_groups/${claimGroup.id}/technical_proof/project_reports/${report.id}` }
      })
    }
  }

  const getLink = (menuItem) => {
    if (menuItem.link === 'edit/general') {
      return `claim_groups/${claimGroup.id}/${menuItem.link}`
    } else if (menuItem.link === 'technical_section') {
      return `claim_groups/${claimGroup.id}/technical_proof/company_info`
    } else if (menuItem.link === 'project_overview') {
      return `claim_groups/${claimGroup.id}/project_overview`
    } else {
      return `companies/${claimGroup.company_id}/${claimGroup.id}/${menuItem.link}`
    }
  }

  const items = user.account_type === 'customer' ? SIDEBAR_MENU_ITEMS_CUSTOMER : SIDEBAR_MENU_ITEMS

  const menuItems = claimGroup && items.map(item => {
    return { 
      text: item.text,
      highlighted: item.link === highlightedMenuItem,
      link: getLink(item),
      subMenu: item.subMenu ? getSubMenuItems(item) : false
    }
  })

  const claimCostItem = [{ 
    text: `Company Cost ${claimGroup.name.slice(-5)}`,
    highlighted: true,
    link: `companies/${claimGroup.company_id}/${claimGroup.id}/claim_costs`,
    subMenu: false
  }]
  
  return (
    <SidebarPortal 
    menuItems={(claimGroupStep === 'claim_costs' && !showMenuItems) ? claimCostItem : menuItems} 
    headerItem={{ link: `companies/${claimGroup.company_id}/master`, text: claimGroup.name }} 
    />
  )
}

export default ClaimGroupSidebar
