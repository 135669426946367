import React from 'react';
import { Button, Badge } from 'react-bootstrap';

const CompanyUserItem = ({companyUser, handleDelete, index}) => {
  return (
    <>
      <Badge pill bg="secondary">  
        {companyUser.company.name}
        <Button
          variant="light"
          className="p-1 d-inline-flex ml-1"
          onClick={() => handleDelete(index)}
        >
          <span className="material-icons md-18 text-warning">delete</span>
        </Button>
      </Badge>
    </>
  )
}

export default CompanyUserItem