import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const importClaimBaseInformationsService = {
  create(claimId, formData) {
    return fetch(`/api/claims/${claimId}/import_claim_base_informations/`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
}

export default importClaimBaseInformationsService;
