export var wageTableHeaders = function wageTableHeaders(showTypeAndYear) {
  return [{
    text: 'Employee',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return (r.data.claimEmployee.name || '') + ' ' + (r.data.claimEmployee.role || '');
    }
  }, {
    text: 'State',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.state || '';
    }
  }, showTypeAndYear ? {
    text: 'Type',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.claimEmployee.claim.id_in_provider_system ? 'Claim' : 'Base';
    }
  } : null, showTypeAndYear ? {
    text: 'Year',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.claimEmployee.claim.label);
    }
  } : null, {
    text: 'Wages ($)',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.claimEmployee.wage);
    }
  }, {
    text: 'R&D %',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.claimEmployee.rnd_apportionment);
    }
  }, {
    text: 'R&D Amount',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.claimEmployee.rnd_amount);
    }
  }].filter(function (h) {
    return !(h === null);
  });
};