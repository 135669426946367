import { api } from '@rd-web-markets/shared/dist/services/service';
var generalRouteSubRouteService = {
  get: function get(id, route, subRoute) {
    var subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.get("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId));
  },
  update: function update(id, route, subRoute) {
    var subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    var body = arguments.length > 4 ? arguments[4] : undefined;
    return api.update("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  delete: function _delete(id, route, subRoute) {
    var subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.delete("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId));
  },
  create: function create(id, route, subRoute) {
    var body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    return api.create("/".concat(route, "/").concat(id, "/").concat(subRoute), body);
  },
  putFormData: function putFormData(id, route, subRoute) {
    var body = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var subRouteId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    return api.putFormData("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  postFormData: function postFormData(id, route, subRoute, body) {
    return api.postFormData("/".concat(route, "/").concat(id, "/").concat(subRoute), body);
  }
};
export default generalRouteSubRouteService;