import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimGroupSupplies = {
  all(claimGroupId, type) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_supplies?type=${type}`, request.get);
  }
}

export default claimGroupSupplies;
