import AttachmentItem from './AttachmentItem';
import AttachmentsForm from './AttachmentsForm'
import React from 'react'
import { useTranslation } from 'react-i18next';
const FinancialAndPersonnelDocuments = ({ financialAndPersonnelDocuments, handleDelete, loading, handleSubmit, handleAttachmentChange, handleAttachmentSubmit }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <AttachmentsForm
        handleSubmit={handleSubmit}
        loading={loading}
      />
      <table className="table borderless">
        <thead>
          <tr className='text-center'>
            <th>{t('date')}</th>
            <th>{t('document_name')}</th>
            <th>{t('notes')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {financialAndPersonnelDocuments.map((att, index) => {
            return (
              <AttachmentItem
                attachment={att}
                index={index}
                handleAttachmentChange={handleAttachmentChange}
                handleDelete={handleDelete}
                handleAttachmentSubmit={handleAttachmentSubmit}
              />
            );
          })}
        </tbody>
      </table>
    </>
  )
}

export default FinancialAndPersonnelDocuments
