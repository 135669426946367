import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import myClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/my_claim_groups.service';
import React, { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Breadcrumb, Pagination } from 'react-bootstrap'
import SearchBarDefault from '@components/util/SearchBarDefault';
import { useTranslation } from 'react-i18next';
import DashboardsTable from '@rd-web-markets/shared/dist/dashboards/dashboards/DashboardsTable';
import MasterSidebar from '@components/util/MasterSidebar';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import { CompanyService } from '@services/company.service';
import * as QueryString from 'query-string';

const initialParams = {
  page: 1,
  query: ''
}


export default function CompanyDashboardListPage({ accountType }) {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([])
  const [paginationItems, setPaginationItems] = useState([]);
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  )
  const fetchAllCompaniesForUser = useErrorHandling(useCallback(async (queryString) => {
    return await CompanyService.all(queryString)
  }, []))

  const handlePage = useCallback( (pageNumber) => {
    setParams({ ...params, page: pageNumber });
  }, [params])

  const setCompaniesFromSearch = useCallback(searchResult => {
    if (searchResult) {
      setCompanies(searchResult.companies);

      const pages = Array.from({length: searchResult.pages}, (_, i) => i + 1)
      const tempPaginationItems = []
      pages.forEach(pageNumber => {
        tempPaginationItems.push(
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === params.page}
            onClick={() => handlePage(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
      })

      setPaginationItems([tempPaginationItems])
    }
  }, [handlePage, params.page]) 

 

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='dashboards' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${user.account_type}` }}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('dashboards')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>{t('dashboards')}</Card.Header>
        <Card.Body>
          <Row>
            <Col md={{ span: 4, offset: 8 }}>
              <SearchBarDefault 
                fetchDataCallback={fetchAllCompaniesForUser} 
                setDataResult={setCompaniesFromSearch} 
                params={params}
                setParams={setParams}
              />
            </Col>
          </Row>
          <Row>
            <DashboardsTable claimGroupsOrCompanies={companies} tableHeader='company' />
          </Row>
          <Row>
            <Pagination className="justify-content-end">{paginationItems}</Pagination>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
