function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { findAdjacentElementsWhere, isFirstNodeChildOfSecond, isTextNode, matchNodeOrAnyParentElement } from "../../../domUtils";
import AdditionHighlight from './models/AdditionHighlight';
import DeletionHighlight from './models/DeletionHighlight';
export function findLastInsertedHighlightElementInDocument(doc, originalAdditionElement) {
  var elementUniqueClassName = Array.from(originalAdditionElement.classList).find(function (c) {
    return c.includes('TrackChanges-el-uuid');
  });
  return Array.from(doc.querySelectorAll(".".concat(elementUniqueClassName))).pop();
}

/**
 * In case we have highlighted several characters as deleted and we click on the right of one of them and write a new character there.
 * Replaces the existing deletion-highlighted character with 2 spans - one deletion span with that character, followed by 1 addition span with the new character.
 * @param {*} insertedSpan 
 * @param {*} changeId 
 */
export function replaceDeletionHighlightWithNewAdditionHighlight(insertedSpan, changeId, editor) {
  var parent = insertedSpan.parentElement;
  var grandParent = parent.parentElement;
  var siblings = Array.from(parent.childNodes); //.map(child => child.cloneNode(true))

  var lastInsertedSibling = parent;
  // parent.replaceWith(siblings[siblings.length - 1])

  for (var index = siblings.length - 1; index >= 0; index--) {
    var _siblings$index$class;
    var deletionHighlight = DeletionHighlight.createHighlightedElement(siblings[index].textContent, changeId);
    var toInsert = (_siblings$index$class = siblings[index].classList) !== null && _siblings$index$class !== void 0 && _siblings$index$class.contains('TrackChanges-Highlight-Addition') ? siblings[index] : deletionHighlight;
    grandParent.insertBefore(toInsert, lastInsertedSibling);
    lastInsertedSibling = lastInsertedSibling.previousSibling;
  }
  parent.remove();
}
export function findAdjacentTrackChangesElementssOfTheSameType(contentNode, nodeTrackChangesTypeClass) {
  if (!nodeTrackChangesTypeClass) {
    return [];
  }
  var checkHighlightFn = nodeTrackChangesTypeClass.includes('Deletion') ? DeletionHighlight.isNodeAHighlight : AdditionHighlight.isNodeAHighlight;

  // In the case of footnotes - these can be textNodes:
  // e.g. If the footnote has this text: 'asdfg' and the user deletes 'sd' and 
  // then deletes the whole footnote from the marker, then we have <deletion>a<deletion>sd</deletion>fg</deletion>.
  // In this case the adjacent nodes of 'sd' will be text nodes whose parent is also a deletion.
  // In this case we need to return their parent element.
  var relatedNodesAndContentNode = findAdjacentElementsWhere(contentNode, function (adjacentNode) {
    return matchNodeOrAnyParentElement(adjacentNode, checkHighlightFn);
  }).map(
  // returns only track change elements, otherwise we will have random spans, divs, etc, as well
  function (node) {
    return matchNodeOrAnyParentElement(node, checkHighlightFn);
  });

  // if text node - get its parent track change element, otherwise return the node itself
  // Therefore it is possible that the array contains both a track change element and a child track change element.
  // This will work, but will show a wrong text in the modal that shows the text to be added / deleted.
  var adjacentElements = relatedNodesAndContentNode.map(function (node) {
    return isTextNode(node) ? matchNodeOrAnyParentElement(node, checkHighlightFn) : node;
  });

  // For all elements that have parents in the array - filter them out.
  var filteredElements = adjacentElements.filter(function (element) {
    var hasParentInTheArray = adjacentElements.some(function (potentialParent) {
      return isFirstNodeChildOfSecond(element, potentialParent);
    });
    return !hasParentInTheArray;
  });

  // In the case with the footnotes, its possible that multiple nodes have the same track change parent,
  // so here we would have duplicate elements.
  var uniqueAdjacentElements = _toConsumableArray(new Set(filteredElements));
  return uniqueAdjacentElements;
}