import { createSlice } from '@reduxjs/toolkit';
export var reviewSlice = createSlice({
  name: 'review',
  initialState: {
    isUnderTechnicalReview: null,
    isAnyCompanyClaimGroupUnderTechnicalReview: null
  },
  reducers: {
    setIsUnderTechnicalReview: function setIsUnderTechnicalReview(state, action) {
      state.isUnderTechnicalReview = action.payload;
    },
    setIsAnyCompanyClaimGroupUnderTechnicalReview: function setIsAnyCompanyClaimGroupUnderTechnicalReview(state, action) {
      state.isAnyCompanyClaimGroupUnderTechnicalReview = action.payload;
    }
  }
});
var _reviewSlice$actions = reviewSlice.actions,
  setIsUnderTechnicalReview = _reviewSlice$actions.setIsUnderTechnicalReview,
  setIsAnyCompanyClaimGroupUnderTechnicalReview = _reviewSlice$actions.setIsAnyCompanyClaimGroupUnderTechnicalReview;
export { setIsUnderTechnicalReview, setIsAnyCompanyClaimGroupUnderTechnicalReview };
export default reviewSlice.reducer;