function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var getColumnWidths = function getColumnWidths(_ref) {
  var totalWidth = _ref.totalWidth,
    tableHeaders = _ref.tableHeaders;
  // headerIndex => headerWidth in pixels -- { 0: 100, 1: 200} etc.
  // empty object otherwise
  var columnFixedWidths = tableHeaders.map(function (header, index) {
    if (header.width && typeof header.width === 'number') {
      return [index, header.width];
    } else if (header.width && typeof header.width === 'string') {
      // deprecated - because we cannot account for all possible strings - e.g. 1rem 1px 1vw 1% 1em etc.
      // assume string is pixels - e.g. '100px'
      return [index, parseInt(header.width)];
    }
    // some can be undefined
  }).reduce(function (accumulator, current) {
    if (current) {
      accumulator[current[0]] = current[1];
    }
    return accumulator;
  }, {});

  // Calculate the total fixed width
  var totalFixedWidth = Object.values(columnFixedWidths).reduce(function (sum, width) {
    return sum + width;
  }, 0);

  // Calculate width for dynamic columns
  var dynamicColumnsCount = tableHeaders.length - Object.keys(columnFixedWidths).length;
  var dynamicColumnWidth = (totalWidth - totalFixedWidth) / dynamicColumnsCount;
  var allColumnWidths = _objectSpread(_objectSpread({}, columnFixedWidths), {}, {
    dynamicColumnWidth: dynamicColumnWidth
  });
  return allColumnWidths;
};