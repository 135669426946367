import { CompanyService } from '@services/company.service';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Row, Col, Card, Breadcrumb } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import UserForm from '@components/users/UserForm';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { CONSULTANT_ROLE_NAME } from '@rd-web-markets/market/dist/constants';
import { useTranslation } from 'react-i18next';
import collaboratingCompaniesService from '@services/collaborating_companies.service';

const CreateUserPage = ({handleToaster}) => {
  const loggedUser = useSelector((state) => state.auth.user);
  const [user, setUser] = useState({company_users_attributes: [], company_users: []});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setUser({
      ...user,
      [event.target.name]: value,
    });
  };

  const usersPagesAvailable = loggedUser.account_type !== CONSULTANT_ROLE_NAME;

  const handleBack = () => usersPagesAvailable
    ? history.push(`/${loggedUser.account_type}/users`)
    : history.goBack();

  const handleCreateUser = async () => {
    setLoading(true);
    try {
      await UserService.create(user);
      handleBack()
      window.location.reload()
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleCompanyUserDelete = async index => {
    const newCompanyUsers = user.company_users;
    newCompanyUsers.splice(index, 1);
    setUser({...user, company_users: newCompanyUsers, company_users_attributes: newCompanyUsers});
  }

  const addCompanyToUser = (company) => {
    const companyUsers = user.company_users;
    companyUsers.push({company: { name: company.name, id: company.id }, company_id: company.id});
    setUser({...user, company_users: companyUsers, company_users_attributes: companyUsers});
  }

  
  return (
    <>
      <SidebarPortal headerItem={{link: 'companies', text: 'Companies'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${loggedUser.account_type}`}}>{t('home')}</Breadcrumb.Item>
          {usersPagesAvailable && <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${loggedUser.account_type}/users`}}>{t('users')}</Breadcrumb.Item>}
          <Breadcrumb.Item active>New User</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Card>
        <Card.Body>
          <UserForm
            handleCompanyUserDelete={handleCompanyUserDelete}
            handleChange={handleChange}
            user={user}
            addCompanyToUser={addCompanyToUser}
          />
        </Card.Body>
        <Card.Footer>
          <Row className="modal-footer">
            <Button variant="light" onClick={() => handleBack()}>
              <span className="material-icons md-18">chevron_left</span> {t('back')}
            </Button>
            <Button
              onClick={() => handleCreateUser()}
              icon='save'
              iconPosition={'left'}
              loading={loading}
              variant="primary">
              {t('create_and_invite_user')}
            </Button>
          </Row>
          </Card.Footer>
      </Card>
    </>
  );
}

export default CreateUserPage
