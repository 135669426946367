import { createSlice } from '@reduxjs/toolkit';
export var uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isLoading: true,
    errors: ''
  },
  reducers: {
    setLoading: function setLoading(state) {
      state.isLoading = true;
      state.errors = '';
    },
    completeLoading: function completeLoading(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    }
  }
});
var _uiSlice$actions = uiSlice.actions,
  setLoading = _uiSlice$actions.setLoading,
  completeLoading = _uiSlice$actions.completeLoading;
export { setLoading, completeLoading };
export default uiSlice.reducer;