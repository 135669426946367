import React from 'react'
import { useTranslation } from 'react-i18next';
import ClaimScheduleSetTimes from '../claim_schedule/ClaimScheduleSetTimes';
import ClaimScheduleSetTimesItaly from '@rd-web-markets/it/dist/claim_schedule/ClaimScheduleSetTimesItaly';

const ClaimGroupClaimScheduleSection = ({
  claimGroup,
  handleClaimSchedule,
  loading,
  handleProccessDate,
  handleToaster
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-primary mb-4">{t('claim_schedule')}</h2>
      {process.env.PUBLIC_URL === '/it' ? (
        <ClaimScheduleSetTimesItaly
          claimSchedule={claimGroup.claim_schedule}
          handleClaimSchedule={handleClaimSchedule}
          loading={loading}
          handleProccessDate={handleProccessDate}
          handleToaster={handleToaster}
        />
      ) : (
        <ClaimScheduleSetTimes
          claimGroup={claimGroup}
          claimSchedule={claimGroup.claim_schedule}
          handleClaimSchedule={handleClaimSchedule}
          loading={loading}
          handleProccessDate={handleProccessDate}
        />
      )}
    </>
  )
}

export default ClaimGroupClaimScheduleSection
