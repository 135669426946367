// Decides that the image needs to be resized if its alt is equal to this text
// This is in order to be able to add this functionality with minimal changes to the existing code.
export var DATA_ATTRIBUTE_IMAGE_RESIZED = 'data-resize-image-when-added-applied';
var IMG_DESIRED_HEIGHT = 400;

/**
 * Called when an image is loaded, so that the browser has rendered it,
 * and we have access to its width and height.
 * 
 * Resizes the image - by setting its height to IMG_DESIRED_HEIGHT and maintaining
 * the original aspect ratio for the new width.
 * 
 * This is a higher order function that accepts an argumetn (Editor) and returns
 * a function that is the image onload event. This is in order to be able to 
 * fire the 'change' event on the editor that contains the said image, so that
 * we can trigger it to save its content, after we have resized the image.
 * @param {*} editor 
 * @returns 
 */
var resizeImage = function resizeImage(editor) {
  return function (event) {
    if (event.target.getAttribute(DATA_ATTRIBUTE_IMAGE_RESIZED)) {
      return;
    }
    var img = event.target;
    var imgHeight = parseInt(img.height || img.style.height);

    // In this function the image should always be rendered, but just in case.
    if (!imgHeight) {
      return;
    }
    var imageWidthCoefficient = IMG_DESIRED_HEIGHT / imgHeight;
    var newImageWidth = parseInt(img.width || img.style.width) * imageWidthCoefficient;
    img.height = IMG_DESIRED_HEIGHT;
    img.width = newImageWidth;
    img.setAttribute(DATA_ATTRIBUTE_IMAGE_RESIZED, true);
    editor.fire('change', {
      content: editor.getContent()
    });
  };
};
var resize_image_when_added = {
  name: 'resize_image_when_added',
  addIfApplicable: function addIfApplicable(editor) {
    editor.on('NodeChange', function (event) {
      var _event$element;
      // Sets an onload event on all images that dont have the resize applied attribute set.
      // Inside the onload callback, the image is rendered so we have access to its
      // original height and width, and we can resize it.
      if ((event === null || event === void 0 || (_event$element = event.element) === null || _event$element === void 0 ? void 0 : _event$element.tagName) === 'IMG' && !event.element.getAttribute(DATA_ATTRIBUTE_IMAGE_RESIZED)) {
        var img = event.element;
        img.onload = resizeImage(editor);
      }
    });
    return true;
  },
  /**
   * This is done like this - because if we use the paste event - we get the event before the image is added to the editor,
   * hence its not rendered yet, and we cant resize it. If we use the change event, maybe we will be able to get the resized image,
   * but even if we do - that would mean firing this function on each change.
   * This way we fire it only when we need it - on paste.
   * This requires the paste plugin.
   * In addition this function is required, because pasting an image does not trigger the NodeChange event.
   * @param {*} args - the args object from the paste_preprocess function of the paste plugin
   */
  paste_preprocess: function paste_preprocess(args) {
    var editor = args.target;
    var images = args.node.querySelectorAll('img');
    images.forEach(function (img) {
      // in case we copy paste an already resized image, there is no need to do anything
      if (img.getAttribute(DATA_ATTRIBUTE_IMAGE_RESIZED)) {
        return;
      }
      img.onload = function () {
        // when the onload is triggered, the image is loaded, but it is not the same as the image tag in the editor
        // it is also possible to have several images with the same src - e.g. several copies of the ayming logo.
        // If we do querySelector - we will always only get the first image in the dom - hence if we copy paste the same image a second
        // time - it wont be resized, because we will always be getting the first image which is already resized.
        var imagesInEditor = editor.getBody().querySelectorAll("img[src=\"".concat(img.src, "\"]"));
        imagesInEditor.forEach(function (imageInEditor) {
          return resizeImage(editor)({
            target: imageInEditor
          });
        });
      };
    });
  }
};
export default resize_image_when_added;