import { hasClassOrParentWithClass } from "../../../domUtils";
import { copyStyleToDataMceStyle } from "../styles_formatter/tinyMCEFix";
import { findAdjacentTrackChangesElementssOfTheSameType } from "./highlighintUtils";
import TrackChangesHighlight from "./models/TrackChangesHighlight";
function removePopovers(editor) {
  var editorBody = editor.getBody();
  var deletables = Array.from(editorBody.querySelectorAll('.Editor-non-content'));
  deletables.forEach(function (el) {
    return el.remove();
  });
}

/**
 * This function needs to run this loop - because sometimes when we paste a <span>text</span> in the editor,
 * tinimce adds the span inside a track change element - like so <track_change><span>text</span></track_change>.
 * In other times - it adds the span outside the track change, and the track change surrounds the text only - like so:
 * <span><track_change>text</track_change></span>.
 */
function replaceOriginalColors(editorDocument) {
  var elementsWithOriginalColor = editorDocument.querySelectorAll('*[data-track-changes-original-color]');
  elementsWithOriginalColor.forEach(function (element) {
    var isInsideATrackChange = hasClassOrParentWithClass(elementsWithOriginalColor, 'TrackChanges-Highlight');
    var containsTrackChange = !!element.querySelector('.TrackChanges-Highlight');
    if (!isInsideATrackChange && !containsTrackChange) {
      element.style.color = element.getAttribute('data-track-changes-original-color');
      element.removeAttribute('data-track-changes-original-color');
      copyStyleToDataMceStyle(element);
    }
  });
}
function acceptChangesForElement(element, trackChangesTypeClass) {
  if (trackChangesTypeClass === 'TrackChanges-Highlight-Addition') {
    if (['LI', 'IMG'].includes(element.nodeName)) {
      TrackChangesHighlight.removeTrackChangesClasses(element);
    } else {
      var contentHTML = element.innerHTML;
      element.insertAdjacentHTML('afterend', contentHTML);
      element.remove();
    }
  } else if (trackChangesTypeClass === 'TrackChanges-Highlight-Deletion') {
    // this if is necessary because of the ayming styles formatter on the backend
    if (element.parentElement.textContent === element.textContent) {
      element.parentElement.remove();
    } else {
      element.remove();
    }
  }
}
function rejectChangesForElement(element, nodeTrackChangesTypeClass) {
  if (nodeTrackChangesTypeClass === 'TrackChanges-Highlight-Deletion') {
    if (['LI', 'IMG'].includes(element.nodeName)) {
      TrackChangesHighlight.removeTrackChangesClasses(element);
    } else {
      var contentHTML = element.innerHTML;
      element.insertAdjacentHTML('afterend', contentHTML);
      element.remove();
    }
  } else if (nodeTrackChangesTypeClass === 'TrackChanges-Highlight-Addition') {
    // this if is necessary because of the ayming styles formatter on the backend
    if (element.parentElement.textContent === element.textContent) {
      element.parentElement.remove();
    } else {
      element.remove();
    }
  }
}
export function acceptChange(editor, contentNode) {
  var fireEvents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  fireEvents && editor.fire('TrackChanges:acceptChangeBefore', {
    payload: {
      node: contentNode
    }
  });
  var nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(contentNode);
  var relatedNodesAndContentNode = findAdjacentTrackChangesElementssOfTheSameType(contentNode, nodeTrackChangesTypeClass);
  relatedNodesAndContentNode.forEach(function (node) {
    fireEvents && editor.fire('TrackChanges:acceptChangeForElementBefore', {
      payload: {
        node: node,
        changeTypeClass: nodeTrackChangesTypeClass
      }
    });
    acceptChangesForElement(node, nodeTrackChangesTypeClass);
    fireEvents && editor.fire('TrackChanges:acceptChangeForElementAfter', {
      payload: {
        node: node,
        changeTypeClass: nodeTrackChangesTypeClass
      }
    });
    replaceOriginalColors(editor.getDoc());
  });

  // added to remove any lingering track changes popovers
  removePopovers(editor);
  fireEvents && editor.fire('TrackChanges:acceptChangeAfter', {
    payload: {
      node: contentNode
    }
  });
}
export function rejectChange(editor, contentNode) {
  var fireEvents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  fireEvents && editor.fire('TrackChanges:rejectChangeBefore', {
    payload: {
      node: contentNode
    }
  });
  var nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(contentNode);
  var relatedNodesAndContentNode = findAdjacentTrackChangesElementssOfTheSameType(contentNode, nodeTrackChangesTypeClass);
  relatedNodesAndContentNode.forEach(function (node) {
    fireEvents && editor.fire('TrackChanges:rejectChangeForElementBefore', {
      payload: {
        node: node,
        changeTypeClass: nodeTrackChangesTypeClass
      }
    });
    rejectChangesForElement(node, nodeTrackChangesTypeClass);
    fireEvents && editor.fire('TrackChanges:rejectChangeForElementAfter', {
      payload: {
        node: node,
        changeTypeClass: nodeTrackChangesTypeClass
      }
    });
  });

  // added to remove any lingering track changes popovers
  removePopovers(editor);
  fireEvents && editor.fire('TrackChanges:rejectChangeAfter', {
    payload: {
      node: contentNode
    }
  });
}
export function acceptAllChanges(editor) {
  editor.fire('TrackChanges:acceptAllChangeBefore');
  var div = editor.getDoc().createElement('div');
  div.innerHTML = editor.getDoc().body.innerHTML;
  var allHighlightElements = Array.from(div.getElementsByClassName('TrackChanges-Highlight'));
  while (allHighlightElements.length) {
    allHighlightElements.forEach(function (el) {
      var nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(el);
      editor.fire('TrackChanges:acceptChangeForElementBefore', {
        payload: {
          node: el,
          changeTypeClass: nodeTrackChangesTypeClass
        }
      });
      acceptChangesForElement(el, nodeTrackChangesTypeClass);
      editor.fire('TrackChanges:acceptChangeForElementAfter', {
        payload: {
          node: el,
          changeTypeClass: nodeTrackChangesTypeClass
        }
      });
    });
    allHighlightElements = Array.from(div.getElementsByClassName('TrackChanges-Highlight'));
  }
  editor.getDoc().body.innerHTML = div.innerHTML;
  editor.fire('TrackChanges:acceptAllChangeAfter');
}
export function rejectAllChanges(editor) {
  var div = editor.getDoc().createElement('div');
  div.innerHTML = editor.getDoc().body.innerHTML;
  var allHighlightElements = div.getElementsByClassName('TrackChanges-Highlight');
  Array.from(allHighlightElements).forEach(function (el) {
    var nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(el);
    editor.fire('TrackChanges:rejectChangeForElementBefore', {
      payload: {
        node: el,
        newEditorBody: div,
        changeTypeClass: nodeTrackChangesTypeClass
      }
    });
    rejectChangesForElement(el, nodeTrackChangesTypeClass);
    editor.fire('TrackChanges:rejectChangeForElementAfter', {
      payload: {
        node: el,
        newEditorBody: div,
        changeTypeClass: nodeTrackChangesTypeClass
      }
    });
  });
  editor.getDoc().body.innerHTML = div.innerHTML;
  editor.fire('TrackChanges:rejectAllChangeAfter');
}