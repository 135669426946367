import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import ClaimProjectForm from '@rd-web-markets/market/dist/claim/editForm/ClaimProjectForm';
import ClaimStateAlerts from '@rd-web-markets/shared/dist/claim/ClaimStateAlerts';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import claimService from '@services/claim/claim.service';
import ClaimEditBreadcrumbs from '@components/shared/claim/EditForm/ClaimEditBreadcrumbs';
import ClaimEditNavigation from '@components/shared/claim/EditForm/ClaimEditNavigation';
import ClaimEditFooter from '@components/shared/claim/EditForm/ClaimEditFooter';
import { setLoading } from '@rd-web-markets/shared/dist/store/features/uiSlice';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const ClaimEditProjectsPage = ({ handleToaster }) => {
  const { claim_id } = useParams();
  const [claim, setClaim] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const claim = await claimService.get(claim_id);
        setClaim(claim);
      } catch(error) {
        dispatch(handleError(error))
      }
    }
    getData();
  }, [claim_id, dispatch]);

  const submit = async e => {
    e.preventDefault();
    claim.claim_group.claim_project_reports_attributes = claim.claim_group.claim_project_reports;
    setLoading(true);
    try {
      await claimService.update(claim);
      setLoading(false);
      history.replace(`/admin/companies/${claim.company_id}/${claim.id}`);
    } catch(error) {
      dispatch(handleError(error));
    }
  };

  const extractMonthYear = dateString => {
    if (dateString) {
      return dateString.split('/').slice(1).join('/');
    }
  }

  const addProject = () => {
    const claim_projects_copy = [...claim.claim_group.claim_project_reports];
    claim_projects_copy.push({
      project_name: '',
      start_date: extractMonthYear(claim.from_date),
      end_date: extractMonthYear(claim.to_date),
    });
    setClaim({ ...claim, claim_project_reports: claim_projects_copy });
  };

  const removeProject = index => {
    const claim_projects_copy = claim.claim_group.claim_project_reports;
    if (claim_projects_copy[index].id){
      claim_projects_copy[index]['_destroy'] = true;
    }
    else {
      claim_projects_copy.splice(index, 1);
    }
    setClaim({ ...claim, claim_project_reports: claim_projects_copy });
  };

  const handleProjectReportChange = (event, index) => {
    const claim_project_report_copy = claim.claim_group.claim_project_reports;
    const value = event.target.value;
    claim_project_report_copy[index][event.target.name] = value;
    setClaim({
      ...claim,
      claim_project_reports: claim_project_report_copy,
    });
  };

  if(!claim) return <Loading />;

  return (
    <>
      <SidebarPortal headerItem={{link: `companies/${claim.company.id}/master`, text: claim.claim_group.name}} />
      <ClaimEditBreadcrumbs claim={claim} />
      <Card>
        <Card.Header>
          <ClaimEditNavigation claim={claim} page={'claim_projects'}/>
        </Card.Header>
        <Card.Body>
          <ClaimStateAlerts claim={claim} />
          <Row>
            <Col md={12}>
              <ClaimProjectForm
                claim_project_reports={claim.claim_group.claim_project_reports}
                handleProjectReportChange={handleProjectReportChange}
                addProject={addProject}
                removeProject={removeProject}
                disabled={claim.is_locked || claim.financials_locked || claim.active_change_set}
                claimGroup={claim}
                setClaimGroup={setClaim}
                handleSubmit={submit}
                handleToaster={handleToaster}
              />
            </Col>
          </Row>
          <Row className="modal-footer">
          <ClaimEditFooter
            claim={claim}
            handleSubmit={submit}
            backUrl={`/admin/claims/${claim.id}/edit/internal_invoice_detail`}
            continueUrl={`/admin/companies/${claim.company_id}/${claim.id}`}
          />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ClaimEditProjectsPage
