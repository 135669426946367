export var ALL_EDITORS_CROSS_MARKET_DYNAMIC_FIELDS = [{
  title: 'Manager',
  field: 'manager',
  editableInModal: true
}, {
  title: 'Lead Consultant',
  field: 'lead_consultant',
  editableInModal: true
}, {
  title: 'Lead Consultant Email',
  field: 'lead_consultant_email'
}, {
  title: 'Lead Consultant Tel',
  field: 'lead_consultant_tel'
}, {
  title: 'Lead Consultant Role',
  field: 'lead_consultant_role'
}];
export var CROSS_MARKET_EMAIL_DYNAMIC_FIELDS = [].concat(ALL_EDITORS_CROSS_MARKET_DYNAMIC_FIELDS, [{
  title: 'Claim Name',
  field: 'claim_name'
}, {
  title: 'Financial Data Completed Provided Date',
  field: 'financial_data_completed_provided_date'
}, {
  title: 'Client Sponsor',
  field: 'client_sponsor'
}, {
  title: 'Claim Schedule Date',
  field: 'claim_schedule_date'
}, {
  title: 'Briefing Call Date',
  field: 'briefing_call_date'
}, {
  title: 'Draft Report Completed Date',
  field: 'draft_report_completed_with_client_for_review_date'
}, {
  title: 'Technical Project Discussions Date',
  field: 'technical_project_discussions_date'
}, {
  title: 'Sign Off from Client Date',
  field: 'sign_off_from_client_date'
}]);
export var CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS = [].concat(ALL_EDITORS_CROSS_MARKET_DYNAMIC_FIELDS, [{
  title: 'Company Abbrevieted Name',
  field: 'abbreviated_name',
  editableInModal: true
}, {
  title: 'Company Overview RND Context',
  field: 'company_overview_rnd_context'
}, {
  title: 'UTR',
  field: 'utr',
  editableInModal: true
}, {
  title: 'All Accounting Period End Date',
  field: 'all_accounting_period_end_date'
}]);