import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { useCallback } from 'react';

const claimProjectReportService = {
  update(claimGroupId, claim_project_report) {
    return fetch(`/api/claim_groups/${claimGroupId}/claim_project_reports/${claim_project_report.id}`, {
      ...request.put,
      body: JSON.stringify({ claim_project_report })
    });
  },
  delete(claimGroupId, reportId) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_project_reports/${reportId}`, request.delete);
  },
  all(params) {
    return fetch(`/api/claim_project_reports?${params}`, request.get);
  },
  get(claimGroupId, reportId) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_project_reports/${reportId}`, request.get);
  },
}

export default claimProjectReportService;
