function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
export var alertSlice = createSlice({
  name: 'alert',
  initialState: {
    // array of type [{ id: 'some-id-1', message: 'some message' }, { id: 'some-id-2', message: 'some message 2' }]
    alerts: []
  },
  reducers: {
    clearAlerts: function clearAlerts(state) {
      state.alerts = [];
    },
    setAlerts: function setAlerts(state, action) {
      state.alerts = action.payload;
    },
    /**
     * This reducer is supposed to be used to handle errors comming from the backend.
     * Sometimes the backend returns a string error message, sometimes an array of error messages.
     * In any case if we catch and throw an ApiError object, we get an array for consistency.
     * However to be able to display separate alerts and clear them, we need to split that array
     * and assign an id to each error message. This id comes from the ApiError id.
     */
    handleError: {
      reducer: function reducer(state, action) {
        try {
          if (action.payload) {
            var errorId = action.payload.id;
            var userFriendlyMessages = action.payload.userFriendlyMessages;
            var newAlerts = userFriendlyMessages.map(function (m, i) {
              return {
                id: "".concat(errorId, "+").concat(i),
                message: m
              };
            });
            state.alerts = [].concat(_toConsumableArray(state.alerts), _toConsumableArray(newAlerts));
          }
        } catch (error) {
          var _errorId = parseInt(Math.random() * 1000);
          state.alerts = [].concat(_toConsumableArray(state.alerts), [{
            id: _errorId,
            message: error.message
          }]);
        }
      },
      prepare: function prepare(error) {
        console.log(error);
        var errorDescription = {
          id: uuidv4(),
          userFriendlyMessages: error.userFriendlyMessages || [error.message]
        };
        return {
          payload: errorDescription
        };
      }
    },
    removeAlert: function removeAlert(state, action) {
      var removeId = action.payload;
      var filtered = state.alerts.filter(function (a) {
        return a.id !== removeId;
      });
      state.alerts = _toConsumableArray(filtered);
    }
  }
});
var _alertSlice$actions = alertSlice.actions,
  clearAlerts = _alertSlice$actions.clearAlerts,
  setAlerts = _alertSlice$actions.setAlerts,
  handleError = _alertSlice$actions.handleError,
  removeAlert = _alertSlice$actions.removeAlert;
export { clearAlerts, setAlerts, handleError, removeAlert };
export default alertSlice.reducer;