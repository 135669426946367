var editorMenuUtils = {
  addToggleButton: function addToggleButton(_ref) {
    var editor = _ref.editor,
      moduleInstance = _ref.moduleInstance,
      toggleStatePropertyName = _ref.toggleStatePropertyName,
      buttonName = _ref.buttonName,
      textIfToggled = _ref.textIfToggled,
      textIfNotToggled = _ref.textIfNotToggled,
      onToggleCallback = _ref.onToggleCallback;
    var button = {
      text: moduleInstance[toggleStatePropertyName] ? textIfToggled : textIfNotToggled,
      title: 'some_title',
      onAction: function onAction(_) {
        button.toggleState();
        onToggleCallback(moduleInstance[toggleStatePropertyName]);
      },
      toggleState: function toggleState(_) {
        // Check this page and NOTE the "." in the expression: https://developer.mozilla.org/en-US/docs/Web/API/Document/evaluate
        var xpath = moduleInstance[toggleStatePropertyName] ? ".//*[text()='".concat(textIfToggled, "']") : ".//*[text()='".concat(textIfNotToggled, "']");
        var buttonInstance = document.evaluate(xpath, editor.getContainer(), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        moduleInstance[toggleStatePropertyName] = !moduleInstance[toggleStatePropertyName];
        buttonInstance.innerHTML = moduleInstance[toggleStatePropertyName] ? textIfToggled : textIfNotToggled;
      }
    };
    editor.ui.registry.addButton(buttonName, button);
  }
};
export default editorMenuUtils;