function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS } from './constants';
import footnotes from './textEdit/modules/footnotes/';
import track_changes from './textEdit/modules/track_changes';
import comments from './textEdit/modules/comments';
import styles_formatter from './textEdit/modules/styles_formatter';
import resize_image_when_added from './textEdit/modules/resize-image-when-added';
import { ALLOWED_SPAN_CHILDREN, getContentStyle } from './textEdit/trachChangesEditorStyles';
import { TINYMCE_MARKET_REPORT_MODULES, TINYMCE_MARKET_EMAIL_MODULES, TINYMCE_MARKET_OTHER_MODULES } from '@rd-web-markets/market/dist/constants';
import { ACCEPTED_COLORS } from './textEdit/modules/styles_formatter/settings';
import AdditionHighlight from './textEdit/modules/track_changes/models/AdditionHighlight';
function fixImageDimensions() {}

/**
 * Loads only the modules that should be loaded for the current market.
 * In addition - e.g. the track changes depends on whether we are tracking changes, otherwise it shouldnt be loaded.
 * 
 * To do that we loop through all the modules and pass the current editor, as well as the options - e.g. isTrackingChanges.
 * Each module now has a function to add it to the editor which uses these options.
 * @param {*} editor 
 * @param {*} options 
 */
function loadTinyMCEModules(editor, marketModulsForEditorType, options) {
  // since we are saving the loaded modules on the editor instance for later use,
  // we should make copies of them, so that we dont ever end up in a case where we reference old data
  var tinyMCEModules = {
    footnotes: _objectSpread({}, footnotes),
    track_changes: _objectSpread({}, track_changes),
    comments: _objectSpread({}, comments),
    styles_formatter: _objectSpread({}, styles_formatter),
    resize_image_when_added: _objectSpread({}, resize_image_when_added)
  };
  editor.AYMING_MODULES = [];

  // if we pass modules { track_change: false } then we should not load the track_changes module
  var eligibleModules = marketModulsForEditorType.filter(function (moduleName) {
    return !options.modules || options.modules[moduleName] !== false;
  });
  eligibleModules.forEach(function (moduleName) {
    var module = tinyMCEModules[moduleName];
    var isAdded = module.addIfApplicable(editor, options);
    if (isAdded) {
      editor.AYMING_MODULES.push(tinyMCEModules[moduleName]);
    }
  });
}

/**
 * There is a bug that happens if we open a tinymce dialog modal, when the tinymce editor is opened insde a bootrstrap modal.
 * The tinymce modal goes outside the bootstrap modal in the DOM. The bootstrap modal keeps the focus on itself.
 * As a result, it is impossible to focus an input field in the tynimce modal.
 * All events, properties, arguments, etc did not work, so the current solution fixes that issue, by
 * monitoring all dom changes.
 * 
 * When we open the tinymce editor it adds a specific div element which is used as a container to all tinymce dialog modals
 * opened in the future. This fix checks when the bootstrap modal is added to the dom and saves it. Then when the tinymce
 * modal container is added to them dom - it moves it under the bootstrap modal div. This way when we focus an input field
 * in the tinymce modal, the focus is still inside the bootstrap modal, and it works.
 */
function addTinyBootstrapModalObserver() {
  // no need to add it twice
  if (window.TINY_BOOTSTRAP_MODAL_OBSERVER) {
    return;
  }
  var latestModal = null;
  var observer = new MutationObserver(function (mutationsList, observer) {
    // Handle the mutations here
    mutationsList.forEach(function (mutation) {
      if (mutation.type === 'childList') {
        var _newNode$classList;
        var newNode = mutation.addedNodes[0];

        // check whether the current node is a bootstrap modal div
        if (newNode !== null && newNode !== void 0 && newNode.getAttribute && newNode.getAttribute('role') === 'dialog' && newNode.classList.contains('modal')) {
          latestModal = newNode;
        }

        // check whether the current node is a tinymce modal container - and if so - move it under the latest bootstrap modal
        if ((newNode === null || newNode === void 0 || (_newNode$classList = newNode.classList) === null || _newNode$classList === void 0 ? void 0 : _newNode$classList.value) === 'tox tox-silver-sink tox-tinymce-aux' && !(newNode.parentElement.getAttribute('role') === 'dialog')) {
          var _latestModal;
          // in case we open the editor outside a modal, the latestModal variable will be null
          (_latestModal = latestModal) === null || _latestModal === void 0 || _latestModal.appendChild(newNode);
        }
      }
    });
  });
  var observerConfig = {
    childList: true,
    subtree: true
  };

  // Start observing the target node for DOM mutations
  observer.observe(document.body, observerConfig);
  window.TINY_BOOTSTRAP_MODAL_OBSERVER = observer;
}
function createDynamicFieldInsertContent(fieldMarkup, isTrackingChanges) {
  var finalInsertMarkup = isTrackingChanges ? AdditionHighlight.createHighlightedElement(fieldMarkup).outerHTML : fieldMarkup;
  return "&puncsp;<span style=\"color: #666E7E; font-family: franklin gothic book; font-size: 11pt\">".concat(finalInsertMarkup, "</span>");
}
function getDefaultSetup(moduleOptions) {
  return function (editor) {
    loadTinyMCEModules(editor, TINYMCE_MARKET_OTHER_MODULES, _objectSpread({}, moduleOptions));
  };
}
function getReportTemplateSetup(claimGroupEditorFields, perClaimEditorFields, moduleOptions) {
  return function (editor) {
    loadTinyMCEModules(editor, TINYMCE_MARKET_REPORT_MODULES, _objectSpread({}, moduleOptions));
    var items = [{
      type: 'menuitem',
      text: 'Pluralize (by number of cost claims)',
      onAction: function onAction() {
        var content = createDynamicFieldInsertContent('[:pluralize]', moduleOptions.isTrackingChanges);
        editor.insertContent(content);
      }
    }].concat(_toConsumableArray(CROSS_MARKET_REPORT_TEMPLATE_DYNAMIC_FIELDS.map(function (f) {
      return {
        type: 'menuitem',
        text: f.title,
        onAction: function onAction() {
          var content = createDynamicFieldInsertContent("[:".concat(f.field, "]"), moduleOptions.isTrackingChanges);
          editor.insertContent(content);
        }
      };
    })));
    perClaimEditorFields.forEach(function (field) {
      items.push({
        type: 'menuitem',
        text: field.text,
        onAction: function onAction() {
          var content = createDynamicFieldInsertContent("[:".concat(field.insertContent, "]"), moduleOptions.isTrackingChanges);
          editor.insertContent(content);
        }
      });
    });
    claimGroupEditorFields.forEach(function (field) {
      items.push({
        type: 'menuitem',
        text: field.text,
        onAction: function onAction() {
          var content = createDynamicFieldInsertContent("[:".concat(field.insertContent, "]"), moduleOptions.isTrackingChanges);
          editor.insertContent(content);
        }
      });
    });
    editor.ui.registry.addMenuButton('dynamic', {
      text: 'Add Dynamic Value',
      fetch: function fetch(callback) {
        callback(items);
      }
    });
  };
}
function getEmailTemplateSetup(templateType, emailTemplateDynamicFileds) {
  return function (editor) {
    loadTinyMCEModules(editor, TINYMCE_MARKET_EMAIL_MODULES, {});
    var emailDynamicFields = emailTemplateDynamicFileds[templateType];
    var items = emailDynamicFields.map(function (f) {
      return {
        type: 'menuitem',
        text: f.title,
        onAction: function onAction() {
          editor.insertContent(createDynamicFieldInsertContent("[:".concat(f.field, "]"), false));
        }
      };
    });
    editor.ui.registry.addMenuButton('dynamic', {
      text: 'Add Dynamic Value',
      fetch: function fetch(callback) {
        callback(items);
      }
    });
  };
}
function getPerClaimEditorFields(reportTemplate, marketClaimSpecificFields) {
  var _reportTemplate$claim;
  if (((_reportTemplate$claim = reportTemplate.claims) === null || _reportTemplate$claim === void 0 ? void 0 : _reportTemplate$claim.length) > 0) {
    var editorFields = [];
    reportTemplate.claims.forEach(function (claim) {
      var dynamicFields = marketClaimSpecificFields.map(function (f) {
        return {
          text: "".concat(f.title, " - ").concat(claim.name),
          insertContent: "".concat(f.field, ",").concat(claim.name)
        };
      });
      editorFields = editorFields.concat(dynamicFields);
    });
    return editorFields;
  } else {
    return [];
  }
}
function getClaimGroupEditorFields(marketClaimGroupFields) {
  var dynamicFields = marketClaimGroupFields.map(function (field) {
    return {
      text: "".concat(field.title),
      insertContent: "".concat(field.field)
    };
  });
  return dynamicFields;
}
export var defaultConfig = {
  plugins: ['image', 'link', 'paste', 'save', 'lists', 'advlist', 'wordcount'],
  menubar: false,
  branding: false,
  paste_block_drop: true,
  // this line and the inclusion of 'paste' plugin above - remove the ability to drop dragged content inside the editor. Conventional copy/paste still works.
  paste_data_images: true,
  // this makes it possible to paste inline images in the editor
  image_uploadtab: false,
  image_title: false,
  image_description: false,
  image_dimensions: false,
  forced_root_block: 'div',
  custom_elements: '~track_changes_change,track_changes_comment,track_changes_change_block',
  valid_children: [].concat(_toConsumableArray(ALLOWED_SPAN_CHILDREN.map(function (x) {
    return "+span[".concat(x, "]");
  })), _toConsumableArray(ALLOWED_SPAN_CHILDREN.map(function (x) {
    return "+track_changes_change[".concat(x, "]");
  }))).join(','),
  // extended_valid_elements: 'span[data-track-changes-original-color]'
  extended_valid_elements: "+@[data-track-changes-original-color]",
  fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 25pt 26pt 27pt 28pt 29pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt',
  lineheight_formats: '0 0.5 0.6 0.7 1 1.1 1.2 1.3 1.4 1.5 2',
  content_style: getContentStyle(false),
  font_formats: 'Georgia=georgia, regular; Franklin Gothic Demi=franklin gothic demi; Franklin Gothic Book=franklin gothic book',
  file_picker_types: 'image',
  // The images dataimg filter is needed to tell tinymce to keep the images' src attributes as base64 data.
  // Otherwise when TinyMCE loads the image - it saves its base64 data into the data-mce-src attribute and
  // then converts the src to some url. This causes a problem, because the code for adding comments is set up
  // to add the comments to the innerHTML of the editor body. However at this point the image's src is already
  // converted to a url, so when we save the content, we save the url to the database and consequently break the image.
  // Here we ignore all images that have the data-mce-src attribute - therefore all base64 images.
  images_dataimg_filter: function images_dataimg_filter(img) {
    return img.hasAttribute('data-mce-src');
  },
  paste_webkit_styles: 'all',
  paste_retain_style_properties: 'all',
  // paste_merge_formats is required for the track changes when pasting text. In order (when accepting a pasted text change) to
  // return the accepted text in its original color. E.g. pasting text in ayming blue, making it green, and when accepting it -
  // returning it to ayming blue. We use a custom data-track-changes-original-color attribute on the new pasted html. To have a 
  // green color we strip any colors from the style attribute. That leads to having a case like this:
  // <div><span style="xyz" data-attr-blue><track_changes_change><span style="xyz" data-attr-red>text</span></track_changes_change></span></div>
  // In that case the paste plugin by default will merge the 2 spans, because their style attributes are the same and we will lose the 
  // knowledge that the inner span was ayming red.
  paste_merge_formats: false,
  // sets a predefined width and height to all pasted images
  paste_postprocess: function paste_postprocess(plugin, args) {
    var editor = args.target;
    editor.AYMING_MODULES.forEach(function (module) {
      var _module$paste_preproc;
      (_module$paste_preproc = module.paste_preprocess) === null || _module$paste_preproc === void 0 || _module$paste_preproc.call(null, args);
    });
  },
  file_picker_callback: function file_picker_callback(cb, value, meta) {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = function () {
      var file = this.files[0];
      var reader = new FileReader();
      reader.onload = function () {
        var id = 'blobid' + new Date().getTime();
        var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        var base64 = reader.result.split(',')[1];
        var blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), {
          title: file.name
        });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  },
  color_map: ['#666E7E', 'Ayming Gray', '#00AEEF', 'Ayming Blue', '#A20A42', 'Ayming Red']
};
var TOOLBARS = {
  DEFAULT_CONFIG_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist'],
  REPORT_TEMPLATE_CONFIG_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist | styles_formatter_toggle'],
  DEFAULT_TRACK_CHANGES_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist | comments_view_toggle', 'track_changes_accept track_changes_reject track_changes_accept_all track_changes_reject_all | track_changes_toggle_changes | add_comment | track_changes_previous_change | track_changes_next_change'],
  REPORT_TEMPLATE_TRACK_CHANGES_TOOLBAR: ['wordcount | undo redo | styleselect | bold italic | link image footnote | dynamic | | fontselect | fontsizeselect | forecolor | backcolor | alignleft aligncenter alignright alignjustify | numlist bullist | styles_formatter_toggle | comments_view_toggle', 'track_changes_accept track_changes_reject track_changes_accept_all track_changes_reject_all | track_changes_toggle_changes | add_comment | track_changes_previous_change | track_changes_next_change']
};
function getFullModuleOptions(providedModuleOptions) {
  var defaultModuleOptions = {
    isTrackingChanges: false,
    onAddCommentButtonClickCallback: function onAddCommentButtonClickCallback() {},
    onNextChangeButtonClickCallback: function onNextChangeButtonClickCallback() {},
    showTrackChangesControls: false,
    footnoteStartIndex: 0,
    toggleTrackChangesVisibility: true,
    onStylesFormatterToggled: function onStylesFormatterToggled() {},
    stylesFormatterToggle: true,
    nextChange: function nextChange() {},
    previousChange: function previousChange() {}
  };
  var fullModuleOptions = _objectSpread(_objectSpread({}, defaultModuleOptions), providedModuleOptions);
  return fullModuleOptions;
}
export var apiKey = 'o9kqqlyz26o6rxv1o2xq807104qdz1ez1f4t1yqfqwzg58vk';

/**
 * 
 * @param {*} moduleOptions 
 *  moduleOptions example:
 *      isTrackingChanges: false,
 *      onAddCommentButtonClickCallback: () => {},
 *      showTrackChangesControls: false,
 *      footnoteStartIndex: 5
 *       
 *      // Everything under modules is true by default.
 *      // Defines which modules to add to the editor.
 *      // E.g. when we are editing a claim project report's title we dont want to have the styles formatter.
 *      // Because the project report titles always have a predefined style which does not match what the styles formatter is doing for the normal text.
 *      modules: { 
 *       footnotes: true,
 *       track_changes: false,
 *       comments: false,
 *       styles_formatter: false,
 *       resize_image_when_added: false
 *      }
 * 
 * @returns 
 */
export function getDefaultConfig() {
  var _moduleOptions$module, _moduleOptions$module2;
  var moduleOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var fullModuleOptions = getFullModuleOptions(moduleOptions);
  var tinyMceConfig = _objectSpread({}, defaultConfig);
  tinyMceConfig.content_style = getContentStyle(fullModuleOptions.isTrackingChanges);
  tinyMceConfig.setup = getDefaultSetup(fullModuleOptions);
  var prefix = (_moduleOptions$module = moduleOptions.modules) !== null && _moduleOptions$module !== void 0 && _moduleOptions$module.styles_formatter ? 'REPORT_TEMPLATE' : 'DEFAULT';
  var trackChangesSuffix = fullModuleOptions.isTrackingChanges ? 'TRACK_CHANGES' : 'CONFIG';
  var toolbar = TOOLBARS["".concat(prefix, "_").concat(trackChangesSuffix, "_TOOLBAR")];
  if ((_moduleOptions$module2 = moduleOptions.modules) !== null && _moduleOptions$module2 !== void 0 && _moduleOptions$module2.hideWordCount) {
    tinyMceConfig.toolbar = [toolbar[0].replace(/wordcount/g, '')];
    tinyMceConfig.plugins = defaultConfig.plugins.filter(function (p) {
      return p !== 'wordcount';
    });
  } else {
    tinyMceConfig.toolbar = toolbar;
  }
  addTinyBootstrapModalObserver();
  return tinyMceConfig;
}

/**
 * 
 * @param {Object} reportTemplate 
 * @param {Array} marketClaimGroupFields 
 * @param {Array} marketClaimSpecificFields 
 * @param {Object} moduleOptions  - {}
 * 
 *  moduleOptions example:
 *      isTrackingChanges: false,
 *      onAddCommentButtonClickCallback: () => {},
 *      showTrackChangesControls: false,
 *      footnoteStartIndex: 5
 * @returns 
 */
export function getReportTemplateOptions(reportTemplate, marketClaimGroupFields, marketClaimSpecificFields) {
  var moduleOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var fullModuleOptions = getFullModuleOptions(moduleOptions);
  var claimGroupFields = getClaimGroupEditorFields(marketClaimGroupFields);
  var perClaimEditorFields = getPerClaimEditorFields(reportTemplate, marketClaimSpecificFields);
  var tinyMceConfig = _objectSpread({}, defaultConfig);
  tinyMceConfig.content_style = getContentStyle(fullModuleOptions.isTrackingChanges);
  tinyMceConfig.setup = getReportTemplateSetup(claimGroupFields, perClaimEditorFields, fullModuleOptions);
  tinyMceConfig.toolbar = fullModuleOptions.isTrackingChanges ? TOOLBARS.REPORT_TEMPLATE_TRACK_CHANGES_TOOLBAR : TOOLBARS.REPORT_TEMPLATE_CONFIG_TOOLBAR;
  addTinyBootstrapModalObserver();
  return tinyMceConfig;
}
export function getEmailTemplateOptions(templateType, emailTemplateDynamicFileds) {
  var tinyMceConfig = _objectSpread({}, defaultConfig);
  tinyMceConfig.setup = getEmailTemplateSetup(templateType, emailTemplateDynamicFileds);
  tinyMceConfig.toolbar = TOOLBARS.DEFAULT_CONFIG_TOOLBAR;
  addTinyBootstrapModalObserver();
  return tinyMceConfig;
}