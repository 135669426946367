function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { findNextOrPreviousTextNode, hasClassOrParentWithClass, matchNodeOrAnyParentElement } from "@rd-web-markets/shared/dist/util/domUtils";
import AdditionHighlight from "./AdditionHighlight";
import DeletionHighlight from "./DeletionHighlight";

/**
 * Contains methods that call both AdditionHighlight methods and DeletionHighlight methods.
 * Aka its not specific to either one - aka checking if a dom element is either a deletion or an addition highlight.
 */
var TrackChangesHighlight = {
  /**
   * Returns the track changes type of the current node (or any of its parent nodes).
   * If no node in the chain is a track changes node, then it returns null. Otherwise returns the string of the class.
   * 
   * The contentNode can be the track chagnges node itself.
   * Otherwise if say the change is a nested html object, the content node may be e.g. the h1 in <{track_changes_element}> <h1></h1> </{track_changes_element}>
   * @param {*} contentNode 
   * @returns {String} 'TrackChanges-Highlight-Addition' | 'TrackChanges-Highlight-Deletion'
   */
  getNodeTrackChangesType: function getNodeTrackChangesType(contentNode) {
    var nodeTrackChangesTypeClass = null;
    var currentNode = contentNode;
    while (currentNode) {
      if (currentNode.className.includes(AdditionHighlight.className)) {
        nodeTrackChangesTypeClass = AdditionHighlight.className;
        break;
      } else if (currentNode.className.includes(DeletionHighlight.className)) {
        nodeTrackChangesTypeClass = DeletionHighlight.className;
        break;
      } else {
        currentNode = currentNode.parentElement;
      }
    }
    return nodeTrackChangesTypeClass;
  },
  isElementAnAdditionOrDeletionHighlight: function isElementAnAdditionOrDeletionHighlight(node) {
    return AdditionHighlight.isNodeAHighlight(node) || DeletionHighlight.isNodeAHighlight(node);
  },
  isNodeAOrInAHighlight: function isNodeAOrInAHighlight(node, highlightType) {
    if (highlightType === AdditionHighlight.highlightType) {
      return matchNodeOrAnyParentElement(node, AdditionHighlight.isNodeAHighlight);
    } else {
      return matchNodeOrAnyParentElement(node, DeletionHighlight.isNodeAHighlight);
    }
  },
  /**
   * 
   * @param {*} node current DOM Node
   * @param {String} siblingDirection 'next' or 'previous'
   * @param {String} highlightType DeletionHighlight.highlightType | AdditionHighlight.highlightType
   */
  isSiblingAOrInAHighlight: function isSiblingAOrInAHighlight(node, siblingDirection, highlightType) {
    if (!node) {
      return false;
    }
    var sibling = findNextOrPreviousTextNode(node, siblingDirection);
    return TrackChangesHighlight.isNodeAOrInAHighlight(sibling, highlightType);
  },
  removeTrackChangesClasses: function removeTrackChangesClasses(element) {
    var _element$classList;
    var trackChangesClasses = Array.from(element.classList).filter(function (c) {
      return c.includes('TrackChanges');
    });
    (_element$classList = element.classList).remove.apply(_element$classList, _toConsumableArray(trackChangesClasses));
  },
  isAnySelectionNodeInTrackChangesHighlight: function isAnySelectionNodeInTrackChangesHighlight(cursorSelection, highlightType) {
    var className = highlightType === AdditionHighlight.highlightType ? AdditionHighlight.className : DeletionHighlight.className;
    var anchorIsInHighlightedElement = hasClassOrParentWithClass(cursorSelection.anchorNode, className);
    var offsetIsInHighlightedElement = hasClassOrParentWithClass(cursorSelection.focusNode, className);
    return anchorIsInHighlightedElement || offsetIsInHighlightedElement;
  }
};
export default TrackChangesHighlight;