import { ACCEPTED_COLORS } from "../styles_formatter/settings";
import AdditionHighlight from "./models/AdditionHighlight";
import TrackChangesHighlight from "./models/TrackChangesHighlight";

/**
 * Since we are in the track_changes module, that means we are tracking changes.
 * If we are tracking changes, we want to preserve the original color of any pasted text,
 * and remove any background color. We want the text to be green, but to revert to its original color
 * when we accept the change.
 * @param {*} node 
 */
function setOriginalColor(node) {
  if (node.style.color && ACCEPTED_COLORS.includes(node.style.color.toUpperCase())) {
    node.setAttribute('data-track-changes-original-color', node.style.color);
  }
  node.style.removeProperty('background-color');
  node.style.removeProperty('color');
}

/**
 * When we are tracking changes and we paste content, we are pasting it inside an addition highlight.
 * Therefore if the content already contains addition highlights, we need to replace the inner addition highlights
 * with their contents.
 * @param {*} element 
 */
function onPasteHighlightedElement(element) {
  if (AdditionHighlight.isNodeAHighlight(element)) {
    element.outerHTML = element.innerHTML;
  }
}

/**
 * When we are tracking changes, and we paste text into the editor - we want all pasted text to be green.
 * However if any of the text was originally ayming red, or ayming blue, etc, we want that text to return to
 * its original accepted ayming color, when the change is accepted.
 * 
 * Required tinymce setting:
 * 
 * paste_merge_formats: false
 */
export function preservePastedColors(args) {
  var allNodes = args.node.querySelectorAll('*');
  // first loop needs to go through all nodes to fix colors
  allNodes.forEach(function (node) {
    setOriginalColor(node);
  });

  // second loop can change the html only after the first loop has completed
  allNodes.forEach(function (node) {
    if (TrackChangesHighlight.isElementAnAdditionOrDeletionHighlight(node)) {
      onPasteHighlightedElement(node);
    }
  });
}