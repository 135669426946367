function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { v4 as uuidv4 } from 'uuid';
import { ZERO_WIDTH_NO_BREAK_SPACE, hasClassOrParentWithClass, isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom, userIsPastingContent } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";
import AdditionHighlight from '../models/AdditionHighlight';
import { findLastInsertedHighlightElementInDocument, replaceDeletionHighlightWithNewAdditionHighlight } from '../highlighintUtils';
import { setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from '../cursorUtils';
import selectionUtils from '../selectionUtils';

// WHEN PASTING
function insertAdditionHighlightForPasting(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  /**
   * In order to not create nested addition highlights
   */
  if (hasClassOrParentWithClass(contentNode, 'TrackChanges-Highlight-Addition') && !DeletionHighlight.isSelectionAnchorInHighlight(cursorSelection)) {
    editor.execCommand('mceInsertContent', false, event.key);
    stopEvent(event);
  } else if (!DynamicField.isSelectionAnchorInDeletedDynamicField(contentNode, cursorSelection)) {
    var _insertedSpan$parentE;
    /**
     * If we are inside a deleted dynamic field, lets just not add text at all
     */
    var changeId = uuidv4();
    var contentToAdd = ZERO_WIDTH_NO_BREAK_SPACE;
    var additionHighlight = AdditionHighlight.createHighlightedElement(contentToAdd, changeId);
    editor.execCommand('mceInsertRawHTML', false, additionHighlight.outerHTML);
    var insertedSpan = findLastInsertedHighlightElementInDocument(editor.contentDocument, additionHighlight);
    if ((_insertedSpan$parentE = insertedSpan.parentElement.classList) !== null && _insertedSpan$parentE !== void 0 && _insertedSpan$parentE.contains('TrackChanges-Highlight-Deletion')) {
      replaceDeletionHighlightWithNewAdditionHighlight(insertedSpan, changeId, editor);
    }
    insertedSpan = findLastInsertedHighlightElementInDocument(editor.contentDocument, additionHighlight);
    var emptyTextNode = document.createTextNode('');
    insertedSpan.appendChild(emptyTextNode);
    // if we are pasting we should let the event bubble up - so not stopping
    // also we need to delete the empty space added into the addition span and then the pasted text will
    // go correctly into the addition highlight
    setCursorPositionToEndOfElement(editor, emptyTextNode);
  }
}

/**
 * Create an addition highlight in the desired place and place the cursor inside it, so that
 * when the pasting happens the pasted content will fall inside the highlight.
 * @param {*} editor 
 * @param {*} cursorSelection 
 * @param {*} rightSideTextNode 
 */
function prepareForPasting(editor, cursorSelection, rightSideTextNode) {
  var changeId = uuidv4();
  var insertedAdditionElement;
  var offset = selectionUtils.getOffsetIfnodeIsFocusOrAnchor(cursorSelection, rightSideTextNode);
  if (hasClassOrParentWithClass(rightSideTextNode, 'TrackChanges-Highlight-Deletion') && offset !== -1) {
    var textNodeCutContent = rightSideTextNode.textContent.substring(offset);
    rightSideTextNode.textContent = rightSideTextNode.textContent.substring(0, offset);
    rightSideTextNode.parentElement.insertAdjacentHTML('afterend', DeletionHighlight.createHighlightedElement(textNodeCutContent).outerHTML);
    var additionElement = AdditionHighlight.createHighlightedElement(ZERO_WIDTH_NO_BREAK_SPACE, changeId, null, 'div');
    rightSideTextNode.parentElement.insertAdjacentHTML('afterend', additionElement.outerHTML);
    insertedAdditionElement = findLastInsertedHighlightElementInDocument(editor.getDoc(), additionElement);
  }
  if (insertedAdditionElement) {
    setCursorPositionToEndOfElement(editor, insertedAdditionElement);
  } else {
    setCursorPositionToStartOfElement(editor, rightSideTextNode);
  }
  if (!insertedAdditionElement) {
    var _additionElement = AdditionHighlight.createHighlightedElement(ZERO_WIDTH_NO_BREAK_SPACE, changeId, null, 'div');
    editor.execCommand('mceInsertRawHTML', false, _additionElement.outerHTML);
    var insertedElement = findLastInsertedHighlightElementInDocument(editor.getDoc(), _additionElement);
    var emptyTextNode = document.createTextNode('');
    insertedElement.appendChild(emptyTextNode);

    // For some reason, even if we copy paste text from the same editor, the new text is pasted inside a new div that has a BR tag.
    Array.from(insertedElement.childNodes).filter(function (c) {
      return c.nodeName === 'BR';
    }).forEach(function (br) {
      return br.remove();
    });
    setCursorPositionToEndOfElement(editor, emptyTextNode); // to get the text node of the span - to be inside the span
  }
}

/**
 * Cutting is handled in onCut.
 * In case we are deleting or we are pasting content or we are pressing enter - we surround the selected text with a deletion highlight.
 * In case we are deleting or pressing Enter - we move the cursor to end of the text node which is on the right of the highlited part.
 * In case we are pasting content - we prepare for pasting by adding an addition highlight dom element to the right and putting the cursor in it.
 * In case we are adding new text - we add a new highlight dom element at the cursor position and put the cursor in that element.
 * @param {*} event 
 * @param {*} editor 
 * @param {*} contentNode 
 * @param {*} cursorSelection 
 * @param {*} predictedInputValueOrNull 
 */
function highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // cutting | pasting | event key enter
  if (contentNode.nodeName === 'IMG') {
    var _contentNode$classLis;
    (_contentNode$classLis = contentNode.classList).add.apply(_contentNode$classLis, _toConsumableArray(DeletionHighlight.createDomElementHighlightClasses().split(' ')));
    return;
  }
  var _surroundSelectionTex = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion'),
    _surroundSelectionTex2 = _slicedToArray(_surroundSelectionTex, 2),
    leftSideTextNode = _surroundSelectionTex2[0],
    rightSideTextNode = _surroundSelectionTex2[1];
  prepareForPasting(editor, cursorSelection, rightSideTextNode);
}
export function isPasting(event) {
  return userIsPastingContent(event);
}
export function doPaste(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (isEmptySelection(cursorSelection)) {
    insertAdditionHighlightForPasting(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  } else {
    highlightAdditionAndDeletionChangesWhenSelectionIsNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}