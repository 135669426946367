import collaboratingCompaniesService from '@services/collaborating_companies.service';
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CollaboringCompanyModal from './CollaboratingCompanyModal';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import {
  CONSULTANT_ROLE_NAME,
  SHOW_DEPARTMENT_FOR_USER_FORM,
  SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS
} from '@rd-web-markets/market/dist/constants';
import { CompanyService } from '@services/company.service';
import CompanyUserItem from './CompanyUserItem';

const UserForm = ({
  user,
  handleChange,
  addCompanyToUser,
  handleCompanyUserDelete
}) => {
  const loggedUser = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [collaboratingCompanies, setCollaboratingCompanies] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await CompanyService.all();
      setCompanies(companies.companies);
    }
    fetchCompanies();

  }, [])

  const changeSelectedCompany = e => {
    setSelectedCompany(companies.find(company => company.id == e.target.value));
  }

  const getCollaboratingCompanies = useErrorHandling(
    useCallback(async () => {
      const collaboratingCompanies = await collaboratingCompaniesService.all();
      setCollaboratingCompanies(collaboratingCompanies);
    }, [setCollaboratingCompanies])
  );

  useEffect(() => {
    getCollaboratingCompanies();
  }, [getCollaboratingCompanies]);

  const changeCollaborator = (e) => {
    if (e.target.value !== '0') {
      handleChange(e);
    } else {
      setShowCollaboratorModal(true);
    }
  }
  
  const shouldShowAdminOption = loggedUser.account_type === CONSULTANT_ROLE_NAME
    ? SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS
    : true;

  if (!collaboratingCompanies || !companies) return <Loading />

  return (
    <>
      <CollaboringCompanyModal
        show={showCollaboratorModal}
        onHide={() => setShowCollaboratorModal(false)}
        onSubmit={() => getCollaboratingCompanies()} />
      <Form>
        <Form.Group as={Row}>
          <Form.Label column="md" md={3}>
            {t('first_name')}*
          </Form.Label>
          <Col>
            <Form.Control
              value={user.first_name}
              name="first_name"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="required"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column="md" md={3}>
            {t('middle_name')}
          </Form.Label>
          <Col>
            <Form.Control
              value={user.middle_name}
              name="middle_name"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="optional"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column="md" md={3}>
            {t('last_name')}*
          </Form.Label>
          <Col>
            <Form.Control
              value={user.last_name}
              name="last_name"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="required"
            />
          </Col>
        </Form.Group>
        {['customer', 'consultant', 'super_consultant'].includes(user.account_type) && (
          <>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                {t('companies')}
              </Form.Label>
              <Col md={4}>
                  <Form.Control
                    as="select"
                    size="md"
                    name="companyId"
                    onChange={e => changeSelectedCompany(e)}
                    type="text"
                    placeholder="Small text"
                    value={selectedCompany.id || ''}
                  >
                    <option value={''}></option>
                    {companies
                    .filter(company => user.company_users.every(cu => cu.company_id !== company.id))
                    .map((c) => {
                      return (
                        <option key={c.value} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col md={2}>
                  <Button
                    disabled={!selectedCompany}
                    variant="link"
                    onClick={() => {
                      addCompanyToUser(selectedCompany);
                      setSelectedCompany({});
                    }}>
                    <span className="material-icons md-18">add</span> {t('add')}
                  </Button>
                </Col>
            </Form.Group>
            {user.company_users.map((uc, index) => {
              return (
                  <CompanyUserItem companyUser={uc} handleDelete={handleCompanyUserDelete} key={index} index={index}/>
                )
              })
            }
          </>
        )}
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('email_address')}*
          </Form.Label>
          <Col>
            <Form.Control
              value={user.email}
              onChange={handleChange}
              name="email"
              size="md"
              type="text"
              placeholder="doe@transmetrics.com"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('phone_number')}
          </Form.Label>
          <Col>
            <Form.Control
              value={user.phone_number || ''}
              name="phone_number"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="+666"
            />
          </Col>
        </Form.Group>
        { !user.id &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('account_type')}*
            </Form.Label>
            <Col>
              <Form.Control
                value={user.account_type}
                onChange={handleChange}
                name="account_type"
                as="select"
                size="md"
              >
                <option value={null}></option>
                <option value="customer">{t('client')}</option>
                <option value="accountant">{t('accountant')}</option>
                <option value={CONSULTANT_ROLE_NAME}>{t('consultant')}</option>
                {shouldShowAdminOption && <option value="admin">{t('admin')}</option>}
              </Form.Control>
            </Col>
          </Form.Group>
        }
        {
          user.account_type === 'accountant' &&
          <Form.Group as={Row}>
            <Form.Label column="md" md={3}>
              {t('company_collaborator')}*
            </Form.Label>
            <Col>
                <Form.Control
                  value={user.collaborating_company_id}
                  onChange={e => changeCollaborator(e)}
                  name="collaborating_company_id"
                  as="select"
                  size="md"
                >
                  <option value={null}></option>
                  {collaboratingCompanies.map(cc => <option key={cc.id} value={cc.id}>{cc.name}</option>)}
                  <option key={0} value={0}>Add new</option>
                </Form.Control>
              </Col>
          </Form.Group>
        }
        { SHOW_DEPARTMENT_FOR_USER_FORM &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('department')}
            </Form.Label>
            <Col>
              <Form.Control
                value={user.department || ''}
                name="department"
                onChange={handleChange}
                size="md"
                type="text"
                placeholder="Department"
              />
            </Col>
          </Form.Group>
        }
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('role')}
          </Form.Label>
          <Col>
            <Form.Control
              value={user.role || ''}
              name="role"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="Consultant/ Dev / etc"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('notes')}
          </Form.Label>
          <Col>
            <Form.Control
              as="textarea"
              rows={3}
              value={user.notes || ''}
              name="notes"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder={t('enter_optional_notes_here')}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('invite_immediately')}
          </Form.Label>
          <Col>
            <Form.Check
              type="checkbox"
              name="invite_immediately"
              checked={user.invite_immediately}
              value={user.invite_immediately}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        { user.account_type === 'admin' &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('display_dashboard')}
            </Form.Label>
            <Col>
              <Form.Check
                type="checkbox"
                name="show_dashboard"
                checked={user.show_dashboard}
                value={user.show_dashboard}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        }
      </Form>
    </>
  )
}

export default UserForm
