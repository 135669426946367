function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { isTextNode, matchNodeOrAnyParentElement } from "../../../domUtils";
import TrackChangesHighlight from "../track_changes/models/TrackChangesHighlight";
import { copyStyleToDataMceStyle } from "./tinyMCEFix";
function isHeaderElement(node) {
  return matchNodeOrAnyParentElement(node, function (nodeToCheck) {
    var _nodeToCheck$style;
    return (_nodeToCheck$style = nodeToCheck.style) === null || _nodeToCheck$style === void 0 || (_nodeToCheck$style = _nodeToCheck$style.fontFamily) === null || _nodeToCheck$style === void 0 ? void 0 : _nodeToCheck$style.toLowerCase().includes('georgia');
  });
}
function isTrackChangesElement(node) {
  return TrackChangesHighlight.isElementAnAdditionOrDeletionHighlight(node);
}
function fixHeaderElementStyles(node) {
  node.style.fontFamily = 'georgia generic';
  if (!node.style.textAlign) {
    node.style.textAlign = 'justify';
  }
  node.style.fontWeight = 'bold';
  node.style.fontSize = '28pt';
  node.style.color = '#666e7e';
  copyStyleToDataMceStyle(node);
}
function fixTrackChangesElementStyles(node) {
  // TODO in this ticket: https://www.pivotaltracker.com/story/show/185606206
}
var MATCHERS_FIXERS = [[isHeaderElement, fixHeaderElementStyles], [isTrackChangesElement, fixTrackChangesElementStyles]];
export var MATCHERS = MATCHERS_FIXERS.map(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    matcher = _ref2[0],
    _ = _ref2[1];
  return matcher;
});
export function fixSpecialElementStyles(node) {
  if (isTextNode(node)) {
    return null;
  }
  var stylesFixed = false;
  MATCHERS_FIXERS.forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      matcher = _ref4[0],
      fixer = _ref4[1];
    if (matcher(node)) {
      fixer(node);
      stylesFixed = true;
    }
  });
  return stylesFixed;
}