import { findNextOrPreviousTextNode, isTextNode } from "../../../domUtils";
var SELF_CLOSING_TAGS = ['AREA', 'BASE', 'BR', 'COL', 'EMBED', 'HR', 'IMG', 'INPUT', 'LINK', 'META', 'PARAM', 'SOURCE', 'TRACK', 'WBR'];
function isSelfClosingTagNode(node) {
  // for now only img, but there can be others as well
  return SELF_CLOSING_TAGS.includes(node.nodeName);
}

/**
 * Usually when we set cursor position we have 2 cases. We have a target node and an offset.
 * The 2 cases are as follows:
 * 1) The node is a text node, and the offset is any number.
 *    In that case TinyMCE considers the offset as the position in the node's textContent.
 *    So the cursor is placed inside the node.
 * 2) The node is an element node. In that case TinyMCE treats the offset as 1 or 0 - aka before of after the node.
 *   So the cursor is placed before or after the node.
 * 
 * However there is a 3rd case where the node is a self-closing tag like an image. Because the tag is self-closing it
 * does not have an offset position of 1. So the cursor cannot be placed after it in the same way as it would in a different
 * element node.
 */
function setCursorPositionToAfterSelfClosingTag(editor, focusedElement) {
  var parentElement = focusedElement.parentElement;
  // note we are using childNodes - to capture all element, text (and possibly other) nodes.
  var indexOfFocusedElement = Array.from(parentElement.childNodes).indexOf(focusedElement);
  editor.selection.setCursorLocation(parentElement, indexOfFocusedElement + 1);
}

/**
 * Moves the cursor one character to the left from its current position
 * @param {TinyMCEEditorInstance} editor 
 * @param {Selection} cursorSelection { anchorNode, anchorOffset, focusNode, focusOffset }
 */
export function moveCursorLeftByOneCharacter(editor, cursorSelection) {
  var focusNode = cursorSelection.focusNode,
    focusOffset = cursorSelection.focusOffset;
  if (focusOffset - 1 > 0) {
    var targetOffset = focusOffset - 1;
    editor.selection.setCursorLocation(focusNode, targetOffset);
    return {
      targetNode: focusNode,
      targetOffset: targetOffset
    };
  } else {
    var previousTextNode = findNextOrPreviousTextNode(focusNode, 'previous');
    if (previousTextNode) {
      var _targetOffset = previousTextNode.textContent.length;
      editor.selection.setCursorLocation(previousTextNode, _targetOffset);
      return {
        targetNode: previousTextNode,
        targetOffset: _targetOffset
      };
    }
  }
}

/**
 * Moves the cursor one character to the right from its current position
 * @param {TinyMCEEditorInstance} editor 
 * @param {Selection} cursorSelection { anchorNode, anchorOffset, focusNode, focusOffset }
 */
export function moveCursorRightByOneCharacter(editor, cursorSelection) {
  var focusNode = cursorSelection.focusNode,
    focusOffset = cursorSelection.focusOffset;
  if (focusOffset + 1 <= focusNode.textContent.length) {
    var targetOffset = focusOffset + 1;
    editor.selection.setCursorLocation(focusNode, focusOffset + 1);
    return {
      targetNode: focusNode,
      targetOffset: targetOffset
    };
  } else {
    var nextTextNode = findNextOrPreviousTextNode(focusNode, 'next');
    if (nextTextNode) {
      var _targetOffset2 = 1;
      editor.selection.setCursorLocation(nextTextNode, _targetOffset2);
      return {
        targetNode: nextTextNode,
        targetOffset: _targetOffset2
      };
    }
  }
}

/**
 * Sets the cursor position after the passed element
 * @param {*} editor 
 * @param {*} focusedElement 
 */
export function setCursorPositionToEndOfElement(editor, focusedElement) {
  // for some reason this needs to be 1 when the focusedElement is an element,
  // and the actual text length when it is a text node

  if (isSelfClosingTagNode(focusedElement)) {
    setCursorPositionToAfterSelfClosingTag(editor, focusedElement);
    return;
  }
  var rightSideOffset = 1;
  if (isTextNode(focusedElement)) {
    rightSideOffset = focusedElement.textContent.length;
  } else if (focusedElement.childNodes.length) {
    rightSideOffset = focusedElement.childNodes.length;
  }
  editor.selection.setCursorLocation(focusedElement, rightSideOffset);
}

/**
 * Sets the cursor position at the start of the passed element
 * @param {*} editor 
 * @param {*} focusedElement 
 */
export function setCursorPositionToStartOfElement(editor, focusedElement) {
  editor.selection.setCursorLocation(focusedElement, 0);
}
export function setCursorPosition(editor, node, offset) {
  editor.selection.setCursorLocation(node, offset);
}