export var ACCEPTED_COLORS = ['RGB(0, 174, 239)',
// ayming blue
'#00AEEF',
// ayming blue
'RGB(102, 110, 126)',
// ayming grey
'#666E7E',
// ayming grey
'RGB(162, 10, 66)',
// ayming red
'#A20A42' // ayming red
];

export var ACCEPTED_FONT_SIZES = ['11pt', '14pt', '28pt'];
export var ACCEPTED_FONT_FAMILIES = ['Franklin Gothic Book', 'Franklin Gothic Demi',
// where do we use this
'georgia generic'];