import { api } from '@rd-web-markets/shared/dist/services/service';
var generalRouteService = {
  get: function get(route, id) {
    return api.get("/".concat(route, "/").concat(id));
  },
  getAll: function getAll(route) {
    var queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return api.get("/".concat(route).concat(queries));
  },
  update: function update(route, id, payload) {
    return api.update("/".concat(route, "/").concat(id), payload);
  },
  delete: function _delete(route, id) {
    return api.delete("/".concat(route, "/").concat(id));
  },
  create: function create(route) {
    var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return api.create("/".concat(route), payload);
  },
  postFormData: function postFormData(route, payload) {
    return api.postFormData("/".concat(route), payload);
  }
};
export default generalRouteService;