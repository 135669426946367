import React, { useEffect, useState, useCallback } from 'react';
import { Breadcrumb, Card, ProgressBar } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TechnicalProofNavigation from '@components/shared/technicalProof/TechnicalProofNavigation';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import CompanyInfoSection from '@components/shared/technicalProof/CompanyInfoSection';
import ClaimProjectReportsSection from '@components/shared/technicalProof/ClaimProjectReportsSection';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import claimGroupTechnicalNarrativeService from '@services/claim_groups/claim_group_technical_narrative.service';
import claimGroupImportTechnicalTemplatesService from '@services/claim/claim_group_import_technical_templates.service';
import ClaimGroupEditAffiliatedCompaniesPage from '../claim_group/ClaimGroupEditAffiliatedCompaniesPage';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import TechnicalTemplatesControls from '@rd-web-markets/market/dist/claim/technicalSection/TechnicalTemplatesControls';
import { MARKET_SPECIFIC_RD_OR_4_MENU, SIDEBAR_TECHNICAL_SECTION_LINKS } from '@rd-web-markets/market/dist/constants';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import TechnicalNarrativeTemplateUploadSection from '@components/shared/technicalProof/TechnicalNarrativeTemplateUploadSection';

const progressBarStyle = {
  fontSize: '1.05rem', height: '24px'
};

const TechnicalProofPage = ({handleToaster, accountType}) => {
  const { page, claimGroupId } = useParams();
  const [claimGroup, setClaimGroup, resetClaimGroup] = claimGroupService.useGetClaimGroup(claimGroupId)
  const [loading, setLoading] = useState(false);
  const [isTechnicalNarrativePending, setIsTechnicalNarrativePending] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateClaimGroup = useErrorHandling(useCallback(async () => {
    try {
      const updatedClaimGroup = await claimGroupService.update(claimGroup);
      setClaimGroup(updatedClaimGroup);
      handleToaster('Claim Group Updated!');
    } catch (error) {
      dispatch(handleError(error));
    }
  }, [claimGroup, dispatch, handleToaster]))

  const handleUploadTechnicalNarrative = useErrorHandling(useCallback(async (e) => {
    let fd = new FormData();
    fd.append('file', e.target.files[0]);
    setLoading(true);
      await claimGroupTechnicalNarrativeService.create(claimGroupId, fd);
      setIsTechnicalNarrativePending(true);
      resetClaimGroup();
      handleToaster('Upload is in progress');

      setLoading(false);
  }, [claimGroupId, resetClaimGroup, handleToaster]))

  const handleImportTechnicalTemplate = useErrorHandling(useCallback(async (e) => {
    let fd = new FormData();
    fd.append('file', e.target.files[0]);
    setLoading(true);
    await claimGroupImportTechnicalTemplatesService.create(claimGroupId, fd)

    setLoading(false);
  }, [claimGroupId]))

  useEffect(() => {
    resetClaimGroup()
  }, [resetClaimGroup]);

  useEffect(() => {
    if (isTechnicalNarrativePending) {
      const intervalId = window.setInterval(() => {
        claimGroupService.get(claimGroup.id)
          .then(claimGroup => {
            setClaimGroup({ ...claimGroup });
            if(claimGroup?.technical_narrative_upload_statuses.slice(-1)[0].import_status !== 'pending') {
              setIsTechnicalNarrativePending(false);
              handleToaster('Import Completed!');
            }
          })
          .catch(err => {
            dispatch(handleError(err.description));
          });
      }, 5000);
      return () => window.clearInterval(intervalId);
    }
  }, [isTechnicalNarrativePending, claimGroup, dispatch, handleToaster]);

  const getSubmenuItems = (menuItem) => {
    if(menuItem.link === 'project_reports') {
      return claimGroup.claim_project_reports.map((report) => {
        return {
          link: `claim_groups/${claimGroup.id}/technical_proof/project_reports/${report.id}`,
          text: report.project_name,
        }
      })
    }
      return menuItem.subMenu.map((item) => {
        return {
          link: `claim_groups/${claimGroup.id}/technical_proof/${item.link}`,
          text: item.text,
          highlighted: item.link === page,
        }
      })
  }

  const getMenuItems = () => {
    return SIDEBAR_TECHNICAL_SECTION_LINKS.map((item) => {
      return {
        link: item.link === 'technical_uploads' ? `claim_groups/${claimGroup.id}/${item.link}` : `claim_groups/${claimGroup.id}/technical_proof/${item.link}`,
        text: item.text,
        subMenu: item.subMenu ? getSubmenuItems(item) : false,
        highlighted: (item.link === 'company_info' && (page === 'project_reports' || page === 'affiliated_companiess')) || item.link === page,
      };
    })
  }

  const getCurrentPage = () => {
    switch (page) {
      case 'company_info':
        return (<>
          <h1 className='text-primary mb-4'>{t('r_d_technical_details')}</h1>
          <Card>
            <Card.Header>
              <TechnicalProofNavigation page={page} claimGroup={claimGroup} />
            </Card.Header>
            <CompanyInfoSection claimGroup={claimGroup} setClaimGroup={setClaimGroup} updateClaimGroup={updateClaimGroup} resetClaimGroup={resetClaimGroup} />
          </Card>
        </>)
      case 'project_reports':
        return (<>
          <h1 className='text-primary mb-4'>{t('r_d_technical_details')}</h1>
          <Card>
            <Card.Header>
              <TechnicalProofNavigation page={page} claimGroup={claimGroup} />
            </Card.Header>
            <ClaimProjectReportsSection claimGroup={claimGroup} />
          </Card>
        </>)
      case 'affiliated_companies':
        return (<>
          <h1 className='text-primary mb-4'>{t('r_d_technical_details')}</h1>
          <Card>
            <Card.Header>
              <TechnicalProofNavigation page={page} claimGroup={claimGroup} />
            </Card.Header>
            <ClaimGroupEditAffiliatedCompaniesPage claimGroup={claimGroup} accountType={accountType} />
          </Card>         
        </>)
      case 'upload_template':
        return (<div style={{ height: '80vh'}}>
          <h1 className='text-primary mb-4'>{t('project_template')}</h1>
          <Card className='p-4'>
            <ProgressBar
              className='mt-5 mb-4'
              style={progressBarStyle}
              variant='success'
              now={claimGroup.completed_percent_tech}
            />
            <TechnicalTemplatesControls
              handleToaster={handleToaster}
              setClaimGroup={setClaimGroup}
              loading={loading}
              claimGroup={claimGroup}
              handleImportTechnicalTemplate={handleImportTechnicalTemplate}
            />
          </Card>
        </div>)
      case 'upload_narrative_template':
        return (<div style={{ height: '80vh'}}>
          <h1 className='text-primary mb-4'>{t('technical_narrative_template')}</h1>
          <Card className='p-4'>
            <ProgressBar
              className='mt-5 mb-4'
              style={progressBarStyle}
              variant='success'
              now={claimGroup.completed_percent_tech}
            />
            <TechnicalNarrativeTemplateUploadSection
              claimGroup={claimGroup}
              loading={loading}
              handleToaster={handleToaster}
              handleUploadTechnicalNarrative={handleUploadTechnicalNarrative}
            />
          </Card>
        </div>)
      default:
        return (<>
          <h1 className='text-primary mb-4'>{t('r_d_technical_details')}</h1>
          <TechnicalProofNavigation page={page} claimGroup={claimGroup} />
          <CompanyInfoSection claimGroup={claimGroup} setClaimGroup={setClaimGroup} updateClaimGroup={resetClaimGroup} />;
        </>)
    }
  }

  if (!claimGroup) return <Loader />;

  return (
    <>
      <SidebarPortal headerItem={{ link: `claim_groups/${claimGroup.id}/project_overview`, text: claimGroup.company.name }} menuItems={getMenuItems()} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/` }}>
            {t('companies')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master` }}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup.name.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t(makeKey(MARKET_SPECIFIC_RD_OR_4_MENU))}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      {getCurrentPage()}
    </>
  );
}

export default TechnicalProofPage
