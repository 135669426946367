function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { useCallback } from 'react';
import { buildQueryString } from '../../services/service';
var useSortRows = function useSortRows(_ref) {
  var tableHeaders = _ref.tableHeaders,
    tableRows = _ref.tableRows,
    setTableRows = _ref.setTableRows;
  var sortRows = useCallback(function (columnIndex, direction) {
    var defaultvalueFnForSortAndFilter = function defaultvalueFnForSortAndFilter(row) {
      return row.columns[columnIndex];
    };
    var valueFnForSortAndFilter = tableHeaders[columnIndex].valueFnForSortAndFilter || defaultvalueFnForSortAndFilter;
    var nonSortableRows = tableRows.filter(function (r) {
      var _r$opts;
      return ((_r$opts = r.opts) === null || _r$opts === void 0 ? void 0 : _r$opts.sort) === false;
    });
    var sortableRows = tableRows.filter(function (r) {
      var _r$opts2;
      return ((_r$opts2 = r.opts) === null || _r$opts2 === void 0 ? void 0 : _r$opts2.sort) !== false;
    });
    var sortedRows = _toConsumableArray(sortableRows).sort(function (a, b) {
      var _ref2 = [valueFnForSortAndFilter(a), valueFnForSortAndFilter(b)],
        valueA = _ref2[0],
        valueB = _ref2[1];
      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      }
      if (direction === 'asc') {
        return valueA === valueB ? 0 : -1;
      } else {
        return valueA === valueB ? 0 : -1;
      }
    });

    // For now those rows are only below the sortable ones.
    // In the future we can add additional functionality here if needed.
    sortedRows.push.apply(sortedRows, _toConsumableArray(nonSortableRows));
    setTableRows(sortedRows);
  }, [setTableRows, tableHeaders, tableRows]);
  return sortRows;
};
function resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex) {
  tableHeaders.forEach(function (h) {
    return h.order = null;
  });
  tableHeaders[columnIndex]._filterText = text;
  setTableHeaders(_toConsumableArray(tableHeaders));
}

// const useResetOrderAndSetFilterText = (tableHeaders, setTableHeaders) => {
//   return useCallback((text, columnIndex) => {
//     resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex)
//   }, [setTableHeaders, tableHeaders])
// }

var useBuildQueryForBackendFiltering = function useBuildQueryForBackendFiltering(_ref3) {
  var tableHeaders = _ref3.tableHeaders,
    setTableHeaders = _ref3.setTableHeaders,
    onFilter = _ref3.onFilter;
  var buildQueryForBackendFiltering = useCallback(function (text, columnIndex) {
    resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex);
    var queries = tableHeaders.map(function (h) {
      return [h.filterBy, h._filterText];
    }).reduce(function (acc, _ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
        key = _ref5[0],
        value = _ref5[1];
      acc[key] = value;
      return acc;
    }, {});
    var queryString = buildQueryString(queries);
    onFilter(tableHeaders, queryString);
  }, [tableHeaders, setTableHeaders, onFilter]);
  return buildQueryForBackendFiltering;
};
var useFilterRowsByText = function useFilterRowsByText(_ref6) {
  var tableHeaders = _ref6.tableHeaders,
    setTableHeaders = _ref6.setTableHeaders,
    rows = _ref6.rows,
    setTableRows = _ref6.setTableRows,
    onFilter = _ref6.onFilter;
  // Sorting is related to filtering
  var filterRowsByText = useCallback(function (text, columnIndex) {
    var _filteredRows;
    resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex);
    var nonFilterableRows = rows.filter(function (r) {
      var _r$opts3;
      return ((_r$opts3 = r.opts) === null || _r$opts3 === void 0 ? void 0 : _r$opts3.filterableByText) === false;
    });
    var filterableRows = rows.filter(function (r) {
      var _r$opts4;
      return ((_r$opts4 = r.opts) === null || _r$opts4 === void 0 ? void 0 : _r$opts4.filterableByText) !== false;
    });
    var filteredRows = filterableRows;
    var _iterator = _createForOfIteratorHelper(tableHeaders.entries()),
      _step;
    try {
      var _loop = function _loop() {
        var _step$value = _slicedToArray(_step.value, 2),
          index = _step$value[0],
          header = _step$value[1];
        if (header.filterableByText) {
          filteredRows = filteredRows.filter(function (r) {
            var filterText = (header._filterText || '').toLowerCase();
            var defaultvalueFnForSortAndFilter = function defaultvalueFnForSortAndFilter(row) {
              return row.columns[index];
            };
            var valueFnForSortAndFilter = header.valueFnForSortAndFilter || defaultvalueFnForSortAndFilter;
            var columnText = (valueFnForSortAndFilter(r) || '').toLowerCase();
            return columnText.includes(filterText);
          });
        }
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    (_filteredRows = filteredRows).push.apply(_filteredRows, _toConsumableArray(nonFilterableRows));
    setTableRows(filteredRows);
  }, [rows, setTableHeaders, setTableRows, tableHeaders]);
  return filterRowsByText;
};
var tableMethods = {
  useSortRows: useSortRows,
  useFilterRowsByText: useFilterRowsByText,
  // useResetOrderAndSetFilterText,
  useBuildQueryForBackendFiltering: useBuildQueryForBackendFiltering
};
export default tableMethods;