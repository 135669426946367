/**
 * Implements the simplest possible cache.
 * A JSON object, containing a key-value pair. Each key is a name of a cache object,
 * Each cache object is a key-value pair.
 * E.g.
 * 
 * For a companies service - we will have AppCache-companies.
 * The companies service may save the following data:
 * '/api/companies/1': { id: 1, name: 'Company 1' }
 * 
 * For now we don't use TTL caching on the frontend, so I decided not to think about this.
 * When we want to - we can switch to a full TTL library.
 */

function clearAllCaches() {
  console.log('App Cache cleared...');
  delete window.APP_CACHE;
}
function getCache(cacheName, maxTimeAlive, timeToLive) {
  if (!window.APP_CACHE) {
    window.APP_CACHE = {};
  }
  var fullCacheName = "AppCache-".concat(cacheName);
  if (!window.APP_CACHE[fullCacheName]) {
    window.APP_CACHE[fullCacheName] = {};
  }
  return window.APP_CACHE[fullCacheName];
}
export var AppCache = {
  getCache: getCache,
  clear: clearAllCaches
};