function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
// import { isEmptySelection, stopEvent, surroundSelectionTextNodesWithDom } from "@rd-web-markets/shared/dist/util/domUtils"
import { isEmptySelection, isTextNode, stopEvent, surroundSelectionTextNodesWithDom } from "../../../../domUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import { setCursorPosition, setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from "../cursorUtils";
export function isAddingNewLine(event) {
  return event.key === 'Enter';
}
export function doAddNewLine(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full)) {
    stopEvent(event);
  }
  var originalAnchorOffset = cursorSelection.anchorOffset;
  if (!isEmptySelection(cursorSelection)) {
    // cutting | pasting | event key enter
    if (contentNode.nodeName === 'IMG') {
      var _contentNode$classLis;
      (_contentNode$classLis = contentNode.classList).add.apply(_contentNode$classLis, _toConsumableArray(DeletionHighlight.createDomElementHighlightClasses().split(' ')));
      setCursorPositionToEndOfElement(editor, contentNode);
      return;
    }
    if (event.key === 'Enter') {
      var _surroundSelectionTex = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion'),
        _surroundSelectionTex2 = _slicedToArray(_surroundSelectionTex, 2),
        leftSideTextNode = _surroundSelectionTex2[0],
        rightSideTextNode = _surroundSelectionTex2[1];

      // previous sibling may be null if we didnt surround the node with deletion highlight - in case it already had an addition highlight and we just deleted part of it
      // In case the left and right text nodes are the same text node under an addition highlight, then its possible that the selection was like the following (between the $):
      // <addition>t$es$t</addition>
      // In the above case we don't want to move the cursor to the start of the right side text node, becuase it would just move the cursor to the start of the text,
      // while in fact we want to add the new line at the middle of the text - like so t\nt.

      var leftSideTextNodeIsRightSideTextNode = leftSideTextNode.isSameNode(rightSideTextNode); // && isTextNode(leftSideTextNode)
      if (!leftSideTextNodeIsRightSideTextNode) {
        setCursorPositionToStartOfElement(editor, rightSideTextNode);
      } else {
        setCursorPosition(editor, cursorSelection.anchorNode, originalAnchorOffset);
      }
    }
  }
}