import React, { useCallback, useEffect } from 'react';
import Modal from '@rd-web-markets/shared/dist/util/Modal';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { setLoading } from '@rd-web-markets/shared/dist/store/features/uiSlice';
import userAccessService from '@rd-web-markets/shared/dist/services/user_access.service';

const ClientReviewMailerModal = ({
  show,
  claimGroup,
  setClaimGroup,
  loading,
  createChangeSetForClient,
  reviewType,
  reviewedObjectClass,
  reviewedObject,
  setModalShow,
}) => {
  const { t } = useTranslation();

  const selectEmails = 
    useErrorHandling(useCallback(async (event, userAccessId, claimGroupToUpdate) => {
      const newUserAccess = await userAccessService.update(claimGroupToUpdate.id, { id: userAccessId, include_in_client_review_email: event.target.checked });

      const userAceessIndex = claimGroupToUpdate.user_accesses.findIndex(uA => uA.id === userAccessId)

      claimGroupToUpdate.user_accesses[userAceessIndex] = {...newUserAccess};
      setClaimGroup({ ...claimGroupToUpdate })
    }, [setClaimGroup]))


  const sendEmailAndStartReview = async (shouldSendEmail) => {
    createChangeSetForClient(reviewedObject, reviewedObjectClass, reviewType, shouldSendEmail, setModalShow);
  };

  const translatedRoles = (roles) => roles.map((r) => t(r)).join(', ');

  const clientContactOptions = claimGroup.user_accesses.sort((a, b) => a.id - b.id).map((uA) => {
    return (
      <div key={uA.user.id}>
        <Form.Check
          checked={uA.include_in_client_review_email}
          name={'email'}
          label={uA.user.name + ' - ' + translatedRoles(uA.roles)}
          onChange={(e) => selectEmails(e, uA.id, claimGroup)}
        />
      </div>
    );
  });

  const claimgGroupHaveClientSponsor = claimGroup.user_accesses.some((uA) => uA.roles.some((r) => r === 'client_sponsor'));

  const noClientSponsorMessage = (
    <p>
      You've not selected a Client Sponsor, please go into the 'Engagement Team' section of Express and appoint a client sponsor
      before setting this claim to a Client Review state
    </p>
  );

  const modalBody = () => {
    return (
      <>
        <Row>
          <Col md={12}>
            <p>
              You are about to send an email notification to your client requesting that they login to Express and review their
              R&D Claim report for claim: {claimGroup.name}
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <p>
              If you want to send this email please select from the Engagement team members who you would like to receive this
              email, once done click on the Send Email button
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <p>Check Box to Include in Email:</p>
          </Col>
        </Row>

        <Row>
          <Col md={8}>{claimgGroupHaveClientSponsor ? clientContactOptions : noClientSponsorMessage}</Col>
          <Col md={4}>
            <Button
              variant='info'
              iconPosition='left'
              icon='mail'
              loading={loading}
              onClick={() => sendEmailAndStartReview(true)}
            >
              {t('send_email_and_start_the_client_review')}
            </Button>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={12}>
            <p>
              If you want to start this review but not send an email to the client click the below 'Start Client Review but do not
              send email' button
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8}></Col>
          <Col md={4}>
            <Button
              variant='info'
              iconPosition='left'
              icon='mail'
              loading={loading}
              onClick={() => sendEmailAndStartReview(false)}
            >
              {t('do_not_send_the_email_but_start_the_client_review')}
            </Button>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={12}>
            <p>If you want to abort this request click the Cancel button and the client review will not be started.</p>
          </Col>
        </Row>
      </>
    );
  };

  return <Modal show={show} size='xl' renderBody={() => modalBody()} onHide={() => setModalShow(false)} />;
};

export default ClientReviewMailerModal;
