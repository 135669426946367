import { createSelectionDataFromArray, isNodeBeforeAnotherInDom } from "../../../domUtils";
import { findAdjacentTrackChangesElementssOfTheSameType } from "./highlighintUtils";
import TrackChangesHighlight from "./models/TrackChangesHighlight";
export function findNextPreviousNonAdjacentChanges(editor, changeElementsOfCurrentPopover) {
  var allChangeElements = Array.from(editor.getBody().querySelectorAll('.TrackChanges-Highlight'));
  var elementsParentNotInPopover = function elementsParentNotInPopover(element) {
    return !changeElementsOfCurrentPopover.some(function (changeElement) {
      return changeElement.contains(element);
    });
  };
  var nonAdjacentChangeElements = allChangeElements.filter(function (changeElement) {
    return !changeElementsOfCurrentPopover.includes(changeElement) && elementsParentNotInPopover(changeElement);
  });
  var nextPopoverChangeElement = nonAdjacentChangeElements[0];
  var previousPopoverChangeElement = nonAdjacentChangeElements[0];
  if (changeElementsOfCurrentPopover !== null && changeElementsOfCurrentPopover !== void 0 && changeElementsOfCurrentPopover.length) {
    nextPopoverChangeElement = nonAdjacentChangeElements.find(function (node) {
      return isNodeBeforeAnotherInDom(node, changeElementsOfCurrentPopover[changeElementsOfCurrentPopover.length - 1]);
    });
    var allPreviousChangeElements = nonAdjacentChangeElements.filter(function (changeElement) {
      return isNodeBeforeAnotherInDom(changeElementsOfCurrentPopover[0], changeElement);
    });
    previousPopoverChangeElement = allPreviousChangeElements[allPreviousChangeElements.length - 1];
  }
  return {
    nextPopoverChangeElement: nextPopoverChangeElement,
    previousPopoverChangeElement: previousPopoverChangeElement
  };
}
export function createEditorSelectionWithAllRelatedTrackChangesElements(editor, trackChangeElement) {
  var doc = editor.getDoc();
  var nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(trackChangeElement);
  var relatedNodesAndContentNode = findAdjacentTrackChangesElementssOfTheSameType(trackChangeElement, nodeTrackChangesTypeClass);
  var selection = createSelectionDataFromArray(doc, relatedNodesAndContentNode);
  return selection;
}