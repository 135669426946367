import React from 'react'
import Dashboard from '@rd-web-markets/market/dist/dashboards/Dashboard';

const DashboardPage = () => {

  return (
    <>
      <div className='mt-3'></div>
        <Dashboard />
      <div className='mb-5'></div>
    </>
  );
};
export default DashboardPage;
