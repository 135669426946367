import { isTextNode, matchNodeOrAnyParentElement } from "../../../domUtils";
import { ACCEPTED_COLORS, ACCEPTED_FONT_FAMILIES, ACCEPTED_FONT_SIZES } from "./settings";
import { copyDataMceStyleToStyle, copyStyleToDataMceStyle } from "./tinyMCEFix";
function areEditorColorButtonsEnabled(editor) {
  var fullToolbar = editor.settings.toolbar.join(' ');
  return fullToolbar.includes('forecolor') || fullToolbar.includes('backcolor');
}

/**
 * Do not change an element's color if it has an accepted color
 * OR
 * if it has no color, but any of its parents has an accepted color.
 * https://github.com/Ayming-Digital-UK/rd-backend/pull/597
 * @param {*} element 
 * @returns 
 */
function shouldSkipSettingColorToAymingGrey(editor, element) {
  var elementOrAynParentHasAcceptedColor = matchNodeOrAnyParentElement(element, function (element) {
    var _element$style;
    return ACCEPTED_COLORS.includes(element === null || element === void 0 || (_element$style = element.style) === null || _element$style === void 0 ? void 0 : _element$style.color.toUpperCase());
  });
  var elementHasNoColorDefined = !element.style.color;
  var elementHasAcceptedColor = ACCEPTED_COLORS.includes(element.style.color.toUpperCase());
  var shouldSkip = elementOrAynParentHasAcceptedColor && elementHasNoColorDefined || elementHasAcceptedColor;

  // if changing colors is not enabled in the editor, we should always fall back to ayming grey
  return areEditorColorButtonsEnabled(editor) && shouldSkip;
}
export function fixElementStyles(editor, element) {
  if (isTextNode(element)) {
    return null;
  }
  copyDataMceStyleToStyle(element);
  if (!element.style.textAlign) {
    element.style.textAlign = 'justify';
  }
  if (!shouldSkipSettingColorToAymingGrey(editor, element)) {
    element.style.color = '#666e7e'; // ayming grey,
  }

  if (!ACCEPTED_FONT_SIZES.includes(element.style.fontSize)) {
    element.style.fontSize = '11pt';
  }
  if (!ACCEPTED_FONT_FAMILIES.includes(element.style.fontFamily)) {
    element.style.fontFamily = 'Franklin Gothic Book';
  }
  copyStyleToDataMceStyle(element);
}