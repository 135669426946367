function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { stopEvent } from "../../../domUtils";
import predictInputValue from "../../../predictInputValue";
import { shouldExitOnKeyDownTrackChangesFunctionWithoutStoppingEvent, shouldStopEventAndExitOnKeyDownTrackChangesFunction } from './tinyMceEditorUtils';
import * as DeleteAction from './editorActions/DeleteAction';
import * as InsertAction from './editorActions/InsertAction';
import * as PasteAction from './editorActions/PasteAction';
import * as CutAction from './editorActions/CutAction';
import * as AddNewLineAction from './editorActions/AddNewLineAction';
import DynamicField from "./models/DynamicField";
export function onSelectionChange(event, editor) {
  DynamicField.disableMenuButtonsIfTheSelectionFallsInsideADynamicField(editor);
}

/**
 * Cut but also highlight the selection with a deletion highliht.
 * Stop the event propagation so that the browser does not remove the cut content.
 * @param {*} event 
 * @param {*} activeEditorInstance 
 * @returns 
 */
export function onCut(event, activeEditorInstance) {
  CutAction.onCutEvent(event, activeEditorInstance);
}

/**
 * Either paste or add characters or delete.
 * Cut is handled inside onCut.
 * @param {*} event 
 * @param {*} editor 
 * @returns 
 */
export function onKeyDown(event, editor) {
  var cursorSelection = editor.selection.getSel();
  var contentNode = editor.selection.getNode();
  if (shouldExitOnKeyDownTrackChangesFunctionWithoutStoppingEvent(event, cursorSelection, contentNode)) {
    return;
  }
  if (shouldStopEventAndExitOnKeyDownTrackChangesFunction(event, cursorSelection, contentNode)) {
    stopEvent(event);
    return;
  }

  // Note that selection data is not passed, and this is a bit hacky.
  // We don't have a maxLength, so passing a big default value.
  // Ths just shows whether the pressed keys would result in a change of the value of the editor or not.
  var predictedInputValueOrNull = predictInputValue(_objectSpread(_objectSpread({}, event), {}, {
    // TODO: fix in new function
    target: {
      // TODO: fix in new function
      value: contentNode.textContent,
      selectionStart: cursorSelection.anchorOffset,
      // TODO: probably not necessary
      selectionEnd: cursorSelection.focusOffset,
      // TODO: probably not necessary
      maxLength: 9999999999 // TODO: fix in new function
    }
  }));

  if (DeleteAction.isDeleting(event, editor, cursorSelection, contentNode)) {
    DeleteAction.doDelete(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (InsertAction.isInserting(event, editor, cursorSelection, contentNode, predictedInputValueOrNull)) {
    InsertAction.doInsert(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (PasteAction.isPasting(event)) {
    PasteAction.doPaste(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (CutAction.isCutting(event)) {
    CutAction.doCut(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  } else if (AddNewLineAction.isAddingNewLine(event)) {
    AddNewLineAction.doAddNewLine(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
    return;
  }
}