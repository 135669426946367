import { matchNodeOrAnyParentElement } from "../../../domUtils";
import TrackChangesPopover from "./popover/TrackChangesPopover";
import { addEditorMenuButtons } from "./buttons";
import TrackChangesHighlight from "./models/TrackChangesHighlight";
import { preservePastedColors } from "./preservePastedColors";
function createTrackChangesPopoverIfClickedOnChange(event, editor, moduleInstance) {
  if (TrackChangesPopover.shouldShowPopup(event, editor)) {
    // commented out, as the event.target is relevant only in deletion highlights when we have contentedible=false
    // const changeElement = matchNodeOrAnyParentElement(event.target, TrackChangesHighlight.isElementAnAdditionOrDeletionHighlight)

    // We will only show the track changes popover on empty selection, so the anchor will be the same as the focus node.
    var changeElement = matchNodeOrAnyParentElement(editor.selection.getSel().anchorNode, TrackChangesHighlight.isElementAnAdditionOrDeletionHighlight);
    if (!changeElement) {
      changeElement = event.target.classList.contains('TrackChanges-Highlight') ? event.target : null;
    }
    moduleInstance.createPopover(editor, changeElement);
  } else {
    TrackChangesPopover.closeAllPopovers(editor);
  }
}
var track_changes = {
  name: 'track_changes',
  currentPopover: null,
  // set from createPopover
  onAddComment: null,
  // set from createPopover
  toggleTrackChangesVisibility: true,
  addIfApplicable: function addIfApplicable(editor, _ref) {
    var isTrackingChanges = _ref.isTrackingChanges,
      showTrackChangesControls = _ref.showTrackChangesControls;
    var moduleInstance = this;
    if (!isTrackingChanges) {
      return false;
    }
    if (showTrackChangesControls) {
      addEditorMenuButtons(editor, this);
    }
    editor.on('click', function (event) {
      createTrackChangesPopoverIfClickedOnChange(event, editor, moduleInstance);
    });
    return true;
  },
  paste_preprocess: preservePastedColors,
  createPopover: function createPopover(editor, changeElement) {
    // this.currentPopover?.close()
    TrackChangesPopover.closeAllPopovers(editor);
    this.currentPopover = TrackChangesPopover.create(editor, changeElement, function (cursorSelection) {
      editor.fire('Comments:triggerChangeSelectedCommentSpanId', {
        payload: {
          cursorSelection: cursorSelection
        }
      });
    });
  }
};
export default track_changes;