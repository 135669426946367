function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { createSlice } from '@reduxjs/toolkit';
export var companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: []
  },
  reducers: {
    storeCompanies: function storeCompanies(state, _ref) {
      var companies = _ref.payload;
      state.companies = companies;
    },
    storeCompany: function storeCompany(state, _ref2) {
      var company = _ref2.payload;
      var companyIndex = state.companies.findIndex(function (storedCompany) {
        return storedCompany.id === company.id;
      });
      if (companyIndex >= 0) {
        state.companies.splice(companyIndex, 1, company);
      } else {
        state.companies = [].concat(_toConsumableArray(state.claimProjectReports), [company]);
      }
    }
  }
});
var _companiesSlice$actio = companiesSlice.actions,
  storeCompanies = _companiesSlice$actio.storeCompanies,
  storeCompany = _companiesSlice$actio.storeCompany;
export { storeCompanies, storeCompany };
export default companiesSlice.reducer;