import editorMenuUtils from '../editorMenuUtils';
export function addCommentButtons(editor, moduleInstance) {
  editor.ui.registry.addButton('add_comment', {
    text: 'Add Comment',
    tooltip: 'Add Comment',
    onAction: function onAction() {
      moduleInstance._updateSelectedSpanIdFromCursorSelection(editor, editor.selection.getSel());
    }
  });
  editorMenuUtils.addToggleButton({
    editor: editor,
    moduleInstance: moduleInstance,
    toggleStatePropertyName: 'commentsViewVisible',
    buttonName: 'comments_view_toggle',
    textIfToggled: 'Hide Comments',
    textIfNotToggled: 'Show Comments',
    onToggleCallback: function onToggleCallback(state) {
      editor.fire('Comments:commentViewToggled', {
        payload: {
          commentsViewVisible: moduleInstance.commentsViewVisible
        }
      });
    }
  });
}