function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { v4 as uuidv4 } from 'uuid';
import { findAnyNodeOrElementSibling, findFirstNonEmptyTextNode, findLDeepestRightmostChildTextNodeElement, findLastNonEmptyTextNode, getTextNodeByCharIndex, isEmptySelection, isTextNode, stopEvent, surroundSelectionTextNodesWithDom, surroundTextNodeContent, userIsCuttingOrPasting } from "@rd-web-markets/shared/dist/util/domUtils";
import { moveCursorLeftByOneCharacter, moveCursorRightByOneCharacter, setCursorPositionToEndOfElement, setCursorPositionToStartOfElement } from "../cursorUtils";
import selectionUtils from "../selectionUtils";
import TrackChangesHighlight from "../models/TrackChangesHighlight";
import { isEditorSelectionCollapsedOnAnImage } from "../tinyMceEditorUtils";
import DeletionHighlight from "../models/DeletionHighlight";
import DynamicField from "../models/DynamicField";

// Depending on whether we delete slowly or quickly and this seems to only depend on the speed
// sometimes the textContent iis '' as the editor seems to produce an empty text node for whatever reason.
// Thuse we need to change the cursorSelection to either the left or the right node
function getCursorSelectionForCharacterDeletionWhenAnchorTextContentIsEmpty(deleteKey, cursorSelection) {
  var selection = cursorSelection;
  if (deleteKey === 'Backspace') {
    var _cursorSelection$anch, _cursorSelection$anch2;
    var targetNode = isTextNode((_cursorSelection$anch = cursorSelection.anchorNode) === null || _cursorSelection$anch === void 0 ? void 0 : _cursorSelection$anch.previousSibling) ? (_cursorSelection$anch2 = cursorSelection.anchorNode) === null || _cursorSelection$anch2 === void 0 ? void 0 : _cursorSelection$anch2.previousSibling : findLastNonEmptyTextNode(cursorSelection.anchorNode.previousElementSibling);
    selection = {
      anchorNode: targetNode,
      anchorOffset: targetNode.textContent.length - 1,
      focusNode: targetNode,
      focusOffset: targetNode.textContent.length - 1
    };
  } else if (deleteKey === 'Delete') {
    var _cursorSelection$anch3, _cursorSelection$anch4;
    var _targetNode = isTextNode((_cursorSelection$anch3 = cursorSelection.anchorNode) === null || _cursorSelection$anch3 === void 0 ? void 0 : _cursorSelection$anch3.nextSibling) ? (_cursorSelection$anch4 = cursorSelection.anchorNode) === null || _cursorSelection$anch4 === void 0 ? void 0 : _cursorSelection$anch4.nextSibling : findFirstNonEmptyTextNode(cursorSelection.anchorNode.nextElementSibling);
    selection = {
      anchorNode: _targetNode,
      anchorOffset: 0,
      focusNode: _targetNode,
      focusOffset: 0
    };
  }
  return selection;
}
function onDeleteCharacter(editor, tinyContentNode, cursorSelection, charMarkedForDeletionIndex, deleteKey) {
  if (cursorSelection.focusNode.nodeName === 'DIV') {
    var childNode = cursorSelection.focusNode.childNodes[cursorSelection.focusOffset - 1];
    if ((childNode === null || childNode === void 0 ? void 0 : childNode.nodeName) === 'IMG') {
      var _childNode$classList;
      (_childNode$classList = childNode.classList).add.apply(_childNode$classList, _toConsumableArray(DeletionHighlight.createDomElementHighlightClasses().split(' ')));
      var prevNode = findAnyNodeOrElementSibling(childNode, 'previous');
      var nextNode = findAnyNodeOrElementSibling(childNode, 'next');
      return [prevNode, nextNode];
    }
  }
  var selection = cursorSelection;
  if (cursorSelection.anchorNode.textContent === '') {
    selection = getCursorSelectionForCharacterDeletionWhenAnchorTextContentIsEmpty(deleteKey, cursorSelection);
  }
  var dynamicFieldBeingEditted = DynamicField.isEditingPartOfADynamicField(selection.anchorNode, selection);

  /**
   * If pressing DELETE / BACKSPACE on a dynamic field - highlight the whole dynamic field for deletion
   */
  if (dynamicFieldBeingEditted) {
    return DeletionHighlight.surroundDynamicField(editor, dynamicFieldBeingEditted);
  }

  // assume this is the Backspace button
  if (charMarkedForDeletionIndex < 0) {
    return [null, null];
  }
  var targetNode = isTextNode(selection.anchorNode) ? selection.anchorNode : getTextNodeByCharIndex(selection.anchorNode, charMarkedForDeletionIndex);
  if (charMarkedForDeletionIndex >= targetNode.textContent.length && deleteKey === 'Delete') {
    var nextSibling = targetNode.nextSibling || targetNode.parentElement.nextSibling;
    if (!nextSibling) {
      return [null, null];
    }
    targetNode = isTextNode(nextSibling) ? nextSibling : getTextNodeByCharIndex(nextSibling, charMarkedForDeletionIndex);
    charMarkedForDeletionIndex = 0;
  }
  var _surroundTextNodeCont = surroundTextNodeContent(editor.getDoc(), targetNode, DeletionHighlight.createHighlightedElement, charMarkedForDeletionIndex, charMarkedForDeletionIndex + 1),
    _surroundTextNodeCont2 = _slicedToArray(_surroundTextNodeCont, 2),
    beforeTextNode = _surroundTextNodeCont2[0],
    afterTextNode = _surroundTextNodeCont2[1];
  return [beforeTextNode, afterTextNode];
}
function highlightDeletionChangesWhenSelectionisNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull) {
  // addition | deletion
  if (!userIsCuttingOrPasting(event)) {
    if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full)) {
      stopEvent(event);
    }
  }

  // addition | deletion | cutting | pasting
  if (contentNode.nodeName === 'IMG') {
    var _contentNode$classLis;
    (_contentNode$classLis = contentNode.classList).add.apply(_contentNode$classLis, _toConsumableArray(DeletionHighlight.createDomElementHighlightClasses().split(' ')));
    return;
  }
  if (!!(predictedInputValueOrNull !== null && predictedInputValueOrNull !== void 0 && predictedInputValueOrNull.full) && contentNode.textContent !== (predictedInputValueOrNull === null || predictedInputValueOrNull === void 0 ? void 0 : predictedInputValueOrNull.full) || userIsPastingContent(event) || event.key === 'Enter') {
    var _surroundSelectionTex = surroundSelectionTextNodesWithDom(editor.getDoc(), cursorSelection, DeletionHighlight.createHighlightedElement, DeletionHighlight.createDomElementHighlightClasses, 'TrackChanges-Highlight-Addition', 'TrackChanges-Highlight-Deletion'),
      _surroundSelectionTex2 = _slicedToArray(_surroundSelectionTex, 2),
      leftSideTextNode = _surroundSelectionTex2[0],
      rightSideTextNode = _surroundSelectionTex2[1];
    if (event.key === 'Backspace') {
      setCursorPositionToEndOfElement(editor, leftSideTextNode);
    } else if (event.key === 'Delete') {
      // previous sibling may be null if we didnt surround the node with deletion highlight - in case it already had an addition highlight and we just deleted part of it
      setCursorPositionToStartOfElement(editor, rightSideTextNode);
    }
  }
}

// we are deleting a single character - nothing would happen if we press CTRL-[C|X] with empty selection
function highlightDeletionChangesWhenSelectionisEmpty(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  if (event.key === 'Backspace') {
    var _selectionUtils$creat = selectionUtils.createSelectionObjectForDeletion(cursorSelection, true),
      cursorSelectionCopy = _selectionUtils$creat.cursorSelectionCopy,
      targetNode = _selectionUtils$creat.targetNode;
    var _onDeleteCharacter = onDeleteCharacter(editor, targetNode, cursorSelectionCopy, cursorSelectionCopy.anchorOffset - 1, event.key),
      _onDeleteCharacter2 = _slicedToArray(_onDeleteCharacter, 2),
      beforeTextNode = _onDeleteCharacter2[0],
      _ = _onDeleteCharacter2[1];
    if (beforeTextNode === null) {
      return;
    }
    setCursorPositionToEndOfElement(editor, beforeTextNode);
  } else if (event.key === 'Delete') {
    var _selectionUtils$creat2 = selectionUtils.createSelectionObjectForDeletion(cursorSelection, false),
      _cursorSelectionCopy = _selectionUtils$creat2.cursorSelectionCopy,
      _targetNode2 = _selectionUtils$creat2.targetNode;
    var _onDeleteCharacter3 = onDeleteCharacter(editor, _targetNode2, _cursorSelectionCopy, _cursorSelectionCopy.anchorOffset - 1, event.key),
      _onDeleteCharacter4 = _slicedToArray(_onDeleteCharacter3, 2),
      _2 = _onDeleteCharacter4[0],
      afterTextNode = _onDeleteCharacter4[1];
    setCursorPositionToStartOfElement(editor, afterTextNode);
  }
  stopEvent(event);
}

// Only called if ['Delete', 'Backspace'].includes(event.key)
//
export function doDelete(event, editor, cursorSelection, contentNode, predictedInputValueOrNull) {
  // if we are trying to delete a deletion highlight, just move the cursor and return
  // from the event without doing anything else
  if (selectionUtils.isUserDeletingADeletionHighlightWithEmptySelection(cursorSelection, event.key)) {
    var moduleInstance = editor.AYMING_MODULES.find(function (m) {
      return m.name === 'track_changes';
    });
    var isShowingTrackChanges = moduleInstance.toggleTrackChangesVisibility;
    if (event.key === 'Backspace') {
      if (isShowingTrackChanges) {
        moveCursorLeftByOneCharacter(editor, cursorSelection);
      } else {
        var untilOutsideDeletionSelection = {
          anchorNode: cursorSelection.anchorNode,
          anchorOffset: cursorSelection.anchorOffset,
          focusNode: cursorSelection.focusNode,
          focusOffset: cursorSelection.focusOffset
        };
        while (selectionUtils.isUserDeletingADeletionHighlightWithEmptySelection(untilOutsideDeletionSelection, event.key)) {
          var _moveCursorLeftByOneC = moveCursorLeftByOneCharacter(editor, untilOutsideDeletionSelection),
            targetNode = _moveCursorLeftByOneC.targetNode,
            targetOffset = _moveCursorLeftByOneC.targetOffset;
          untilOutsideDeletionSelection = {
            anchorNode: targetNode,
            focusNode: targetNode,
            anchorOffset: targetOffset,
            focusOffset: targetOffset
          };
        }
      }
    } else if (event.key === 'Delete') {
      if (isShowingTrackChanges) {
        moveCursorRightByOneCharacter(editor, cursorSelection);
      } else {
        var _untilOutsideDeletionSelection = {
          anchorNode: cursorSelection.anchorNode,
          anchorOffset: cursorSelection.anchorOffset,
          focusNode: cursorSelection.focusNode,
          focusOffset: cursorSelection.focusOffset
        };
        while (selectionUtils.isUserDeletingADeletionHighlightWithEmptySelection(_untilOutsideDeletionSelection, event.key)) {
          var _moveCursorRightByOne = moveCursorRightByOneCharacter(editor, _untilOutsideDeletionSelection),
            _targetNode3 = _moveCursorRightByOne.targetNode,
            _targetOffset = _moveCursorRightByOne.targetOffset;
          _untilOutsideDeletionSelection = {
            anchorNode: _targetNode3,
            focusNode: _targetNode3,
            anchorOffset: _targetOffset,
            focusOffset: _targetOffset
          };
        }
      }
    }

    // prevent tinymce from handling the keypress and deleting
    stopEvent(event);
    return;
  }
  if (selectionUtils.isUserDeletingAnAdditionHighlightWithEmptySelection(cursorSelection, event.key)) {
    if (event.key === 'Backspace' && !TrackChangesHighlight.isNodeAOrInAHighlight(cursorSelection.anchorNode, 'addition')) {
      var _previousSibling$chil;
      var previousSibling = findAnyNodeOrElementSibling(cursorSelection.anchorNode, 'previous');
      // if it is an element with children - e..g <div><span></span><span></span>text_node</div> - we need its last child element,
      // as technicallly it is the first sibling on the left of node
      if ((_previousSibling$chil = previousSibling.children) !== null && _previousSibling$chil !== void 0 && _previousSibling$chil.length) {
        previousSibling = findLDeepestRightmostChildTextNodeElement(previousSibling);
      }
      setCursorPositionToEndOfElement(editor, previousSibling);
    }
    return;
  }
  if (isEditorSelectionCollapsedOnAnImage(editor)) {
    editor.selection.getStart().className += " ".concat(DeletionHighlight.createDomElementHighlightClasses());
    stopEvent(event);
    return;
  }
  if (isEmptySelection(cursorSelection)) {
    highlightDeletionChangesWhenSelectionisEmpty(event, editor, cursorSelection, contentNode, predictedInputValueOrNull);
  } else {
    highlightDeletionChangesWhenSelectionisNotEmpty(event, editor, contentNode, cursorSelection, predictedInputValueOrNull);
  }
}
export function isDeleting(event) {
  return ['Delete', 'Backspace'].includes(event.key);
}