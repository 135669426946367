import React, { useEffect, useState, useCallback } from 'react'
import { Breadcrumb, Card, Row } from 'react-bootstrap';
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useTranslation } from 'react-i18next';
import { companyGroupService } from '@services/company_group.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import MasterSidebar from '@components/util/MasterSidebar';
import CompanyGroupEditForm from '@components/admin/companyGroup/CompanyGroupEditForm';
import { SubmitButton } from '@rd-web-markets/shared/dist/util/buttons';
import CompanyAddForm from '@components/admin/companyGroup/CompanyAddForm';
import CompaniesList from '@components/admin/companyGroup/CompaniesList';
import { CompanyService } from '@services/company.service';

const CompanyGroupEditPage = ({accountType}) => {
  let { company_group_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [companyGroup, setCompanyGroup] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();


  const fetchCompanyGroup = useErrorHandling(useCallback(async () => {
    const response = await companyGroupService.get(company_group_id);
    const companies = await CompanyService.all();
    setCompanies(companies.companies);
    setCompanyGroup(response);
    setLoading(false);
  }));

  useEffect(() => {
    setLoading(true);
    fetchCompanyGroup();
  }, [history]);

  const handleChange = (e) => {
    const value = e.target.value;
    setCompanyGroup({
      ...companyGroup,
      [e.target.name]: value,
    });
  };

  const handleSubmit = useErrorHandling(useCallback(async () => {
    await companyGroupService.update(companyGroup);
    setLoading(false);
  }, [companyGroup]));



  if(!companyGroup || !companies) return <div>Loading</div>


  return <>
    <MasterSidebar accountType={accountType} currentPage='companies' />
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies`}}>{t('companies')}</Breadcrumb.Item>
        <Breadcrumb.Item active>{t('edit')}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>

    <Card>
      <Card.Header className="bg-gradient-primary text-white">{t('edit_company')}</Card.Header>
      <Card.Body>
        <CompanyGroupEditForm
          handleChange={handleChange}
          companyGroup={companyGroup}
        />
        <CompanyAddForm companies={companies} companyGroup={companyGroup} setCompanyGroup={setCompanyGroup}/>
        <CompaniesList companyGroup={companyGroup} setCompanyGroup={setCompanyGroup} />
      <Row className='modal-footer'>
        <SubmitButton isImmutableUnderReview={true} loading={loading} onClick={handleSubmit} variant="primary">
          <span className="material-icons md-18">save</span> {t('save')}
        </SubmitButton>
      </Row>
      </Card.Body>
    </Card>
  </>;
}

export default CompanyGroupEditPage