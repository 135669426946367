function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { useEffect, useState } from 'react';

/**
 * Creates a proxy that says that if the abilities for a certain page are not defined - then by default everything is true (allowed) for that page.
 * So e.g. abilities.page_1.can_see_button - may be defined as false for the UK, but we may not have any such definition for this page in Germany.
 * With this proxy we can simply write 
 *    if (abilities.page_1.can_see_button) - which will give false for the UK and true for Germany.
 * 
 * In addition we have defined a level of nesting on 'componemts' - check the array passed to createNestedPagesPropProxy.
 * The first nesting level is pages, followed by null, then components, followed by null.
 * This means that abilities.page_1.can_see_button will default to true, and abilities.page_1.components.component_1.can_see_button
 * will also default to true.
 * 
 * However abilities.page_1.components.component_1.someting.between.can_see_button - will evaluate to
 *         proxy     proxy  proxy      proxy       true     undefined  Error
 * 
 * If we dont have this proxy - we will need to have a lot of if statements / ternary operators everywhere in the code.
 * Or we will have to defined every single ability for every single market which may not be feasible.
 * 
 * @returns {Proxy} the proxy to the provided user abilities object
 */
export function createPagesAbilitiesProxy(userClaimGroupAbilities) {
  var createNestedPagesPropProxy = function createNestedPagesPropProxy(abilitiesPages, nestedPropsArray) {
    var currentLevelNestedPropName = nestedPropsArray === null || nestedPropsArray === void 0 ? void 0 : nestedPropsArray.shift();
    return new Proxy(abilitiesPages || {}, {
      get: function get(target, prop) {
        if (prop in target) {
          var value = target[prop];
          if (_typeof(value) === 'object' && value !== null) {
            return createNestedPagesPropProxy(value, nestedPropsArray);
          }
          return value;
        }
        if (prop === currentLevelNestedPropName || currentLevelNestedPropName === null) {
          return createNestedPagesPropProxy({}, nestedPropsArray);
        }
        return true;
      }
    });
  };
  var abilitiesProxy = new Proxy(userClaimGroupAbilities || {}, {
    get: function get(abilitiesObject, prop) {
      if (prop === 'pages') {
        var res = createNestedPagesPropProxy(abilitiesObject[prop], [null, 'components', null]);
        return res;
      }
      return abilitiesObject[prop];
    }
  });
  return abilitiesProxy;
}

// default empty abilities implementation - so I dont have to copy paste this in every market
function getUserClaimGroupAbilities(user, claimGroupId) {
  return createPagesAbilitiesProxy({
    pages: {}
  });
}

// default useGetUserClaimGroupAbilities implementation - so I dont have to copy paste this in every market
export function useGetUserClaimGroupAbilities(user, claimGroupId) {
  // it always initially returns this state, so we need to build it asap
  var _useState = useState(getUserClaimGroupAbilities(user, claimGroupId)),
    _useState2 = _slicedToArray(_useState, 2),
    userClaimGroupAbilities = _useState2[0],
    setUserClaimGroupAbilities = _useState2[1];
  useEffect(function () {
    setUserClaimGroupAbilities(getUserClaimGroupAbilities(user, claimGroupId));
  }, [user, claimGroupId]);
  return userClaimGroupAbilities;
}