import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Card, Row, Col, Form } from 'react-bootstrap';
import ClaimProjectForm from '@rd-web-markets/market/dist/claim/editForm/ClaimProjectForm';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupBreadcrumbLinks from '@components/shared/claim_group/edit/ClaimGroupBreadcrumbLinks';
import ClaimGroupEditNavigation from '@components/shared/claim_group/edit/ClaimGroupEditNavigation';
import ClaimGroupEditFooter from '@components/shared/claim_group/edit/ClaimGroupEditFooter';
import claimProjectReportService from '@services/claim_project_report.service';
import ClaimGroupSidebar from './ClaimGroupSidebar';
import claimGroupReportTemplateService from '@rd-web-markets/shared/dist/services/claim_group_report_templates.service';
import { SHOULD_CREATE_REPORT } from '@rd-web-markets/market/dist/constants';
import { formatProjectDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { IS_PROJECT_DATE_SHORT_FORMAT } from '@rd-web-markets/market/dist/constants';
import { t } from 'i18next';
import { SHOW_CLAIM_GROUP_PROJECT_ELSTER } from '@rd-web-markets/market/dist/constants';

const ClaimGroupEditProjectsPage = ({handleToaster, accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = claimGroupService.useGetClaimGroup(claimGroupId);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();


  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    claimGroup.claim_project_reports_attributes = claimGroup.claim_project_reports;
    setLoading(true);
    try {
      if(claimGroup.selectedTemplate?.name && SHOULD_CREATE_REPORT){ //Italy
        claimGroup.selectedTemplate.report_template_categories_attributes = claimGroup.selectedTemplate.report_template_categories;
        await claimGroupReportTemplateService.create(claimGroup.id, claimGroup.selectedTemplate);
      }
      setClaimGroup(await claimGroupService.update(claimGroup));
      setLoading(false);
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleReportOrderChange = async (event, index) => {
    event.preventDefault();

    if(window.confirm('Are you sure you want to reorder the projects')) {
      setLoading(true);
      try {
        const claim_project_report_copy = claimGroup.claim_project_reports;
        const value = event.target.value;
        claim_project_report_copy[index][event.target.name] = value;
        claimGroup.claim_project_reports_attributes = claim_project_report_copy;
        const updatedClaimGroup = await claimGroupService.update(claimGroup);
        setClaimGroup(updatedClaimGroup);
      } catch(err) { 
        dispatch(handleError(err));
      } finally { 
        setLoading(false);
      }
    }
  }

  const addProject = () => {
    const claim_projects_copy = [...claimGroup.claim_project_reports];
    const firstClaimIndex = claimGroup.claims.length - 1;
    if(process.env.PUBLIC_URL === '/it'){
      claim_projects_copy.push({
        uniqueKey: new Date().getTime() + claimGroup.claim_project_reports?.length || 1,
        project_name: '',
        start_date: claimGroup.claims[0].from_date,
        end_date: claimGroup.claims[firstClaimIndex].to_date,
      });
    }else {
      const from_date = (claimGroup.from_date || '')
      const end_date = (claimGroup.to_date || '')
      claim_projects_copy.push({
        uniqueKey: new Date().getTime() + claimGroup.claim_project_reports?.length || 1,
        project_name: '',
        start_date: IS_PROJECT_DATE_SHORT_FORMAT ? from_date.slice(3) : from_date,
        end_date: IS_PROJECT_DATE_SHORT_FORMAT ? end_date.slice(3) : end_date,
      });
    }
    setClaimGroup({ ...claimGroup, claim_project_reports: claim_projects_copy });
  };


  const removeProject = async (index) => {
    setLoading(true);
    try {
      if(window.confirm('Are you sure you want to remove this project')){
        const claim_projects_copy = claimGroup.claim_project_reports;
        if (claim_projects_copy[index].id){
          await claimProjectReportService.delete(claimGroup.id, claim_projects_copy[index].id);
          const updatedClaimGroup = await claimGroupService.get(claimGroupId)
          setClaimGroup(updatedClaimGroup)
        }
        else {
          claim_projects_copy.splice(index, 1);
          setClaimGroup({ ...claimGroup, claim_project_reports: claim_projects_copy });
        }
      }
    } catch(error) {
      dispatch(handleError(error));
    } finally {
      setLoading(false)
    }
  };

  const handleProjectReportChange = (event, index) => {
    const claim_project_report_copy = claimGroup.claim_project_reports;
    const value = event.target.value;
    claim_project_report_copy[index][event.target.name] = value;
    if([event.target.name][0] === 'start_date' && value !== undefined && process.env.PUBLIC_URL === '/de') {
      const year = event.target.value.split('/')[2];
      claim_project_report_copy[index]['end_date'] = `31/12/${year}`
    }
    setClaimGroup({
      ...claimGroup,
      claim_project_reports: claim_project_report_copy,
    });
  };

  const handleClamiGroupChange = (e) => {
    setClaimGroup({
      ...claimGroup,
      [e.target.name]: e.target.checked
    })
  }

  if(!claimGroup) return <Loading/>;

  return (
    <>
      <ClaimGroupSidebar claimGroup={claimGroup} highlightedMenuItem='edit/general' />
      <ClaimGroupBreadcrumbLinks claimGroup={claimGroup}/>
      <Card>
        <Card.Header>
          <ClaimGroupEditNavigation accountType={accountType} page={'claim_projects'} claimGroup={claimGroup}/>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <ClaimProjectForm
                claim_project_reports={claimGroup.claim_project_reports}
                handleProjectReportChange={handleProjectReportChange}
                addProject={addProject}
                removeProject={removeProject}
                handleReportOrderChange={handleReportOrderChange}
                claimGroup={claimGroup}
                setClaimGroup={setClaimGroup}
                handleSubmit={handleSubmit}
                handleToaster={handleToaster}
              />
            </Col>
          </Row>
          { SHOW_CLAIM_GROUP_PROJECT_ELSTER && <Row>
              <Col md={12}>
                <Form.Check
                  checked={claimGroup.projects_ready_for_elster}
                  value={claimGroup.projects_ready_for_elster}
                  name={'projects_ready_for_elster'}
                  label={t('all_projects_ready_for_elster')}
                  onChange={handleClamiGroupChange}
                />
              </Col>
            </Row>
          }
          <Row className="modal-footer">
            <ClaimGroupEditFooter
              loading={loading}
              handleSubmit={handleSubmit}
              backUrl={`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}/master`}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupEditProjectsPage
