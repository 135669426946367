import { Loading } from '@rd-web-markets/shared/dist/util';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ClaimProjectReportsSection = ({claimGroup}) => {
  const user = useSelector((state) => state.auth.user);

  const isComplete = r => {
    return (
      (r.project_name || '').length > 0 &&
      (r.start_date || '').length > 0 &&
      (r.capabilities || '').length > 0 &&
      (r.technical_uncertainties || '').length > 0 &&
      (r.how_and_when_were_uncertainties_overcome || '').length > 0 &&
      (r.why_was_knowledge_sought_not_readily_deducible || '').length >
        0
    );
  };

  if (!claimGroup) return <Loading />;

  return (
    <>
      <Card.Body>
        <Card>
          <Card.Body>
            <p>
              Make sure they meet the criteria for
              R&D set out in the Instructions. Your projects don't have to be
              separate products. You can break down one big product into
              multiple distinct "projects". And remember: your audience is
              non-technical so explain your work simply and clearly.
            </p>
          </Card.Body>
        </Card>
        <Row className="pt-4">
          {claimGroup.claim_project_reports.map(report => {
            return (
              <Col md={3} key={report.id} className="pb-3">
                <Card>
                  <Card.Header className="text-center">
                    { isComplete(report) ? 'Completed' : 'In Progress' }
                  </Card.Header>
                  <Link to={`project_reports/${report.id}`}>
                    <Card.Body className="text-center">{report.project_name}</Card.Body>
                  </Link>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md={12} className="text-left">
            <Button as={Link} variant="light" to={`/${user.account_type}/claim_groups/${claimGroup.id}/technical_proof/company_info`}>
              <span className="material-icons md-18">chevron_left</span> Back
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </>
  );
}

export default ClaimProjectReportsSection
