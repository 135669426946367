function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/**
 * We need to explicitly pass the time and date format parameters to the React Date Picker
 * @returns 
 */
export function getLocaleDateFormat() {
  var LOCALE = window.AYMING_MARKET_LOCALE;
  switch (LOCALE) {
    case 'en-GB':
      return 'dd/MM/yyyy';
    case 'en-US':
      return 'MM/dd/yyyy';
    case 'de-DE':
      return 'dd.MM.yyyy';
    case 'it-IT':
      return 'dd.MM.yyyy';
    default:
      return 'dd/MM/yyyy';
  }
}

/**
 * We need to explicitly pass the time and date format parameters to the React Date Picker
 * @returns 
 */
export function getLocaleDateTimeFormat() {
  var LOCALE = window.AYMING_MARKET_LOCALE;
  switch (LOCALE) {
    case 'en-GB':
      return 'dd/MM/yyyy HH:mm';
    case 'en-US':
      return 'MM/dd/yyyy HH:mm';
    case 'de-DE':
      return 'dd.MM.yyyy HH:mm';
    case 'it-IT':
      return 'dd.MM.yyyy HH:mm';
    default:
      return 'dd/MM/yyyy HH:mm';
  }
}
export function getDateTimeFormatForTable() {
  var LOCALE = window.AYMING_MARKET_LOCALE;
  switch (LOCALE) {
    case 'en-US':
      return 'HH:mm MM.DD.yyyy';
    default:
      return 'HH:mm DD.MM.yyyy';
  }
}
var DEFAULT_FORMATTING_OPTIONS = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
};

// Displaying formats based on locales for different environment
function localesForCustomLocaleDateString() {
  var LOCALE = window.AYMING_MARKET_LOCALE;
  if (LOCALE === 'en-CA') {
    return {
      locale: 'en-US',
      formattingOptions: {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      }
    };
  } else if (LOCALE === 'it-IT') {
    return {
      locale: 'de-DE',
      formattingOptions: DEFAULT_FORMATTING_OPTIONS
    };
  } else {
    return {
      locale: LOCALE,
      formattingOptions: DEFAULT_FORMATTING_OPTIONS
    };
  }
}
export function extractMonthYear(dateString) {
  if (dateString) {
    return dateString.split('/').slice(1).join('/');
  }
}
export function dateToString(date) {
  return "".concat(date.getDate(), "/").concat(date.getMonth() + 1, "/").concat(date.getFullYear());
}
export function dateToLocaleString(date) {
  var dateObject = new Date(date); // in case its a string, works fine it its a date
  return "".concat(dateObject.toLocaleString());
}

/**
 * We mostly follow the 2023-01-01 format - YYYY-MM-DD.
 * Converts 31/01/2023 to 2023--01-31 and then creates a date in the current
 * There is a possibility for the passed date to contain time data as well. Therefore we first need to strip out
 * only the date part.
 * @param {*} dateStringWithSlashes 
 * @returns 
 */
export function DDMMYYYStringToDate(dateStringWithSlashes) {
  var DATE_PART_REGEX = /(\d*\/\d*)+/;
  var datePart = dateStringWithSlashes.match(DATE_PART_REGEX)[0];
  var dateStringCorrectFormat = datePart.split('/').reverse().join('-');
  return createDateInCurrentTimezone(dateStringCorrectFormat);
}

/**
 * Javascript creates dates in different timezones depending on the datestring passed to the new Date constructor.
 * This is because depending the string itself may belong to a different time format.
 * 
 * E.g. a London timezoned date for the 1st of August, when loaded on a laptop in the USA, will convert that date to a USA timezone,
 * subtracting the timedifference in hours and reducing the days by 1, since the 1st of August may not yet have happened in the USA, depending
 * on the time of the date - e.g. 1st of August 22:00 London time vs 1st of August 00:00 London time.
 * 
 * In order to avoid such issues with a date string format, we convert the 2 string formats we currently use to YYYY-MM-DD, and take the 
 * year, month and day component separately, and then create a date in the local timezone at 00:00:00.000 without using the string format.
 * @param {Object | String} dateStringOrObject
 * @returns 
 */
export function createTimezonedDate(dateStringOrObject, defaultToToday) {
  if (!dateStringOrObject) {
    if (!defaultToToday) {
      return null;
    }
    dateStringOrObject = new Date();
  }
  var date;
  if (dateStringOrObject && _typeof(dateStringOrObject) === 'object') {
    date = dateStringOrObject;
  } else if (dateStringOrObject) {
    if (dateStringOrObject.includes('/')) {
      date = DDMMYYYStringToDate(dateStringOrObject);
    } else {
      date = createDateInCurrentTimezone(dateStringOrObject);
    }
  }
  date.toCustomLocaleDateString = function (isDEDocx) {
    var locales_format = localesForCustomLocaleDateString();
    if (process.env.PUBLIC_URL === '/ca') {
      return date.toLocaleDateString(locales_format.locale, locales_format.formattingOptions).replace(/,/g, '').replace(/\s+/g, '.');
    } else if (isDEDocx) {
      return date.toLocaleDateString('ko-KR', locales_format.formattingOptions).replace(/[.\s]/g, ''); // yyyymmdd format for DE docx
    } else {
      return date.toLocaleDateString(locales_format.locale, locales_format.formattingOptions);
    }
  };
  date.toCustomLocaleTimeString = function () {
    var locales_format = localesForCustomLocaleDateString();
    return date.toLocaleTimeString(locales_format.locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  return date instanceof Date && !isNaN(date) ? date : console.log('Invalid date');
}
export function createEndOfCurrentYear() {
  var currentYear = new Date().getFullYear();
  var endOfCurrentYear = "".concat(currentYear, "-12-31");
  return endOfCurrentYear;
}
/**
 * Accepts date in the format of YYYY-MM-DD and creates a new date in the current timezone
 */
function createDateInCurrentTimezone(dateString) {
  var dateStringParts = dateString.split('-');
  var year = dateStringParts[0];
  var month = Number(dateStringParts[1]) - 1;
  var day = parseInt(dateStringParts[2]);
  if (month < 0 || month > 11) {
    throw new Error("Invalid date ".concat(dateString, ". Month - 1 must be between 0 and 11. Possibly passed a date in a wrong format."));
  }
  return new Date(year, month, day, 0, 0, 0, 0);
}
export var getYear = function getYear(dateInString) {
  return new Date(dateInString).getFullYear();
}; // gets 'DD/MM/YYYY and retruns years as a number

export var currentTime = function currentTime() {
  var today = new Date();
  var hour = String(today.getHours()).padStart(2, '0');
  var minute = String(today.getMinutes()).padStart(2, '0');
  var second = String(today.getSeconds()).padStart(2, '0');
  return "".concat(hour, ":").concat(minute, ":").concat(second);
};
export var datesInChronologicalOrder = function datesInChronologicalOrder(firstDate, secondDate) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var strictComparison = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var startDate, endDate;
  if (!format) {
    startDate = new Date(firstDate);
    endDate = new Date(secondDate);
  } else if (format.toLowerCase() === 'mm/yyyy') {
    var _firstDate$split = firstDate.split('/'),
      _firstDate$split2 = _slicedToArray(_firstDate$split, 2),
      firstMonth = _firstDate$split2[0],
      firstYear = _firstDate$split2[1];
    var _secondDate$split = secondDate.split('/'),
      _secondDate$split2 = _slicedToArray(_secondDate$split, 2),
      secondMonth = _secondDate$split2[0],
      secondYear = _secondDate$split2[1];
    if (!firstYear || !secondYear) return true; // one of the dates is not specified, return true (means that another date is valid)
    startDate = new Date(firstYear, firstMonth - 1);
    endDate = new Date(secondYear, secondMonth - 1);
  } else if (format.toLowerCase() === 'dd/mm/yyyy') {
    var _firstDate$split3 = firstDate.split('/'),
      _firstDate$split4 = _slicedToArray(_firstDate$split3, 3),
      firstDay = _firstDate$split4[0],
      _firstMonth = _firstDate$split4[1],
      _firstYear = _firstDate$split4[2];
    var _secondDate$split3 = secondDate.split('/'),
      _secondDate$split4 = _slicedToArray(_secondDate$split3, 3),
      secondDay = _secondDate$split4[0],
      _secondMonth = _secondDate$split4[1],
      _secondYear = _secondDate$split4[2];
    if (!_firstYear || !_secondYear) return true; // one of the dates is not specified, return true (means that another date is valid)
    startDate = new Date(_firstYear, _firstMonth - 1, firstDay);
    endDate = new Date(_secondYear, _secondMonth - 1, secondDay);
  } else if (format.toLowerCase() === 'mm/dd/yyyy') {
    startDate = new Date(firstDate);
    endDate = new Date(secondDate);
  }
  return strictComparison ? startDate < endDate : startDate <= endDate;
};