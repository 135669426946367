export function disabledBecauseOfClaimState(user, object) {
  var _object$claim;
  // if there is an active change set
  var activeChangeSet = (object === null || object === void 0 ? void 0 : object.active_change_set) || (object === null || object === void 0 || (_object$claim = object.claim) === null || _object$claim === void 0 ? void 0 : _object$claim.active_change_set);
  if (activeChangeSet) {
    var _object$company, _object$claim2;
    // if it's finalized - disable element!
    if (activeChangeSet.finalized_at) {
      return true;
    } else if (
    /// ,.. or if the user is not the assigned company admin and not an admin account type - disable element!
    user.id !== (object === null || object === void 0 || (_object$company = object.company) === null || _object$company === void 0 || (_object$company = _object$company.admin) === null || _object$company === void 0 ? void 0 : _object$company.id) && user.id !== (object === null || object === void 0 || (_object$claim2 = object.claim) === null || _object$claim2 === void 0 || (_object$claim2 = _object$claim2.company) === null || _object$claim2 === void 0 || (_object$claim2 = _object$claim2.admin) === null || _object$claim2 === void 0 ? void 0 : _object$claim2.id) && user.account_type !== 'admin') {
      return true;
    }
  }
}
;