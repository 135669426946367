import SearchBar from '@components/util/SearchBar';
import React, { useState, useEffect, useCallback } from 'react'
import { Button, Card, Row, Col, Pagination, Form, Breadcrumb } from 'react-bootstrap'
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import * as QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { useSelector } from 'react-redux';
import { claimProjectReportsService } from '@services/task_management/claimProjectReports.service';
import { DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import Filters from '@rd-web-markets/shared/dist/util/Filters';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { trackingTasksService } from 'src/services/task_management/claim_project_reports/tracking_tasks.service';
import MasterSidebar from '@components/util/MasterSidebar';
import { tasksService } from '@services/task_management/tasks.service';

const initialParams = 'page=1&query=';

const TimeTrackingTasksPage = ({ accountType }) => {
  const location = useLocation();
  const history = useHistory();
  const { claim_project_report_id, claim_group_id } = useParams();
  const [claimProjectReport, setClaimProjectReport] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTasks = async () => {
      const claimProjectReport = await claimProjectReportsService.get(claim_group_id, claim_project_report_id);
      setClaimProjectReport(claimProjectReport);
      const tasks = await tasksService.all(claimProjectReport.id, QueryString.stringify(params));
      setTasks(tasks);
      history.push({
        search: QueryString.stringify(params),
      });
    };

    fetchTasks();
  }, [history, params])

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const deleteTask = useErrorHandling(useCallback(async (task) => {
    await trackingTasksService.delete(claimProjectReport.id, task.id);
    const tasks = await tasksService.all(QueryString.stringify(params));
    setTasks(tasks);
  }, [claimProjectReport]));

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const headers = [
    { text: t('assignee') },
    { text: t('type') },
    { text: t('title') },
    { text: t('status') },
    { text: t('effort') },
    { text: t('worked') },
    '',
  ];

  if (!tasks) return <Loading />

  const rows = tasks.map(task => {
    return {
      key: task.id,
      columns: [
        task.assignee?.name,
        t(task.classification),
        task.title,
        t(task.completion_status),
        task.effort,
        task.worked,
        <>
          <EditButton
            as={Link}
            to={`/${user.account_type}/time_tracking/claim_groups/${claimProjectReport.claim_group.id}/claim_project_reports/${claimProjectReport.id}/edit_task/${task.id}`}
          />
          <DeleteButton onClick={() => deleteTask(task)} />
        </>,
      ]
    }
  });

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='list_of_companies' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/list_of_companies`}}>{t('companies')}</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/time_tracking/companies/${claimProjectReport.claim_group.company_id}/claim_groups`}}>{claimProjectReport.claim_group.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{claimProjectReport.claim_group.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal> 
      <Card>
        <Row>
          <Col md={12} className='text-center p-4'>
            <Button className='text-center' variant="light" size="md" as={Link} to={`/${user.account_type}/time_tracking/claim_groups/${claimProjectReport.claim_group.id}/claim_project_reports/${claimProjectReport.id}/create_task`}>
              <span className="material-icons md-18">add</span>{t('task')}
            </Button>
          </Col>
        </Row>
      </Card>

      <div className='text-right mb-2'>
        <Filters
          params={params}
          setParams={setParams}
          filters={[
            { name: 'title', label: t('title'), type: 'text' },
            { name: 'type', label: t('type'), type: 'select', options: ['task', 'file_request']},
            { name: 'status', label: t('status'), type: 'select', options: ['not_started', 'in_progress', 'done']}
          ]}
        />
      </div>

      <Card>
        <Table
          headers={headers}
          rows={rows}
        />
      </Card>

      <Pagination className='justify-content-end'>{items}</Pagination>
    </>
  )
}

export default TimeTrackingTasksPage