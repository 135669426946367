function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { isContentOverlappingSelection, isTextNode, loopChildNodes } from "@rd-web-markets/shared/dist/util/domUtils";
import DeletionHighlight from "./DeletionHighlight";
var DYNAMIC_FIELD_PATTERN = /\[ *: *\w+(?: *, *\w[\w|\-|"|'|.|;|&|\s]+\w)* *\]/g;
var DISABLED_BUTTON_SELECTORS_WHILE_EDDITING_DYNAMIC_FIELD = ['*[aria-label="Insert/edit image"]', '*[aria-label="Text color"]', '*[aria-label="Background color"]', '*[aria-label="Align left"]', '*[aria-label="Align center"]', '*[aria-label="Align right"]', '*[aria-label="Justify"]', '*[aria-label="Font sizes"]', '*[aria-label="Fonts"]', '*[aria-label="Insert/edit link"]', '*[aria-label="Italic"]', '*[aria-label="Bold"]', '*[aria-label="Formats"]', '*[aria-label="Add Footnote"]', '*[aria-label="Add Comment"]'];
var DynamicField = {
  /**
   * Checks whether the cursor selection overlaps a dynamic field. If we are deleting elements with Backspace, then a caret position like so(*): [:field]*
   * would mean that we would be editing the dynamic field. Same if we are deleting with Delete and the caret is here *[:field].
   * If we are adding content then a caret position of either *[:field]* is fine.
   * This function works also for non-collapsed document selection where the anchor and focus are in different places.
   * 
   * @param {*} tinyContentNode 
   * @param {*} cursorSelection 
   * @param {*} isAddingContent 
   * @returns 
  */
  isEditingPartOfADynamicField: function isEditingPartOfADynamicField(tinyContentNode, cursorSelection, isAddingContent) {
    // We need to loop through all text node children in so that when we do find a dynamic field - we can get its parent node.
    // When we know the parent node - we can replace text inside it.
    var dynamicFields = [];
    if (isTextNode(tinyContentNode)) {
      var match = DYNAMIC_FIELD_PATTERN.exec(tinyContentNode.textContent);
      while (match !== null) {
        dynamicFields.push({
          text: match[0],
          index: match.index,
          length: match[0].length,
          indexEnd: match.index + match[0].length - 1,
          textNode: tinyContentNode,
          parentElement: tinyContentNode.parentElement
        });
        match = DYNAMIC_FIELD_PATTERN.exec(tinyContentNode.textContent);
      }
    } else {
      loopChildNodes(tinyContentNode, function (childNode) {
        if (isTextNode(childNode)) {
          var nodeText = childNode.textContent;
          var _match = DYNAMIC_FIELD_PATTERN.exec(nodeText);
          while (_match !== null) {
            dynamicFields.push({
              text: _match[0],
              index: _match.index,
              length: _match[0].length,
              indexEnd: _match.index + _match[0].length - 1,
              textNode: childNode,
              parentElement: childNode.parentElement
            });
            _match = DYNAMIC_FIELD_PATTERN.exec(nodeText);
          }
        }
      });
    }
    var dynamicFieldBeingEditted = dynamicFields.find(function (d) {
      var edittingDynamicFieldInAnchorNode = (d.textNode.isSameNode(cursorSelection.anchorNode) || d.parentElement.isSameNode(cursorSelection.anchorNode)) && isContentOverlappingSelection(d.index, d.indexEnd, cursorSelection, isAddingContent);
      var edittingDynamicFieldInFocusNode = (d.textNode.isSameNode(cursorSelection.focusNode) || d.parentElement.isSameNode(cursorSelection.focusNode)) && isContentOverlappingSelection(d.index, d.indexEnd, cursorSelection, isAddingContent);
      return edittingDynamicFieldInAnchorNode || edittingDynamicFieldInFocusNode;
    });
    return dynamicFieldBeingEditted || false;
  },
  /**
   * Simply calls the other function, but passing true for the last argument. Makes it more readable in the code below.
   * @param {*} node 
   * @param {*} selection 
   * @returns 
   */
  isAddingContentInADynamicField: function isAddingContentInADynamicField(node, selection) {
    return this.isEditingPartOfADynamicField(node, selection, true);
  },
  /**
  * Returns true if the cursor is inside a deletion which is a deleted dynamic field
  * @param {*} tinyContentNode 
  * @param {*} cursorSelection 
  * @returns 
  */
  isSelectionAnchorInDeletedDynamicField: function isSelectionAnchorInDeletedDynamicField(tinyContentNode, cursorSelection) {
    return DeletionHighlight.isSelectionAnchorInHighlight(cursorSelection) && this.isEditingPartOfADynamicField(tinyContentNode, cursorSelection);
  },
  disableMenuButtonsIfTheSelectionFallsInsideADynamicField: function disableMenuButtonsIfTheSelectionFallsInsideADynamicField(editor) {
    var _document$evaluate$si;
    var self = this;
    var cursorSelection = editor.selection.getSel();
    var contentNode = editor.selection.getNode();
    var dynamicFieldButtonXPath = "//*[text()='Add Dynamic Value']";
    var dynamicFieldButton = (_document$evaluate$si = document.evaluate(dynamicFieldButtonXPath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue) === null || _document$evaluate$si === void 0 ? void 0 : _document$evaluate$si.parentElement;
    var buttons = [].concat(_toConsumableArray(DISABLED_BUTTON_SELECTORS_WHILE_EDDITING_DYNAMIC_FIELD.map(function (selector) {
      return document.querySelector(selector);
    })), [dynamicFieldButton]).filter(function (b) {
      return !!b;
    });
    buttons.forEach(function (button) {
      if (self.isEditingPartOfADynamicField(contentNode, cursorSelection) || self.isAddingContentInADynamicField(contentNode, cursorSelection)) {
        if (!button.classList.contains('disabled-btn')) {
          button.setAttribute('disabled', true);
          button.classList.add('disabled-btn');
        }
      } else if (button.classList.contains('disabled-btn')) {
        button.removeAttribute('disabled');
        button.classList.remove('disabled-btn');
      }
    });
  }
};
export default DynamicField;