import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const myClaimsService = {
  all(params) {
    return fetch(`/api/my/claims?${params}`, request.get);
  },
}

export default myClaimsService;
