import { setCursorPositionToEndOfElement } from "../cursorUtils";
import { createHighlightType } from "./HighlightType";
var DeletionHighlightSettings = {
  className: 'TrackChanges-Highlight-Deletion',
  highlightType: 'deletion'
};
var DeletionHighlight = createHighlightType(DeletionHighlightSettings);
DeletionHighlight.isSelectionAnchorInHighlight = function (cursorSelection) {
  var _cursorSelection$anch;
  return ((_cursorSelection$anch = cursorSelection.anchorNode.classList) === null || _cursorSelection$anch === void 0 ? void 0 : _cursorSelection$anch.contains(DeletionHighlightSettings.className)) || cursorSelection.anchorNode.parentElement.classList.contains(DeletionHighlightSettings.className);
};
DeletionHighlight.surroundDynamicField = function (editor, dynamicFieldBeingEditted) {
  var oldTextValue = dynamicFieldBeingEditted.textNode.nodeValue;
  var newTextValueStartTextNode = document.createTextNode(oldTextValue.substring(0, dynamicFieldBeingEditted.index));
  var newDynamicFieldDeletionSpan = DeletionHighlight.createHighlightedElement(dynamicFieldBeingEditted.text);
  dynamicFieldBeingEditted.textNode.deleteData(0, dynamicFieldBeingEditted.indexEnd + 1);
  dynamicFieldBeingEditted.parentElement.insertBefore(newDynamicFieldDeletionSpan, dynamicFieldBeingEditted.textNode);
  dynamicFieldBeingEditted.parentElement.insertBefore(newTextValueStartTextNode, newDynamicFieldDeletionSpan);
  setCursorPositionToEndOfElement(editor, newDynamicFieldDeletionSpan);
  return [newTextValueStartTextNode, dynamicFieldBeingEditted.textNode];
};
export default DeletionHighlight;