export var suppliesTableHeaders = function suppliesTableHeaders(showTypeAndYear) {
  return [{
    text: 'Vendor Name',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.object.vendor_name || '';
    }
  }, {
    text: 'Supply Type',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.object.supply_type;
    }
  }, {
    text: 'State',
    sortable: true,
    filterableByText: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.object.state;
    }
  }, showTypeAndYear ? {
    text: 'Type',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return r.data.object.claim.id_in_provider_system ? 'Claim' : 'Base';
    }
  } : null, showTypeAndYear ? {
    text: 'Year',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.object.claim.label);
    }
  } : null, {
    text: 'Expences',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.object.expense);
    }
  }, {
    text: 'R&D %',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.object.rnd_apportionment);
    }
  }, {
    text: 'QREs',
    sortable: true,
    valueFnForSortAndFilter: function valueFnForSortAndFilter(r) {
      return Number(r.data.object.qre);
    }
  }, {
    text: '',
    sortable: false
  }].filter(function (h) {
    return !(h === null);
  });
};