import { createEditorSelectionWithAllRelatedTrackChangesElements, findNextPreviousNonAdjacentChanges } from "./findNextPreviousChange";

/**
 * 
 * @param {*} editor 
 * @param {*} moduleInstance 
 * @param {String} direction "next" | "previous"
 */
export function triggerCommentsReload(editor, moduleInstance, direction) {
  var _moduleInstance$curre;
  var currentPopoverChangeElements = ((_moduleInstance$curre = moduleInstance.currentPopover) === null || _moduleInstance$curre === void 0 ? void 0 : _moduleInstance$curre.changeElements) || [];
  var nonAdjacentChanges = findNextPreviousNonAdjacentChanges(editor, currentPopoverChangeElements);
  var nextPreviousChangeElementToUse = nonAdjacentChanges["".concat(direction, "PopoverChangeElement")];
  var changeElementToUse = nextPreviousChangeElementToUse || editor.getBody().querySelector('TrackChanges-Highlight');
  if (changeElementToUse) {
    moduleInstance.createPopover(editor, changeElementToUse);
    var editorSelectionForComments = createEditorSelectionWithAllRelatedTrackChangesElements(editor, changeElementToUse);
    editor.fire('Comments:triggerChangeSelectedCommentSpanId', {
      payload: {
        cursorSelection: editorSelectionForComments
      }
    });
  }
}