function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
export { userIsCuttingContent, userIsPastingContent, userIsCuttingOrPasting, userIsPressingCtrlOrMetaOrShiftOnly } from "./domUtils/userActions";
export var ZERO_WIDTH_NO_BREAK_SPACE = "\uFEFF";
export var CHARACTER_WORD_JOINER = '⁠';
export var CHARACTER_NO_BREAK_SPACE = ' ';
export var SIBLING_DIRECTION_PREV = 'previous';
export var SIBLING_DIRECTION_NEXT = 'next';
function parents(node) {
  var nodes = [node];
  for (; node; node = node.parentNode) {
    nodes.unshift(node);
  }
  return nodes;
}
function getOrderedNodesAndOffsets(node1, offset1, node2, offset2) {
  var _ref = isNodeBeforeAnotherInDom(node1, node2) ? [node2, offset2, node1, offset1] : [node1, offset1, node2, offset2],
    _ref2 = _slicedToArray(_ref, 4),
    firstNode = _ref2[0],
    firstNodePos = _ref2[1],
    secondNode = _ref2[2],
    secondNodePos = _ref2[3];
  return {
    firstNode: firstNode,
    firstNodePos: firstNodePos,
    secondNode: secondNode,
    secondNodePos: secondNodePos
  };
}
function nodeOrChildTextNodeIfNodeIsANonImageElement(node, offset) {
  // Also in case the first child node is not a text node, we are probably in a case like <div><img /></div>,
  // and we are selecting the whole image. In TinyMCE when we select an image like that, the anchor and focus nodes
  // are the div node, and the anchorOffset is 0, and the focusOffset is 1.
  if (isTextNode(node) || isImageNode(node) || !isTextNode(node === null || node === void 0 ? void 0 : node.childNodes[0])) {
    return node;
  }

  // in cases like <div><img /></div> - we wont have any text nodes, so textNode will be null
  var textNode = getTextNodeByCharIndex(node, offset);
  return textNode || node;
}

/**
 * When using a Range object coming from a Selection object, that was not created by our code, but by the browser,
 * the range will always have the startContainer be first in the dom tree, and the endContainer be second (after it).
 * Regardless of the initial direction of the selection. However if we manually set the end container of a range to a node
 * that is above the startContainer in the dom tree, the range will collapse and whaterver is the end container will also become
 * the start container. That is why to make the created range the same as what the browser would create, we need to check which node
 * comes first in the dom tree, and then set the correct start and end nodes.
 * 
 * In addition if we set start or end to be a dom element, the unit tests will throw an "IndexSizeError: Offset out of bound".
 * So the start and end need to be set to text nodes.
 * @param {*} doc 
 * @param {*} startNode for ease of use position in the dom tree does not matter
 * @param {*} startPos for ease of use position in the dom tree does not matter
 * @param {*} endNode 
 * @param {*} endPos 
 * @returns 
 */
function createRange(doc, startNode, startPos, endNode, endPos) {
  var range = doc.createRange();
  var _getOrderedNodesAndOf = getOrderedNodesAndOffsets(startNode, startPos, endNode, endPos),
    firstNode = _getOrderedNodesAndOf.firstNode,
    firstNodePos = _getOrderedNodesAndOf.firstNodePos,
    secondNode = _getOrderedNodesAndOf.secondNode,
    secondNodePos = _getOrderedNodesAndOf.secondNodePos;
  range.setStart(nodeOrChildTextNodeIfNodeIsANonImageElement(firstNode, firstNodePos), firstNodePos);
  range.setEnd(nodeOrChildTextNodeIfNodeIsANonImageElement(secondNode, secondNodePos), secondNodePos);
  return range;
}
export function createSelection(doc, startNode, startPos, endNode, endPos) {
  var range = createRange(doc, startNode, startPos, endNode, endPos);
  var selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  return selection;
}
export var CHARACTER_PUNCTUATION_SPACE = "\u2008";
export function isFirstNodeChildOfSecond(firstNode, secondNode) {
  return secondNode.contains(firstNode) && !secondNode.isSameNode(firstNode);
}
export function getTextNodesInRange(range) {
  var textNodes = [];
  var treeWalker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_TEXT, null, false);
  var currentNode = treeWalker.currentNode;
  while (currentNode) {
    if (range.intersectsNode(currentNode)) {
      textNodes.push(currentNode);
    }
    currentNode = treeWalker.nextNode();
  }
  return textNodes;
}
function createRangeFromArray(doc, array) {
  // makes sure the start node is the first one in the DOM
  var startNode = !isNodeBeforeAnotherInDom(array[0], array[array.length - 1]) ? array[0] : array[array.length - 1];
  var endNode = array[0].isSameNode(startNode) ? array[array.length - 1] : array[0];
  if (!isTextNode(startNode)) {
    var textNode = findFirstNonEmptyTextNode(startNode);
    startNode = textNode || startNode;
  }
  if (!isTextNode(endNode)) {
    var _textNode = findLastNonEmptyTextNode(endNode);
    endNode = _textNode || endNode;
  }
  return createRange(doc, startNode, 0, endNode, endNode.textContent.length);
}
export function createSelectionDataFromArray(doc, array) {
  var range = createRangeFromArray(doc, array);
  var selectionData = {
    anchorNode: range.startContainer,
    anchorOffset: range.startOffset,
    focusNode: range.endContainer,
    focusOffset: range.endOffset,
    getRangeAt: function getRangeAt(index) {
      return index === 0 ? range : null;
    }
  };
  return selectionData;
}
export function matchNodeOrAnyParentElement(node, matchFn) {
  var nodeAndParents = parents(node);
  var currentNode = nodeAndParents.pop();
  while (currentNode) {
    if (matchFn(currentNode)) {
      return currentNode;
    }
    currentNode = nodeAndParents.pop();
  }
  return null;
}
export function selectionContainsElementsWithClassname(selection, className) {
  if (selection.rangeCount === 0) {
    return false;
  }
  var range = selection.getRangeAt(0);
  var elements = range.cloneContents().querySelectorAll("[class*=\"".concat(className, "\"]"));
  return elements.length > 0;
}

/**
 * 
 * @param {*} nodeOrElement 
 * @param {*} direction 'right' | 'previous'
 * @returns 
 */
export function findNextOrPreviousTextNode(nodeOrElement, direction) {
  var nextOrPreviousNode = findAnyNodeOrElementSibling(nodeOrElement, direction);
  if (isTextNode(nextOrPreviousNode)) {
    return nextOrPreviousNode;
  }

  // if the node itself is not a text node, look inside it to find a text node
  return direction === 'next' ? findFirstNonEmptyTextNode(nextOrPreviousNode) : findLastNonEmptyTextNode(nextOrPreviousNode);
}

/**
 * Sometmies a node does not have a previous sibling, but its grandgrandparent does.
 * Tries to loop through the next/previous sibling. If such does not exist - tries the next/previous Element Sibling.
 * If those do not exist as well - goes to the parent node.
 * @param {*} node 
 */
export function findAnyNodeOrElementSibling(node, order) {
  var siblingAttribute = order === 'previous' ? 'previousSibling' : 'nextSibling';
  var elementSiblingAttribute = order === 'previous' ? 'previousElementSibling' : 'nextElementSibling';
  var nodeAndParents = parents(node);
  var currentNode = nodeAndParents.pop();

  // if the node is empty then we cant text edit inside of it
  // there are some cases where the browser just adds empty nodes
  var firstNonEmptySibling = null;
  while (!firstNonEmptySibling) {
    if (!currentNode || currentNode.nodeName === 'BODY') {
      return null;
    }

    // find the first possible non empty sibling
    // if the sibling is empty - then we get its sibling, until there are no more siblings, or one is not empty
    var sibling = currentNode[siblingAttribute] || currentNode[elementSiblingAttribute];
    while (!!sibling && sibling.textContent === '' && sibling.nodeName !== 'IMG' && !findAllChildNodes(sibling, function () {
      return true;
    }).some(function (c) {
      return c.nodeName === 'IMG';
    })) {
      sibling = sibling[siblingAttribute] || sibling[elementSiblingAttribute];
    }

    // if we don't have a sibling then take the parent
    if (!sibling) {
      currentNode = nodeAndParents.pop();
    } else {
      firstNonEmptySibling = sibling;
    }
  }
  return firstNonEmptySibling;
}

/**
 * Returns a div. The innerHTML of that div is the htmlString.
 * That is because sometimes the string can be pure text without a single DOM element.
 * @param {*} htmlString 
 * @returns 
 */
export function stringToDom(htmlString) {
  if (!htmlString) {
    return null;
  }
  var div = document.createElement('div');
  div.innerHTML = htmlString;
  return div;
}
export function isEmptySelection(selection) {
  return selection.anchorNode.isSameNode(selection.focusNode) && selection.anchorOffset === selection.focusOffset || selection.isCollapsed;
}
export function hasClassOrParentWithClass(node, classToCheck) {
  var currentNode = node;
  while (currentNode) {
    var _currentNode$classLis;
    if ((_currentNode$classLis = currentNode.classList) !== null && _currentNode$classLis !== void 0 && _currentNode$classLis.contains(classToCheck)) {
      return true;
    }
    currentNode = currentNode.parentNode;
  }
  return false;
}
export function findParent(node, partialParentClass, partialParentStopClass) {
  var currentNode = node.parentNode;

  // checks wether currentNode is defined and not the document node
  while ((_currentNode = currentNode) !== null && _currentNode !== void 0 && _currentNode.classList) {
    var _currentNode;
    var classListArray = Array.from(currentNode.classList);
    var matchingClassName = classListArray.find(function (x) {
      return x.includes(partialParentClass);
    });
    var containsStopClass = classListArray.some(function (c) {
      return c.includes(partialParentStopClass);
    });
    if (matchingClassName) {
      return currentNode;
    } else if (containsStopClass) {
      return false;
    }
    currentNode = currentNode.parentNode;
  }
  return false;
}
export function removeElements(node, selector) {
  var matchingElements = Array.from(node.querySelectorAll(selector));
  matchingElements.forEach(function (e) {
    return e.remove();
  });
}
export function findCommonDomAncestor(node1, node2) {
  if (!node1 || !node2) {
    throw new Error('node1 or node2 is not defined!');
  }
  var parents1 = parents(node1);
  var parents2 = parents(node2);

  /**
   * The 0 parent is the document, and the last one is the node itself.
   */
  if (parents1[0] !== parents2[0]) {
    throw new Error('No common ancestor!');
  }
  for (var i = 0; i < parents1.length; i++) {
    if (parents1[i] !== parents2[i]) return parents1[i - 1];
  }

  // if we come here then node1 and node2 are the same
  return node1.parentNode;
}
export function isTextNode(node) {
  return (node === null || node === void 0 ? void 0 : node.nodeType) === Node.TEXT_NODE;
}
export function isImageNode(node) {
  return node.nodeName.toUpperCase() === 'IMG';
}
export function isAnyElementNode(node) {
  return node.nodeType === Node.ELEMENT_NODE;
}

/**
 * Pefrorms a DFS on the childNodes
 * The last possible childNode - the deepest rightmost one is first and everyone else
 * is added in backwards order.
 * @param {*} node 
 * @param {*} onChildNode 
 */
export function loopChildNodes(node, onChildNode) {
  for (var i = 0; i < node.childNodes.length; i++) {
    var child = node.childNodes[i];
    loopChildNodes(child, onChildNode);
    onChildNode(child);
  }
}
export function findAllChildNodes(node, conditionFn) {
  var textNodes = [];
  loopChildNodes(node, function (childNode) {
    if (conditionFn(childNode)) {
      textNodes.push(childNode);
    }
  });
  return textNodes;
}

/**
 * Finds either the deepeest child node on the left side, or the deepest rightmost child node.
 * By default looks for child nodes that have some non-empty text content.
 * 
 * Examples:
 * <div><p><span>deepest leftmost text node</span></p><span>center</span><p><span>deepest rightmost text node</span></p></div>
 * <div>deepest leftmost<span>center</span>deepest rightmost</div>
 * <div><span class="leftmost"></span>center text node<span class="rightmost"></span></div>
 * 
 * @param {*} node 
 * @param {*} direction 
 * @param {*} conditionFn 
 * @returns 
 */
function findDeepestChildNodeInDirection(node, direction) {
  var conditionFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (c) {
    return !!c.textContent;
  };
  var childNodes = findAllChildNodes(node, function () {
    return true;
  });
  var childNodesArray = _toConsumableArray(childNodes);
  // it can have childNodes, but it cannot have children
  // Since findAllChildNodes returns the nodes in backwards oreder (DFS), then based on the direction we
  // need either the first matching node or the last.
  // right: last matching node
  // left: first matching node 
  if (direction === 'right') {
    childNodesArray.reverse();
  }
  return childNodesArray.find(function (c) {
    var _c$children;
    return !((_c$children = c.children) !== null && _c$children !== void 0 && _c$children.length) && conditionFn(c);
  });
}
export function findLDeepestRightmostChildTextNodeElement(node) {
  var conditionFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (c) {
    return !!c.textContent;
  };
  if (isTextNode(node)) {
    return node;
  }
  var childElements = findAllChildNodes(node, function (n) {
    return isTextNode(n);
  });
  return childElements.reverse().find(function (c) {
    var _c$childNodes;
    return !((_c$childNodes = c.childNodes) !== null && _c$childNodes !== void 0 && _c$childNodes.length) && conditionFn(c);
  });
}
export function findAllChildTextNodes(node) {
  var textNodes = [];
  loopChildNodes(node, function (childNode) {
    if (isTextNode(childNode)) {
      textNodes.push(childNode);
    }
  });
  return textNodes;
}
export function findLastNonEmptyTextNode(node) {
  var textNodes = findAllChildTextNodes(node);
  var currentNode = textNodes.pop();
  while (currentNode && !currentNode.textContent) {
    currentNode = textNodes.pop();
  }
  return currentNode;
}
export function findFirstNonEmptyTextNode(node) {
  var textNodes = findAllChildTextNodes(node);
  var currentNode = textNodes.shift();
  while (currentNode && !currentNode.textContent) {
    currentNode = textNodes.shift();
  }
  return currentNode;
}
export function getObjectBoundingClientRect(object) {
  if (isTextNode(object)) {
    var range = document.createRange();
    range.selectNodeContents(object);
    var rects = range.getClientRects();
    return rects[0];
  } else if (object.anchorNode) {
    return object.getRangeAt(0).getBoundingClientRect();
  } else {
    return object.getBoundingClientRect();
  }
}
export function getElementOrParentElementIfTextNode(domNode) {
  if (domNode.nodeType === Node.TEXT_NODE) {
    return domNode.parentElement;
  }
  return domNode;
}
function isInsideSelection(index, selection) {
  return selection.anchorOffset < index && index <= selection.focusOffset || selection.focusOffset < index && index <= selection.anchorOffset;
}

/**
 * When pressing BACKSPACE the selection offset indexes are on the right of the character that will be deleted.
 * If the content ends at index 30, but the offset is at index 31 - then pressing backspace will delete the char at index 30.
 * @param {Integer} contentIndexStart 
 * @param {Integer} contentIndexEnd 
 * @param {Integer} selection 
 * @returns
 */
function isAnySelectionIndexInsideContent(contentIndexStart, contentIndexEnd, selection, isAddingContent) {
  var anchorOffset = selection.anchorOffset,
    focusOffset = selection.focusOffset;
  var anchorEnd = isAddingContent ? anchorOffset : anchorOffset - 1;
  var focusEnd = isAddingContent ? focusOffset : focusOffset - 1;
  var anchorOffsetIsInsideContent = contentIndexStart < anchorOffset && anchorEnd <= contentIndexEnd || contentIndexEnd < anchorOffset && anchorEnd <= contentIndexStart;
  var focusOffsetIsInsideContent = contentIndexStart < focusOffset && focusEnd <= contentIndexEnd || contentIndexEnd < focusOffset && focusEnd <= contentIndexStart;
  return anchorOffsetIsInsideContent || focusOffsetIsInsideContent;
}
export function isContentOverlappingSelection(contentIndexStart, contentIndexEnd, selection, isAddingContent) {
  // if the selection is inside the content
  var selectionIndexFallsInsideContent = isAnySelectionIndexInsideContent(contentIndexStart, contentIndexEnd, selection, isAddingContent);
  // or if any part of the content is inside the selection
  var selectionSurroundsContent = isInsideSelection(contentIndexStart, selection) || isInsideSelection(contentIndexEnd, selection);
  return selectionIndexFallsInsideContent || selectionSurroundsContent;
}

/**
 * Checks whether node1 precedes node2 in the DOM tree.
 * 
 * True if the second node precedes the first one in the DOM tree.
 * False if the first node is before the second in the DO Mtree..
 * @param {*} node1 
 * @param {*} node2 
 * @returns 
 */
export function isNodeBeforeAnotherInDom(node1, node2) {
  return node1.compareDocumentPosition(node2) === Node.DOCUMENT_POSITION_PRECEDING;
}

/**
 * Surrounds all text nodes that fall between the startNode:startPos and endNode:endPos,
 * including partially surrounding the startNode and endNode if they are only partially
 * in that range. This function surrounds the text with the given characters.
 * E.g. if the text is <div>Hel{startPos}lo</div><div> wor{endPos}ld</div>" and the startNode is "Hello" and the endNode is "world" then
 * the text will be surrounded with the given characters (e.g. > <) to become <div>Hel>lo</div><div> wor<ld</div>.">Hello world<".
 * The function does not return anything, but instead it modifies the commonAncestor's innerHTML.
 * @param {*} commonAncestor 
 * @param {*} startNode 
 * @param {*} startPos 
 * @param {*} endNode 
 * @param {*} endPos 
 * @param {*} surroundStartCharacter 
 * @param {*} surroundEndCharacter 
 */
export function surroundTextNodesWithCharacters(commonAncestor, startNode, startPos, endNode, endPos, surroundStartCharacter, surroundEndCharacter) {
  if (startNode.nodeType !== Node.TEXT_NODE) {
    startNode = getTextNodeByCharIndex(startNode, startPos);
  }
  if (endNode.nodeType !== Node.TEXT_NODE) {
    endNode = getTextNodeByCharIndex(endNode, endPos);
  }

  // Ensure that startNode is before or equal to endNode in the DOM tree
  if (isNodeBeforeAnotherInDom(startNode, endNode)) {
    var _ref3 = [endNode, endPos, startNode, startPos];
    startNode = _ref3[0];
    startPos = _ref3[1];
    endNode = _ref3[2];
    endPos = _ref3[3];
  }

  // Create a range that spans the text between the two positions
  var range = createRange(document, startNode, startPos, endNode, endPos);
  var allTextNodes = findAllChildTextNodes(commonAncestor);

  // const tempSelection = createSelection(range)
  // includes partially contained nodes - e.g. startNode and endNode
  var textNodesInRange = allTextNodes.filter(function (node) {
    return node.isSameNode(startNode) ||
    // partially in the range
    range.intersectsNode(node) ||
    // fully inside the range
    node.isSameNode(endNode);
  } // partially in the range
  );

  textNodesInRange.forEach(function (node) {
    if (node.isSameNode(startNode)) {
      var contentBeforeSurround = node.nodeValue.substring(0, startPos);
      var surroundedContent = "".concat(surroundStartCharacter).concat(node.nodeValue.substring(startPos)).concat(surroundEndCharacter);
      node.nodeValue = "".concat(contentBeforeSurround).concat(surroundedContent);
    } else if (node.isSameNode(endNode)) {
      var contentAfterSurround = node.nodeValue.substring(endPos);
      var _surroundedContent = "".concat(surroundStartCharacter).concat(node.nodeValue.substring(0, endPos)).concat(surroundEndCharacter);
      node.nodeValue = "".concat(_surroundedContent).concat(contentAfterSurround);
    } else {
      node.nodeValue = "".concat(surroundStartCharacter).concat(node.nodeValue).concat(surroundEndCharacter);
    }
  });
}

/**
 * Surrounds the text content of a text node with a DOM element.
 * Returns the text node on the left of the surrounded text and the text node on the right of the surrounded text.
 * @param {*} textNode 
 * @param {*} createSurroudDomElementFn 
 * @param {*} startIndex 
 * @param {*} endIndex If undefined - surround from start to end of text node text content
 */
export function surroundTextNodeContent(doc, textNode, createSurroudDomElementFn, startIndex) {
  var endIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
  var textBeforeIndex = textNode.textContent.substring(0, startIndex);
  var beforeTextNode = doc.createTextNode(textBeforeIndex);
  var contentToSurround = textNode.textContent.substring(startIndex, endIndex);
  var surroundDomElement = createSurroudDomElementFn(contentToSurround);
  var afterTextNode = doc.createTextNode('');
  if (endIndex) {
    var textAfterIndex = textNode.textContent.substr(endIndex);
    afterTextNode = doc.createTextNode(textAfterIndex);
  }
  var parentElement = textNode.parentElement;
  parentElement.replaceChild(afterTextNode, textNode);
  parentElement.insertBefore(surroundDomElement, afterTextNode);
  parentElement.insertBefore(beforeTextNode, surroundDomElement);
  return [beforeTextNode, afterTextNode];
}
var reachedANode = function reachedANode(childNode, firstNode, secondNode) {
  if (childNode.isSameNode(firstNode) && firstNode.isSameNode(secondNode)) {
    return 'both';
  }
  if (childNode.isSameNode(firstNode)) {
    return 'first';
  }
  if (childNode.isSameNode(secondNode)) {
    return 'second';
  }
  return false;
};
function getAllNodesWithGivenNameInRange(doc, selection, range) {
  var nodeName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var showTextNodes = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  // There are other settings to show comments, attributes, etc, but we only want element and text nodes.
  // https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker/whatToShow
  var nodesToShow = NodeFilter.SHOW_ELEMENT;
  if (showTextNodes) {
    nodesToShow += NodeFilter.SHOW_TEXT;
  }
  var matchingNodes = [];
  var walker = doc.createTreeWalker(range.commonAncestorContainer, nodesToShow, null, false);
  var node = walker.nextNode();
  while (node) {
    // Check if the current node is fully contained within the range. Only if it is fully contained.
    if (selection.containsNode(node, false)) {
      if (node.nodeName === nodeName || nodeName === null) {
        matchingNodes.push(node);
      }
    }
    node = walker.nextNode();
  }
  return matchingNodes;
}
function surroundAllTextNodesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  var textNodes = getAllNodesWithGivenNameInRange(doc, cursorSelection, cursorSelection0Range, '#text').filter(function (foundNode) {
    return !(foundNode.isSameNode(cursorSelection0Range.startContainer) || foundNode.isSameNode(cursorSelection0Range.endContainer));
  });
  textNodes.forEach(function (textNode) {
    if (hasClassOrParentWithClass(textNode, deleteClass)) {
      textNode.textContent = '';
    } else if (hasClassOrParentWithClass(textNode, excludeClass)) {
      // skip
    } else {
      surroundTextNodeContent(doc, textNode, createSurroudDomElementFn, 0);
    }
  });
}
function surroundAllImagesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  var images = getAllNodesWithGivenNameInRange(doc, cursorSelection, cursorSelection0Range, 'IMG');
  images.forEach(function (image) {
    if (hasClassOrParentWithClass(image, deleteClass)) {
      image.className += " ".concat(createClassNamesFn());
    } else if (hasClassOrParentWithClass(image, excludeClass)) {
      // skip
    } else {
      image.className += " ".concat(createClassNamesFn());
    }
  });
}
function deleteAllMatchingElementsInRange(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClas) {
  var allElements = getAllNodesWithGivenNameInRange(doc, cursorSelection, cursorSelection0Range, null, false);
  var toDelete = allElements.filter(function (e) {
    return e.classList.contains(deleteClass);
  });
  toDelete.forEach(function (e) {
    return e.remove();
  });
}
function deleteFocusText(focusNode, cursorSelection0Range) {
  focusNode.textContent = "".concat(focusNode.textContent.substring(cursorSelection0Range.endOffset));
}
function deleteAnchorText(anchorNode, cursorSelection0Range) {
  anchorNode.textContent = "".concat(anchorNode.textContent.substring(0, cursorSelection0Range.startOffset));
}

/**
 * 
 * @param {*} anchorOrFocusNode 
 * @param {*} whichNode = 'anchor' | 'focus' | 'both'
 * @param {*} cursorSelection0Range 
 * @param {*} createSurroudDomElementFn 
 * @param {*} createClassNamesFn 
 * @param {*} deleteClass 
 * @param {*} excludeClass 
 * @returns 
 */
function surroundAnchorOrFocusNode(doc, anchorOrFocusNode, whichNode, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  var _anchorOrFocusNode$ch;
  var leftSideTextNode = null,
    rightSideTextNode = null;
  if (isImageNode(anchorOrFocusNode) && hasClassOrParentWithClass(anchorOrFocusNode, deleteClass)) {
    anchorOrFocusNode.remove();
  } else if (isImageNode(anchorOrFocusNode) && !hasClassOrParentWithClass(anchorOrFocusNode, excludeClass)) {
    anchorOrFocusNode.className += " ".concat(createClassNamesFn());
    leftSideTextNode = anchorOrFocusNode;
    rightSideTextNode = anchorOrFocusNode;
  } else if (hasClassOrParentWithClass(anchorOrFocusNode, deleteClass)) {
    if (whichNode === 'anchor') {
      deleteAnchorText(anchorOrFocusNode, cursorSelection0Range);
    } else if (whichNode === 'focus') {
      deleteFocusText(anchorOrFocusNode, cursorSelection0Range);
    } else {
      anchorOrFocusNode.textContent = "".concat(anchorOrFocusNode.textContent.substring(0, cursorSelection0Range.startOffset)).concat(anchorOrFocusNode.textContent.substring(cursorSelection0Range.endOffset));
    }
    leftSideTextNode = anchorOrFocusNode;
    rightSideTextNode = anchorOrFocusNode;
  } else if (hasClassOrParentWithClass(anchorOrFocusNode, excludeClass)) {
    leftSideTextNode = anchorOrFocusNode;
    rightSideTextNode = anchorOrFocusNode;
  } else if (isTextNode(anchorOrFocusNode)) {
    if (whichNode === 'anchor') {
      var _surroundTextNodeCont = surroundTextNodeContent(doc, anchorOrFocusNode, createSurroudDomElementFn, cursorSelection0Range.startOffset);
      var _surroundTextNodeCont2 = _slicedToArray(_surroundTextNodeCont, 1);
      leftSideTextNode = _surroundTextNodeCont2[0];
    } else if (whichNode === 'focus') {
      var _surroundTextNodeCont3 = surroundTextNodeContent(doc, anchorOrFocusNode, createSurroudDomElementFn, 0, cursorSelection0Range.endOffset);
      var _surroundTextNodeCont4 = _slicedToArray(_surroundTextNodeCont3, 2);
      rightSideTextNode = _surroundTextNodeCont4[1];
    } else {
      var _surroundTextNodeCont5 = surroundTextNodeContent(doc, anchorOrFocusNode, createSurroudDomElementFn, cursorSelection0Range.startOffset, cursorSelection0Range.endOffset);
      var _surroundTextNodeCont6 = _slicedToArray(_surroundTextNodeCont5, 2);
      leftSideTextNode = _surroundTextNodeCont6[0];
      rightSideTextNode = _surroundTextNodeCont6[1];
    }
  } else if (isAnyElementNode(anchorOrFocusNode) && (_anchorOrFocusNode$ch = anchorOrFocusNode.children) !== null && _anchorOrFocusNode$ch !== void 0 && _anchorOrFocusNode$ch.length) {
    // assume the offsets point to positions between child nodes
    if (whichNode === 'anchor') {
      var surroundElement = createSurroudDomElementFn('');
      Array.from(anchorOrFocusNode.childNodes).forEach(function (childNode, index) {
        if (index >= cursorSelection0Range.startOffset) {
          surroundElement.appendChild(childNode);
        }
      });
      if (cursorSelection0Range.startOffset > 0) {
        anchorOrFocusNode.after(anchorOrFocusNode.childNodes[0], surroundElement);
      } else {
        anchorOrFocusNode.prepend(surroundElement);
      }
      var beforeTextNode = doc.createTextNode('');
      anchorOrFocusNode.before(beforeTextNode);
      leftSideTextNode = beforeTextNode;
    } else if (whichNode === 'focus') {
      var _surroundElement = createSurroudDomElementFn('');
      Array.from(anchorOrFocusNode.childNodes).forEach(function (childNode, index) {
        if (index < cursorSelection0Range.endOffset) {
          _surroundElement.appendChild(childNode);
        }
      });
      anchorOrFocusNode.prepend(_surroundElement);
      var afterTextNode = doc.createTextNode('');
      anchorOrFocusNode.after(afterTextNode);
      rightSideTextNode = afterTextNode;
    }
  }
  return [leftSideTextNode, rightSideTextNode];
}
export function surroundSelectionTextNodesWithDom(doc, cursorSelection, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass) {
  var cursorSelection0Range = cursorSelection.getRangeAt(0);
  deleteAllMatchingElementsInRange(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  surroundAllTextNodesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  surroundAllImagesFullyContainedInSelection(doc, cursorSelection, cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  var startContainer = cursorSelection0Range.startContainer,
    endContainer = cursorSelection0Range.endContainer;
  if (startContainer.isSameNode(endContainer)) {
    return surroundAnchorOrFocusNode(doc, startContainer, 'both', cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass);
  } else {
    var _surroundAnchorOrFocu = surroundAnchorOrFocusNode(doc, startContainer, 'anchor', cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass),
      _surroundAnchorOrFocu2 = _slicedToArray(_surroundAnchorOrFocu, 1),
      leftSideTextNode = _surroundAnchorOrFocu2[0];
    var _surroundAnchorOrFocu3 = surroundAnchorOrFocusNode(doc, endContainer, 'focus', cursorSelection0Range, createSurroudDomElementFn, createClassNamesFn, deleteClass, excludeClass),
      _surroundAnchorOrFocu4 = _slicedToArray(_surroundAnchorOrFocu3, 2),
      rightSideTextNode = _surroundAnchorOrFocu4[1];
    return [leftSideTextNode, rightSideTextNode];
  }
}
export function getTextNodeByCharIndex(elementNode, charIndex) {
  var textNodes = findAllChildTextNodes(elementNode).map(function (t) {
    return [t, t.textContent.length];
  });
  if (!textNodes.length) {
    return null;
  }
  var currentNodeIndex = 0;
  var passedStringLength = textNodes[currentNodeIndex][1];
  while (passedStringLength < charIndex) {
    passedStringLength += textNodes[currentNodeIndex][1];
    currentNodeIndex += 1;
  }
  return textNodes[currentNodeIndex][0];
}
export function stopEvent(event) {
  var stopImmediate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  event.preventDefault();
  event.stopPropagation();
  stopImmediate && event.stopImmediatePropagation();
}

/**
 * We are looking for adjacent nodes for text editing. If we look for nodes
 * priour to ours in the DOM tree, then the direction should be SIBLING_DIRECTION_PREV.
 * Else the direction should be SIBLING_DIRECTION_NEXT.
 * In addition because we want to do text editting, the adjacent nodes should be the deepest
 * child nodes of the NEXT/PREV sibling. In case we need the PREV sibling, then the deepest rightmost childnode.
 * In case its the NEXT sibling, then the deepest leftmost childnode.
 * 
 * That is because the element to the left may be something like this:
 * <div class="left sibling">text child 1 <span>some content</span> text child 2</div>CURRENT NODE</div class="right node">...</div>
 * In the above example if we are at the (CURRENT NODE) then the previous node which is adjacent to ours is (text child 2).
 * In this case this gives us the text node that is exactly next to our text node on the left.
 * 
 * It is the exact opposite if we want to find the text that is exactly next to our text node on the right.
 * 
 * This works for all kinds of nodes. Most of the time DIV, P, SPAN, etc will have an empty child text node.
 * If they dont, the function will match them. If e.g. we have an IMG node, since it doesnt have any children,
 * the function will match the IMG node.
 */
function findAdjacentElementsInDirection(node, direction, conditionFn) {
  var _sibling;
  var deepestNodeSide = direction === SIBLING_DIRECTION_PREV ? 'right' : 'left';
  var resultNodes = [];
  var sibling = findAnyNodeOrElementSibling(node, direction);
  // if it is an element with children - e..g <div><span></span><span></span>text_node</div> - we need its last child element,
  // as technicallly it is the first sibling on the left of node
  if ((_sibling = sibling) !== null && _sibling !== void 0 && (_sibling = _sibling.children) !== null && _sibling !== void 0 && _sibling.length) {
    sibling = findDeepestChildNodeInDirection(sibling, deepestNodeSide);
  }

  // while there is a previous sibling and the condition passes for it
  while (!!sibling && conditionFn(sibling)) {
    var _sibling2;
    resultNodes.push(sibling);
    sibling = findAnyNodeOrElementSibling(sibling, direction);
    // if it is an element with children - e..g <div><span></span><span></span>text_node</div> - we need its last child element,
    // as technicallly it is the first sibling on the left of node
    if ((_sibling2 = sibling) !== null && _sibling2 !== void 0 && (_sibling2 = _sibling2.children) !== null && _sibling2 !== void 0 && _sibling2.length) {
      sibling = findDeepestChildNodeInDirection(sibling, deepestNodeSide);
    }
  }

  // For consistency. If previously we called resultNodes.push, and the content is like so:
  // A, B, C, CURRENT NODE, D, E, F, where each letter is a node, then the resultNodes will:
  //
  // * for direction = SIBLING_DIRECTION_PREV --- [C, B, A]
  // * for direction = SIBLING_DIRECTION_NEXT --- [D, E, F]
  // 
  // As you can see if we then combine the arrays including the current node, we get
  // [C, B, A, CURRENT NODE, D, E, F], which is not consistent with how the nodes appear in the DOM,
  // and if we want to traverse them, we will fail.
  // We call reverse, so if we combine all nodes - prev ajdacent elements, current, next adjacent elements,
  // we get: [A, B, C, CURRENT NODE, D, E, F], which is consistent with how the nodes appear in the DOM.
  if (direction === SIBLING_DIRECTION_PREV) {
    resultNodes.reverse();
  }
  return resultNodes;
}

/**
 * Finds all adjacent ELEMENT nodes for which the conditionFn returns true.
 * <div>1</div><div>2</div><div>current</div><div>3</div><div>4</div>
 * Returns elements in order [div 1, div 2, current, div 3, div 4], assuming conditionFn returns true for all of the divs.
 * 
 * @param {*} node | any node - text or element
 * @param {*} conditionFn | predicate that must return either true or false
 * @returns 
 */
export function findAdjacentElementsWhere(node, conditionFn) {
  var previousAdjacentSiblings = findAdjacentElementsInDirection(node, SIBLING_DIRECTION_PREV, conditionFn);
  var nextAdjacentSiblings = findAdjacentElementsInDirection(node, SIBLING_DIRECTION_NEXT, conditionFn);
  var resultNodes = [].concat(_toConsumableArray(previousAdjacentSiblings), [node], _toConsumableArray(nextAdjacentSiblings));
  var adjacentNodes = resultNodes;
  var nodeParentLi = matchNodeOrAnyParentElement(node, function (el) {
    return el.nodeName === 'LI';
  });
  if (nodeParentLi) {
    adjacentNodes = resultNodes.filter(function (n) {
      return nodeParentLi.contains(n);
    });
  }
  return adjacentNodes;
}