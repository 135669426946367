import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import SearchBar from '@components/util/SearchBar';
import { Breadcrumb, Button, Card, Col, Form, FormControl, InputGroup, Pagination, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupList from '@components/shared/claim_group/list/ClaimGroupList';
import ClaimGroupsListNavigation from '@rd-web-markets/market/dist/claim_group/list/ClaimGroupsListNavigation';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';

const initialParams = {
  page: 1,
  query: ''
}

const AllClaimGroupsPage = ({ accountType }) => {
  const [claimGroups, setClaimGroups] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  );

  const fetchData = async () => {
    setLoading(true);
    const response = await claimGroupService.all(QueryString.stringify(params));
    setClaimGroups(response.claim_groups);
    setTotalPages(response.pages);
    history.push({
      path: 'admin/claim_groups',
      search: QueryString.stringify(params),
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [history, params]);

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const deleteClaimGroup = async claimGroupId => {
    if(window.confirm('Are you sure you wish to delete this claim group ?')){
      setLoading(true);
      try {
        await claimGroupService.delete(claimGroupId);
        await fetchData();
      } catch(error){
        dispatch(handleError(error));
      };
      setLoading(false);
    }
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === params.page}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  };

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='all_claim_groups' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>All Claim Groups</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>
          <ClaimGroupsListNavigation page='claim_groups' />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Row>
                    <Col md={1}>
                      All Claim Groups
                    </Col>
                    <Col md={{ span: 4, offset: 7 }}>
                      <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  {claimGroups && <ClaimGroupList deleteClaimGroup={deleteClaimGroup} claimGroups={claimGroups} loading={loading} />}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Pagination className="justify-content-end">{items}</Pagination>
        </Card.Body>
      </Card>
    </>
  )
}

export default AllClaimGroupsPage
