import { createCommentSpans } from "@rd-web-markets/shared/dist/comments/commentsDomUtils";
import { getTextNodesInRange } from "../../../domUtils";
import { v4 as uuidv4 } from 'uuid';
import { addCommentButtons } from "./buttons";

/**
 * Listens for the following events:
 * 'click' - in case we have selected text inside the editor body - and in case we are NOT clicking on the track changes popover comment button.
 *         - sets the selected comment span id, and fires 'Comments:commentSpanIdChanged'.
 * 'Comments:triggerChangeSelectedCommentSpanId' - sets the selected comment span id from cursor selection and fires 'Comments:commentSpanIdChanged'
 * 
 * Fires the following events:
 * 'Comments:commentSpanIdChanged' - called with the current { payload: { commentSpanId } }
 * 'change' - fired in order to trigger tinymce to save the content after we add the comment html
 * @param {*} editor 
 * @param {*} event 
 * @param {*} moduleInstance 
 */

function showCommentsView(editor, moduleInstance) {
  if (!moduleInstance.commentsViewVisible) {
    editor.ui.registry.getAll().buttons.comments_view_toggle.toggleState();
    editor.fire('Comments:commentViewToggled', {
      payload: {
        commentsViewVisible: moduleInstance.commentsViewVisible
      }
    });
  }
}
function updateSelectedSpanId(editor, event, moduleInstance) {
  // If we have clicked on the comment button, we set the selected span id separately
  // otherwise if we dont skip this code here, it will reset the span id to null, and
  // it will trigger another fetchComments call with span id = null, which may end up in a race condition 
  // with the non null id call, and reset the comments unnecessarily.
  if (!event.target.className.includes('TrackChangesPopover-btn__comment')) {
    if (event.target.getAttribute('data-aym-comment-id')) {
      moduleInstance.selectedCommentsSpanId = event.target.getAttribute('data-aym-comment-id');
    } else {
      moduleInstance.selectedCommentsSpanId = null;
    }
    editor.fire('Comments:commentSpanIdChanged', {
      payload: {
        commentSpanId: moduleInstance.selectedCommentsSpanId
      }
    });
  }
}
var comments = {
  name: 'comments',
  selectedCommentsSpanId: null,
  lastCommentEditorSelection: null,
  commentsViewVisible: true,
  // this method is only added to this object, because for some reason we were not able to pass the moduleInstance as this, and we
  // had to use "this." inside the method.
  // This method should not be called from any other place. It is better to fire the Comments:triggerChangeSelectedCommentSpanId event.
  _updateSelectedSpanIdFromCursorSelection: function _updateSelectedSpanIdFromCursorSelection(editor, cursorSelection) {
    this.lastCommentEditorSelection = cursorSelection;
    var range = cursorSelection.getRangeAt(0);
    var textNodes = getTextNodesInRange(range);
    var existingCommentId = textNodes.map(function (n) {
      return n.parentNode.getAttribute('data-aym-comment-id');
    }).find(function (v) {
      return !!v;
    });
    var commentIdToUse = existingCommentId || uuidv4();
    this.selectedCommentsSpanId = commentIdToUse;
    editor.fire('Comments:commentSpanIdChanged', {
      payload: {
        commentSpanId: this.selectedCommentsSpanId
      }
    });
  },
  addIfApplicable: function addIfApplicable(editor, _ref) {
    var isTrackingChanges = _ref.isTrackingChanges,
      showTrackChangesControls = _ref.showTrackChangesControls;
    var moduleInstance = this;
    if (isTrackingChanges) {
      addCommentButtons(editor, moduleInstance);
      editor.on('click', function (event) {
        if (showTrackChangesControls) {
          updateSelectedSpanId(editor, event, moduleInstance);
          showCommentsView(editor, moduleInstance);
        }
      });
      editor.on('Comments:triggerChangeSelectedCommentSpanId', function (_ref2) {
        var payload = _ref2.payload;
        moduleInstance._updateSelectedSpanIdFromCursorSelection(editor, payload.cursorSelection);
        showCommentsView(editor, moduleInstance);
      });
      return true;
    }
    return false;
  },
  createCommentHighlightAndUpdateContent: function createCommentHighlightAndUpdateContent(editor) {
    // not sure why we cant just use "this" here.
    // const moduleInstance = this
    var moduleInstance = editor.AYMING_MODULES.find(function (m) {
      return m.name === 'comments';
    });

    // at this point the user has clicked on different places and de-selected the original selection many times
    // so we need to pass the original editorSelection
    if (moduleInstance.lastCommentEditorSelection) {
      var resultContent = createCommentSpans(editor.getBody(), moduleInstance.lastCommentEditorSelection, moduleInstance.selectedCommentsSpanId);
      editor.setContent(resultContent);
      moduleInstance.lastCommentEditorSelection = null;
    }

    // making sure we save the contend by triggering tinymce to trigger save
    editor.fire('change', {
      content: editor.getContent()
    });

    // onCreateComment(editor.getBody().innerHTML)
  }
};

export default comments;