import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimGroupRentalLeaseComputers = {
  all(claimGroupId, type) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_rental_lease_computers?type=${type}`, request.get);
  }
}

export default claimGroupRentalLeaseComputers;
