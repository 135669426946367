import React, { useRef, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { t } from 'i18next';

const AttachmentsForm = ({
  handleSubmit,
  loading
}) => {
  const hiddenFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachment, setAttachment] = useState({notes: ''});

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const addFile = event => {
    setSelectedFile(event.target.files[0]);
  }

  const handleChange = e => {
    const value =
    e.target.type === 'checkbox'
      ? e.target.checked
      : e.target.value;

      setAttachment({
      ...attachment,
      [e.target.name]: value,
    });
  };

  const submit = () => {
    setSelectedFile(null);
    setAttachment({notes: ''});
    handleSubmit(attachment, selectedFile);
    hiddenFileInput.current.value = '';
  };


  return (
    <>
      <Row className="pt-3">
        <Col md={2}>
          <p>{t('add_attachment')}</p>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col md={4}>
          <Form.Control
            onChange={handleChange}
            name="notes"
            placeholder={t('notes') + '...'}
            value={attachment.notes}
            size="md"
            type="text"
          ></Form.Control>
        </Col>
        <Col md={3}>
          <Button variant="primary" onClick={handleClick} disabled={loading}>
            {t('upload_document')}
          </Button>
          <input
            style={{ display: 'none' }}
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => addFile(e)}
          ></input>
          <p>{selectedFile && selectedFile.name}</p>
        </Col>
        <Col md={{span:1, offset: 1}}>
          <Button loading={loading} variant="primary" onClick={submit}>
            <span className="material-icons md-18">save</span> {t('save')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default AttachmentsForm
