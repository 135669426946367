import { hasClassOrParentWithClass, isEmptySelection } from '../../../../domUtils';
import { findAdjacentTrackChangesElementssOfTheSameType } from '../highlighintUtils';
import { showPopover } from '../../../editorUtils/popover';
import { createEditorSelectionWithAllRelatedTrackChangesElements } from '../findNextPreviousChange';
import DeletionHighlight from '../models/DeletionHighlight';
import AdditionHighlight from '../models/AdditionHighlight';
import TrackChangesHighlight from '../models/TrackChangesHighlight';
import { acceptChange, rejectChange } from '../acceptRejectChanges';
function createTrackChangesActionButtons(editor, popover, changeElement) {
  var doc = editor.getDoc();
  var actionButtonsContainer = doc.createElement('div');
  actionButtonsContainer.className = 'action_buttons_container';
  var acceptButton = doc.createElement('button');
  acceptButton.innerHTML = 'Accept';
  acceptButton.className = 'btn__accept';
  acceptButton.onclick = function () {
    acceptChange(editor, changeElement);
    editor.fire('change', {
      content: editor.getContent()
    });
  };
  actionButtonsContainer.appendChild(acceptButton);
  var rejectButton = doc.createElement('button');
  rejectButton.innerHTML = 'Reject';
  rejectButton.className = 'btn__reject';
  rejectButton.onclick = function () {
    rejectChange(editor, changeElement);
    editor.fire('change', {
      content: editor.getContent()
    });
  };
  actionButtonsContainer.appendChild(rejectButton);
  popover.appendChild(actionButtonsContainer);
}
function createCommentButton(editor, popover, changeElement, onAddComment) {
  var doc = editor.getDoc();
  var actionButtonsContainer = popover.querySelector('.action_buttons_container');
  var commentButton = doc.createElement('button');
  commentButton.innerHTML = 'Comment';
  commentButton.className = 'TrackChangesPopover-btn__comment';
  commentButton.style = 'float: left;';
  commentButton.onclick = function () {
    var selection = createEditorSelectionWithAllRelatedTrackChangesElements(editor, changeElement);
    onAddComment(selection);
  };
  actionButtonsContainer.prepend(commentButton);
}
function getPopoverDescriptionForChangeType(changeType) {
  if (changeType === 'deletion') {
    return 'Remove:';
  }
  return 'Add';
}
function getChangeClassNameForChangeType(changeType) {
  if (changeType === 'deletion') {
    return ' Aym-TextEditor-TrackChanges-Content-Deletion';
  }

  // space in front is important
  return ' Aym-TextEditor-TrackChanges-Content-Addition';
}
function createChangeContainer(doc, popover, changeType, fullChangeContent) {
  var changeContainer = doc.createElement('div');
  changeContainer.className = 'popover-change-container';
  changeContainer.setAttribute('disabled', true);
  var changeDescriptor = doc.createElement('div');
  changeDescriptor.innerText = getPopoverDescriptionForChangeType(changeType);
  var text = doc.createElement('div');
  text.innerHTML = fullChangeContent;
  text.className = 'text';
  text.className += getChangeClassNameForChangeType(changeType);
  changeContainer.appendChild(changeDescriptor);
  changeContainer.appendChild(text);
  popover.appendChild(changeContainer);
}
function create(editor, changeElement, onAddComment) {
  var nodeTrackChangesTypeClass = TrackChangesHighlight.getNodeTrackChangesType(changeElement);
  var relatedNodesAndContentNode = findAdjacentTrackChangesElementssOfTheSameType(changeElement, nodeTrackChangesTypeClass);
  var fullChangeContent = relatedNodesAndContentNode.reduce(function (acc, node) {
    return acc + node.textContent;
  }, '');
  this.changeElements = relatedNodesAndContentNode;
  var changeType = null;
  if (nodeTrackChangesTypeClass === 'TrackChanges-Highlight-Deletion') {
    changeType = 'deletion';
  } else if (nodeTrackChangesTypeClass === 'TrackChanges-Highlight-Addition') {
    changeType = 'addition';
  }
  if (!changeElement) {
    console.warn('[TrackChanges - TrackChangesPopover - create]: No change element provided.');
    return;
  }
  showPopover(editor.getDoc(), changeElement, 'Accept / Reject change?',
  // body fn
  function (popover) {
    createChangeContainer(document, popover, changeType, fullChangeContent);
  },
  // footer fn
  function (popover) {
    createTrackChangesActionButtons(editor, popover, changeElement);
    createCommentButton(editor, popover, changeElement, onAddComment);
  }, {
    className: 'Ayming-TrackChanges-Popover'
  });
  return this;
}
var TrackChangesPopover = {
  create: create,
  /**
   * Returns true if we have an empty selection where the anchor node is either inside a track changes highlight.
   * Empty means that the anchor node is equal to the focus node and the anchor offset is equal to the focus offset.
   * 
   * !!! This function does not handle the case where we click inside a deletion highlight with contenteditable=false. !!!
   * Check the below commented out function for that. This function is relevant only if we do NOT set contenteditable=false.
   * 
   * @param {*} clickEvent 
   * @param {*} editor 
   */
  shouldShowPopup: function shouldShowPopup(clickEvent, editor) {
    var cursorSelection = editor.selection.getSel();
    var anchorNode = cursorSelection.anchorNode,
      focusNode = cursorSelection.focusNode;
    var isSameImage = anchorNode.isSameNode(focusNode) && anchorNode.nodeName === 'IMG';
    var anySelectionNodeIsInAHighlight = TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType) || TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, AdditionHighlight.highlightType);
    var targetNodeNameIsIMG = clickEvent.target.nodeName === 'IMG';
    var isUnderHighlightClass = hasClassOrParentWithClass(clickEvent.target, 'TrackChanges-Highlight');
    var clickEventTargetIsTrackChangesImage = targetNodeNameIsIMG && isUnderHighlightClass;
    var selectionCondition = (isSameImage || isEmptySelection(cursorSelection)) && anySelectionNodeIsInAHighlight;
    return selectionCondition || clickEventTargetIsTrackChangesImage;
  },
  //
  // For now the below function is commented out. This is temporary until we test the deletion highlights without contenteditable=false.
  // The below function catered for when we click inbetween letters in a deletion highlight with contenteditable=false.
  //
  //
  // shouldShowPopup (clickEvent, editor) {
  //   const cursorSelection = editor.selection.getSel()
  //   const isSelectionInAHighlight = TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, DeletionHighlight.highlightType) || TrackChangesHighlight.isAnySelectionNodeInTrackChangesHighlight(cursorSelection, AdditionHighlight.highlightType)
  //   // this is in order to reduce the amount of situations where a popover may appear - just in case
  //   const isAnchorEqualToFocus = cursorSelection.anchorNode.isEqualNode(cursorSelection.focusNode)
  //   // the check cannot be generalized because the deletions have contenteditable=false
  //   // when clicking on a deletion highlight - sometimes the popover wont be shown without this check
  //   const elementIsInHighlight = TrackChangesHighlight.isNodeAOrInAHighlight(clickEvent.target, 'addition') || TrackChangesHighlight.isNodeAOrInAHighlight(clickEvent.target, 'deletion')
  //   const shouldDisplayPopoverForDeletion = elementIsInHighlight && isAnchorEqualToFocus
  //   const shouldDisplayPopoverForAddition = isSelectionInAHighlight && isEmptySelection(cursorSelection)
  //   return shouldDisplayPopoverForDeletion || shouldDisplayPopoverForAddition
  // },
  closeAllPopovers: function closeAllPopovers(editor) {
    var editorBody = editor.getBody();
    var deletables = Array.from(editorBody.querySelectorAll('.Editor-non-content'));
    deletables.forEach(function (el) {
      return el.remove();
    });
  }
};
export default TrackChangesPopover;