import SearchBar from '@components/util/SearchBar';
import React, { useState, useEffect } from 'react'
import { Button, Card, Row, Col, Pagination, Form } from 'react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { companiesService } from '@services/task_management/companies.service';
import { useSelector } from 'react-redux';
import Filters from '@rd-web-markets/shared/dist/util/Filters';
import MasterSidebar from '@components/util/MasterSidebar';

const initialParams = 'page=1&query=';

const TimeTrackingCompaniesPage = ({accountType}) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await companiesService.all(QueryString.stringify(params));
      setCompanies(companies);
      history.push({
        search: QueryString.stringify(params),
      });
    };

    fetchCompanies();
  }, [history, params])

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const headers = [
    { text: t('company') },
    { text: t('number_of_projects')},
    { text: t('tasks')},
    { text: t('hours_spent')},
  ];

  if(!companies) return <Loading />

  const companyNamesList = companies.map(company => company.name);

  const rows = companies.map(company => {
    return {
      key: company.id,
      columns: [
        <Link to={`/${user.account_type}/time_tracking/companies/${company.id}/claim_groups`}>{company.name}</Link>,
        company.number_of_projects,
        company.number_of_tasks,
        company.hours_spent,
      ]
    }
  });

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='list_of_companies' />
      <Card>
        <Row>
          <Col md={12} className='text-center p-4'>
            <Button className='text-center' variant='light' size='md' as={Link} to={'/admin/time_tracking/create_project'}>
              <span className="material-icons md-18">add</span> Project
            </Button>
          </Col>
        </Row>
      </Card>

      <div className='text-right mb-2'>
        <Filters
          params={params}
          setParams={setParams}
          filters={[
            { name: 'company', label: t('company'), type: 'select', options: companyNamesList },
          ]}
        />
      </div>

      <Card>
        <Table headers={headers}
               rows={rows}
        />
      </Card>

      <Pagination className='justify-content-end'>{items}</Pagination>
    </>
  )
}

export default TimeTrackingCompaniesPage