import React from 'react'
import RegularRealtimeDashboard from '@rd-web-markets/market/dist/dashboards/RegularRealtimeDashboard';

const RegularRealtimeDashboardPage = () => {

  return (
    <>
      <div className='mt-3'></div>
        <RegularRealtimeDashboard />
      <div className='mb-5'></div>
    </>
  );
};
export default RegularRealtimeDashboardPage;
