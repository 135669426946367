import editorMenuUtils from "../editorMenuUtils";
import { fixElementStyles } from "./fixElementStyles";
import { fixSpecialElementStyles } from "./fixSpecialElementStyles";
var _require = require("../../../domUtils"),
  loopChildNodes = _require.loopChildNodes,
  stopEvent = _require.stopEvent;

/**
 * @param {*} editorBody 
 */
function applyBodyStylesToAllDirectChildren(editor, editorBody) {
  Array.from(editorBody.children).forEach(function (child) {
    fixElementStyles(editor, child);
  });
}
function fixAllInlineStylesInEditorBody(editor) {
  var editorBody = editor.getBody();
  applyBodyStylesToAllDirectChildren(editor, editorBody);
  loopChildNodes(editorBody, function (node) {
    var stylesFixed = fixSpecialElementStyles(node);
    if (stylesFixed === false && !node.parentElement.isSameNode(editorBody)) {
      fixElementStyles(editor, node);
    }
  });
}
function addToggleStylesFormatterButton(editor, moduleInstance, onStylesFormatterToggled) {
  editorMenuUtils.addToggleButton({
    editor: editor,
    moduleInstance: moduleInstance,
    toggleStatePropertyName: 'stylesFormatterToggle',
    buttonName: 'styles_formatter_toggle',
    textIfToggled: 'Styles Formatter: ON',
    textIfNotToggled: 'Styles Formatter: OFF',
    onToggleCallback: function onToggleCallback(stylesFormatterToggle) {
      onStylesFormatterToggled(stylesFormatterToggle);
      if (stylesFormatterToggle) {
        editor.fire('change', {
          content: editor.getContent()
        });
      }
    }
  });
}
var styles_formatter = {
  name: 'styles_formatter',
  stylesFormatterToggle: true,
  addIfApplicable: function addIfApplicable(editor, _ref) {
    var _this = this;
    var onStylesFormatterToggled = _ref.onStylesFormatterToggled,
      stylesFormatterToggleIniitialValue = _ref.stylesFormatterToggleIniitialValue;
    this.stylesFormatterToggle = stylesFormatterToggleIniitialValue;
    editor.on('change', function (e) {
      if (e.firedFromStyleFormatter || !_this.stylesFormatterToggle) {
        return;
      }

      // stopping the event because initially it was called with the old editor content,
      // and if we allow it to proceed, the rest of the callbacks will act on the old content
      stopEvent(e);

      // fix all styles formatting
      fixAllInlineStylesInEditorBody(editor);

      // Re-fire the change event with the formatted content, but add a flag to prevent infinite loop.
      // Why are we not simply using mceCleanup? Because it puts the cursor location at the start of the content.
      // Alternatively we could use tinymce selection bookmarks together with mceCeanup, but the
      // bookmarks dont work.
      editor.fire('change', {
        content: editor.getContent(),
        firedFromStyleFormatter: true
      });
    });
    addToggleStylesFormatterButton(editor, this, onStylesFormatterToggled);
    return true;
  }
};
export default styles_formatter;